import moment from "moment";
import { defineStore } from "pinia";

// https://pinia.vuejs.org/introduction.html#basic-example
export const useReportingStore = defineStore("reporting", {
  state: () => {
    return {
      startDate: moment().subtract(1, "w").startOf("d").format(),
      endDate: moment().subtract(1, "d").startOf("d").format(),
    };
  },
  // could also be defined as
  // state: () => ({ count: 0 })
  actions: {
    increment() {
      //
    },
  },
});
