import { renderIcon } from "@gohighlevel/ghl-ui";
import { secondsToHms } from "@/helpers";
import moment from "moment";
import {
  FaceHappyIcon,
  FaceSadIcon,
  MessageNotificationSquareIcon,
  MessageTextSquare02Icon,
  LayoutAlt03Icon,
  CalendarCheck02Icon,
  Inbox02Icon,
  Mail02Icon,
  Star01Icon,
  PhoneIncoming01Icon,
  PhoneOutgoing01Icon,
  Hourglass03Icon,
  UserCheck02Icon,
  ClipboardCheckIcon,
  CursorBoxIcon,
  User03Icon,
  ArrowUpIcon,
  ArrowDownIcon,
  FilterFunnel01Icon,
  Globe06Icon,
  Dotpoints02Icon,
  CheckDone02Icon,
} from "@gohighlevel/ghl-icons/24/outline";
import { reportNumbers } from "@/helpers";
import { useI18n } from "vue-i18n";

export function prepareReportFields(fieldsSelected: string[], sortKey: string) {
  const fields = Object.assign([], fieldsSelected);
  if (fields.includes("sms")) {
    fields.push("smsIn");
    fields.push("smsOut");
  }
  if (fields.includes("emails")) {
    fields.push("emailsIn");
    fields.push("emailsOut");
  }
  if (fields.includes("calls")) {
    fields.push("callsIn");
    fields.push("callsOut");
    fields.push("callDurationIn");
    fields.push("callDurationOut");
  }
  if (fields.includes("reviews")) {
    fields.push("reviewsGoogle");
    fields.push("reviewsFacebook");
    fields.push("reviewsPositive");
    fields.push("reviewsNegative");
  }
  if (fields.includes("gmbMsg")) {
    fields.push("gmbMsgIn");
    fields.push("gmbMsgOut");
  }
  if (fields.includes("facebookMsg")) {
    fields.push("facebookMsgIn");
    fields.push("facebookMsgOut");
  }
  if (fields.includes("instagramMsg")) {
    fields.push("instagramMsgIn");
    fields.push("instagramMsgOut");
  }
  // if (fields.includes("whatsappMsg")) {
  //   fields.push("whatsappMsgIn");
  //   fields.push("whatsappMsgOut");
  // }

  if (!fields.includes(sortKey) && sortKey !== "name") {
    fields.push(sortKey);
  }

  return fields;
}

export function buildEmptyReport(fields) {
  const report = {};
  for (const field of fields) {
    report[field] = 0;
  }
  return report;
}

export function getPreviousRange(range) {
  const { start, end } = range;

  const diff = moment(end).diff(start, "days");
  const prevEnd = moment(start).subtract(1, "days").endOf("day")
.format();
  const prevStart = moment(prevEnd).subtract(diff, "days").startOf("day")
.format();
  return {
    start: prevStart,
    end: prevEnd,
  };
}

/**
 * Helper to generate markup for comparison text
 * @param stat - Current value
 * @param prevStat - Previous range value to compare
 * @param inversePositive - Boolean to indicate if decreasing values is positive,
 *                          i.e. decreasing failed count is a positive case, show them in green instead of red
 */
export function getStatComparison(stat = 0, prevStat = 0, inversePositive = false) {
  if (prevStat === undefined) {
    return undefined;
  }

  if (prevStat === stat) {
    return {
      icon: null,
      classes: ["bg-success-50", "text-success-700"],
      value: 0,
    };
  }

  if (prevStat === 0) {
    if (inversePositive) {
      return {
        icon: renderIcon(ArrowUpIcon),
        classes: ["bg-error-50", "text-error-700"],
        value: 100,
      };
    }
    return {
      icon: renderIcon(ArrowUpIcon),
      classes: ["bg-success-50", "text-success-700"],
      value: 100,
    };
  }

  const diff = ((stat - prevStat) / prevStat) * 100;
  const icon = diff < 0 ? ArrowDownIcon : ArrowUpIcon;
  const classes: string[] = [];
  if (inversePositive) {
    if (diff <= 0) {
      classes.push("text-success-700");
      classes.push("bg-success-50");
    } else {
      classes.push("text-error-700");
      classes.push("bg-error-50");
    }
  } else if (diff >= 0 || (inversePositive && diff <= 0)) {
    classes.push("text-success-700");
    classes.push("bg-success-50");
  } else {
    classes.push("text-error-700");
    classes.push("bg-error-50");
  }
  return {
    icon: renderIcon(icon),
    classes,
    value: Math.abs(diff < 99 ? parseFloat(diff.toFixed(2)) : Math.trunc(diff)),
  };
}

export function buildReport(fields, report, previousReport) {

  const { t } = useI18n();

  const data: any[] = [];
  if (!report) {
    return [[], []];
  }

  if (report.hasOwnProperty("users") && fields.includes("users")) {
    data.push({
      label: t("activeUsers"),
      metrics: [
        {
          key: "users",
          label: t("activeUsers"),
          value: reportNumbers(report.users || 0),
          icon: renderIcon(UserCheck02Icon),
          tooltip: t("activeUsers"),
          comparison: getStatComparison(report.users, previousReport?.users),
        },
      ],
    });
  }
  if (report.hasOwnProperty("calls") && fields.includes("calls")) {
    const totalDuration = (report.callDurationIn || 0) + (report.callDurationOut || 0);
    const prevTotalDuration = previousReport
      ? (previousReport.callDurationIn || 0) + (previousReport.callDurationOut || 0)
      : 0;
    const durationHms = secondsToHms(totalDuration);
    data.push({
      type: "calls",
      label: t("calls"),
      metrics: [
        {
          label: "Incoming",
          key: "calls-in",
          value: reportNumbers(report.callsIn || 0),
          icon: renderIcon(PhoneIncoming01Icon),
          tooltip: t("incomingCalls"),
          comparison: getStatComparison(report.callsIn, previousReport?.callsIn),
        },
        {
          label: "Outgoing",
          key: "calls-out",
          value: reportNumbers(report.callsOut || 0),
          icon: renderIcon(PhoneOutgoing01Icon),
          tooltip: t("outgoingCalls"),
          comparison: getStatComparison(report.callsOut, previousReport?.callsOut),
        },
        {
          label: "Duration",
          key: "calls-duration",
          value: durationHms,
          icon: renderIcon(Hourglass03Icon),
          tooltip: t("totalTalktime"),
          comparison: getStatComparison(totalDuration, prevTotalDuration),
        },
      ],
    });
  }

  if (report.hasOwnProperty("emails") && fields.includes("emails")) {
    data.push({
      label: t("emails"),
      metrics: [
        {
          label: "Incoming",
          key: "emails-in",
          value: reportNumbers(report.emailsIn || 0),
          icon: renderIcon(Inbox02Icon),
          tooltip: t("incomingEmails"),
          comparison: getStatComparison(report.emailsIn, previousReport?.emailsIn),
        },
        {
          label: "Outgoing",
          key: "emails-out",
          value: reportNumbers(report.emailsOut || 0),
          icon: renderIcon(Mail02Icon),
          tooltip: t("outgoingEmails"),
          comparison: getStatComparison(report.emailsOut, previousReport?.emailsOut),
        },
      ],
    });
  }

  if (report.hasOwnProperty("sms") && fields.includes("sms")) {
    data.push({
      label: t("textMessages"),
      metrics: [
        {
          label: "Incoming",
          key: "sms-in",
          value: reportNumbers(report.smsIn || 0),
          icon: renderIcon(MessageNotificationSquareIcon),
          tooltip: t("incomingSms"),
          comparison: getStatComparison(report.smsIn, previousReport?.smsIn),
        },
        {
          label: "Outgoing",
          key: "sms-out",
          value: reportNumbers(report.smsOut || 0),
          icon: renderIcon(MessageTextSquare02Icon),
          tooltip: t("outgoingSms"),
          comparison: getStatComparison(report.smsOut, previousReport?.smsOut),
        },
      ],
    });
  }

  if (report.hasOwnProperty("contacts") && fields.includes("contacts")) {
    data.push({
      label: t("numberOfContacts"),
      metrics: [
        {
          label: "No. of Contacts",
          key: "contacts",
          value: reportNumbers(report.contacts || 0),
          icon: renderIcon(User03Icon),
          tooltip: t("contactsCreated"),
          comparison: getStatComparison(report.contacts, previousReport?.contacts),
        },
      ],
    });
  }

  if (report.hasOwnProperty("appointments") && fields.includes("appointments")) {
    data.push({
      label: t("numberOfAppointments"),
      metrics: [
        {
          label: "No. of Appointments",
          key: "appointments",
          value: reportNumbers(report.appointments || 0),
          icon: renderIcon(CalendarCheck02Icon),
          tooltip: t("appointmentsBooked"),
          comparison: getStatComparison(report.appointments, previousReport?.appointments),
        },
      ],
    });
  }

  const submissions = {
    label: t("submissions"),
    metrics: [] as any[],
  };
  if (
    (report.hasOwnProperty("formSubmissions") ||
      report.hasOwnProperty("facebookFormSubmissions") ||
      report.hasOwnProperty("surveySubmissions")) &&
    (fields.includes("formSubmissions") ||
      fields.includes("facebookFormSubmissions") ||
      fields.includes("surveySubmissions"))
  ) {
    if (report.hasOwnProperty("formSubmissions") && fields.includes("formSubmissions")) {
      submissions.metrics.push({
        label: "Form Submissions",
        key: "form-submissions",
        value: reportNumbers(report.formSubmissions || 0),
        icon: renderIcon(LayoutAlt03Icon),
        tooltip: t("totalFormSubmissions"),
        comparison: getStatComparison(report.formSubmissions, previousReport?.formSubmissions),
      });
    }
    if (report.hasOwnProperty("surveySubmissions") && fields.includes("surveySubmissions")) {
      submissions.metrics.push({
        label: "Survey Submissions",
        key: "survey-submissions",
        value: reportNumbers(report.surveySubmissions || 0),
        icon: renderIcon(ClipboardCheckIcon),
        tooltip: t("totalSurveySubmissions"),
        comparison: getStatComparison(report.surveySubmissions, previousReport?.surveySubmissions),
      });
    }
    if (
      report.hasOwnProperty("facebookFormSubmissions") &&
      fields.includes("facebookFormSubmissions")
    ) {
      submissions.metrics.push({
        label: "Facebook From Submissions",
        key: "fbform-submissions",
        value: reportNumbers(report.facebookFormSubmissions || 0),
        icon: renderIcon(CursorBoxIcon),
        tooltip: t("totalFacebookFormSubmissions"),
        comparison: getStatComparison(
          report.facebookFormSubmissions,
          previousReport?.facebookFormSubmissions
        ),
      });
    }
    data.push(submissions);
  }

  const sitesCreated = {
    label: t("sitesCreated"),
    metrics: [] as any,
  };

  if (
    (report.hasOwnProperty("typeFunnel") ||
      report.hasOwnProperty("typeWebsite") ||
      report.hasOwnProperty("formBuilder") ||
      report.hasOwnProperty("survey")) &&
    (fields.includes("typeFunnel") ||
      fields.includes("typeWebsite") ||
      fields.includes("formBuilder") ||
      fields.includes("survey"))
  ) {
    if (report.hasOwnProperty("typeFunnel") && fields.includes("typeFunnel")) {
      sitesCreated.metrics.push({
        label: "Funnels",
        key: "funnels",
        value: reportNumbers(report.typeFunnel || 0),
        icon: renderIcon(FilterFunnel01Icon),
        tooltip: t("funnels"),
        comparison: getStatComparison(report.typeFunnel, previousReport?.typeFunnel),
      });
    }
    if (report.hasOwnProperty("typeWebsite") && fields.includes("typeWebsite")) {
      sitesCreated.metrics.push({
        label: "Websites",
        key: "websites",
        value: reportNumbers(report.typeWebsite || 0),
        icon: renderIcon(Globe06Icon),
        tooltip: t("websites"),
        comparison: getStatComparison(report.typeWebsite, previousReport?.typeWebsite),
      });
    }
    if (report.hasOwnProperty("formBuilder") && fields.includes("formBuilder")) {
      sitesCreated.metrics.push({
        label: "Forms",
        key: "forms",
        value: reportNumbers(report.formBuilder || 0),
        icon: renderIcon(Dotpoints02Icon),
        tooltip: t("forms"),
        comparison: getStatComparison(report.formBuilder, previousReport?.formBuilder),
      });
    }
    if (report.hasOwnProperty("survey") && fields.includes("survey")) {
      sitesCreated.metrics.push({
        label: "Surveys",
        key: "surveys",
        value: reportNumbers(report.survey || 0),
        icon: renderIcon(CheckDone02Icon),
        tooltip: t("surveys"),
        comparison: getStatComparison(report.survey, previousReport?.survey),
      });
    }
    data.push(sitesCreated);
  }

  if (report.hasOwnProperty("gmbMsg") && fields.includes("gmbMsg")) {
    data.push({
      label: t("gmbMessages"),
      metrics: [
        {
          label: "GBP Messages",
          value: reportNumbers(report.gmbMsgIn || 0),
          key: "gmb-msg-in",
          icon: renderIcon(MessageNotificationSquareIcon),
          tooltip: t("incomingGMBMessages"),
          comparison: getStatComparison(report.gmbMsgIn, previousReport?.gmbMsgIn),
        },
        {
          label: "GBP Messages",
          value: reportNumbers(report.gmbMsgOut || 0),
          key: "gmb-msg-out",
          icon: renderIcon(MessageTextSquare02Icon),
          tooltip: t("outgoingGMBMessages"),
          comparison: getStatComparison(report.gmbMsgOut, previousReport?.gmbMsgOut),
        },
      ],
    });
  }

  if (report.hasOwnProperty("facebookMsg") && fields.includes("facebookMsg")) {
    data.push({
      label: t("fbMessages"),
      metrics: [
        // {
        //   label: "Facebook Messages",
        //   value: report.facebookMsg || 0,
        //   key: "fb-msg",
        //   icon: renderIcon(Star01Icon),
        //   tooltip: "Facebook Messages",
        //   comparison: getStatComparison(report.facebookMsg, previousReport?.facebookMsg),
        // },
        {
          label: "Facebook Messages",
          value: reportNumbers(report.facebookMsgIn || 0),
          key: "fb-msg-in",
          icon: renderIcon(MessageNotificationSquareIcon),
          tooltip: t("incomingFbMessages"),
          comparison: getStatComparison(report.facebookMsgIn, previousReport?.facebookMsgIn),
        },
        {
          label: "Facebook Messages",
          value: reportNumbers(report.facebookMsgOut || 0),
          key: "fb-msg-out",
          icon: renderIcon(MessageTextSquare02Icon),
          tooltip: t("outgoingFbMessages"),
          comparison: getStatComparison(report.facebookMsgOut, previousReport?.facebookMsgOut),
        },
      ],
    });
  }

  if (report.hasOwnProperty("instagramMsg") && fields.includes("instagramMsg")) {
    data.push({
      label: t("igMessages"),
      metrics: [
        // {
        //   label: "Instagram Messages",
        //   value: report.instagramMsg || 0,
        //   key: "ig-msg",
        //   icon: renderIcon(Star01Icon),
        //   tooltip: "Instagram Messages",
        //   comparison: getStatComparison(report.instagramMsg, previousReport?.instagramMsg),
        // },
        {
          label: "Instagram Messages",
          value: reportNumbers(report.instagramMsgIn || 0),
          key: "ig-msg-in",
          icon: renderIcon(MessageNotificationSquareIcon),
          tooltip: t("incomingIgMessages"),
          comparison: getStatComparison(report.instagramMsgIn, previousReport?.instagramMsgIn),
        },
        {
          label: "Instagram Messages",
          value: reportNumbers(report.instagramMsgOut || 0),
          key: "ig-msg-out",
          icon: renderIcon(MessageTextSquare02Icon),
          tooltip: t("outgoingIgMessages"),
          comparison: getStatComparison(report.instagramMsgOut, previousReport?.instagramMsgOut),
        },
      ],
    });
  }

  if (report.hasOwnProperty("reviews") && fields.includes("reviews")) {
    data.push({
      label: t("reviews"),
      metrics: [
        {
          label: "Reviews",
          value: reportNumbers(report.reviews || 0),
          key: "reviews",
          icon: renderIcon(Star01Icon),
          tooltip: t("totalReviews"),
          comparison: getStatComparison(report.reviews, previousReport?.reviews),
        },
        {
          label: "Positives",
          key: "reviews-p",
          value: reportNumbers(report.reviewsPositive || 0),
          icon: renderIcon(FaceHappyIcon),
          tooltip: t("positiveReviews"),
          comparison: getStatComparison(report.reviewsPositive, previousReport?.reviewsPositive),
        },
        {
          label: "Negatives",
          key: "reviews-n",
          value: reportNumbers(report.reviewsNegative || 0),
          icon: renderIcon(FaceSadIcon),
          tooltip: t("negativeReviews"),
          comparison: getStatComparison(report.reviewsNegative, previousReport?.reviewsNegative),
        },
      ],
    });
  }
  // console.log(data);
  return [data.slice(0, 4), data.slice(4)];
}
