import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

import HomePage from "@/pages/Home.vue";
import LocationDashboardReport from "@/components/LocationDashboardReport.vue";
import ScheduleList from "@/components/schedule/ScheduleList.vue";
import TransferList from "@/components/locationTransfer/TransferList.vue";

const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/sub-accounts/dashboard/:locationId",
    component: LocationDashboardReport,
  },
  {
    path: "/sub-accounts/schedules",
    component: ScheduleList,
  },
  {
    path: "/sub-accounts/transfer-locations",
    component: TransferList,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export { router };
