<template>
  <UICard class="rollup__charts-card h-full" :class="{ 'select-card': selection && selectMode }">
    <template #header>
      <div class="flex flex-row items-center justify-between">
        <div
          class="chart-draggble-handler flex items-center"
          :class="{ 'cursor-pointer': isDraggble }"
        >
          <Menu05Icon v-if="isDraggble" class="mr-2 h-4 w-4" />
          <UITextLgMedium>{{ chartTitle }}</UITextLgMedium>
        </div>
        <div>
          <UICheckbox
            v-show="selectMode"
            id="checkbox"
            v-model:checked="selection"
            @update:checked="insightSelect"
          ></UICheckbox>
        </div>
      </div>
    </template>

    <div class="flex">
      <div
        v-for="label in columns"
        :id="'pg-agency-location-reporting__tab-outreach-' + label.type"
        :key="label.label"
        class="mr-4 cursor-pointer p-2"
        :class="[
          selectedMessageType === label.type ? 'border-x-0 border-b-4 border-t-0 border-solid' : '',
          label.borderColor,
        ]"
        @click="selectedMessageType = label.type"
      >
        <UITextSmMedium class="text-gray-500">{{ label.label }}</UITextSmMedium>
        <UITooltip :id="'loc-messages-' + label.label + '__stat_title'">
          <template #trigger>
            <UITextLgMedium class="label-value">{{
              $filters.compactNumbers(label.count || 0)
            }}</UITextLgMedium>
          </template>
          {{ $filters.reportNumbers(label.count || 0) }}
        </UITooltip>
      </div>
    </div>

    <div v-show="isLoading" class="flex h-full w-full items-center justify-center">
      <UISpin :show="true"></UISpin>
    </div>
    <div v-show="!isLoading">
      <v-chart class="chart" :option="option" autoresize />
    </div>
  </UICard>
</template>

<script lang="ts">
import { ReportingService } from "@/services/ReportingService";
import { useAppStore } from "@/store/app";
import { Menu05Icon } from "@gohighlevel/ghl-icons/24/outline";
import {
UICard,
UICheckbox,
UISpin,
UITextLgMedium,
UITextSmMedium,
UITooltip,
} from "@gohighlevel/ghl-ui";
import { sumBy } from "lodash";
import VChart, { THEME_KEY } from "vue-echarts";

export default {
  components: {
    UISpin,
    VChart,
    UICard,
    UITextLgMedium,
    UITextSmMedium,
    UITooltip,
    Menu05Icon,
    UICheckbox,
  },
  provide: {
    [THEME_KEY]: "shine",
  },
  props: {
    isDraggble: {
      type: Boolean,
    },
    type: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: true,
    },
    chartTitle: {
      type: String,
      required: true,
    },
    selectMode: {
      type: Boolean,
      required: true,
    },
    checkedInsights: {
      type: Array,
      required: true,
    },
  },
  emits: ["insightSelectEvent"],
  data() {
    return {
      isLoading: false,
      selection: false,
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          bottom: 10,
          data: [],
        },
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: this.$t("outbound"),
            type: "bar",
            barGap: "20%",
            barWidth: 8,
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              color: "#2970FF",
              borderRadius: [10, 10, 0, 0],
            },
            data: [1, 2, 3],
          },
        ],
      },
      columns: [
        {
          type: "email_message",
          label: this.$t("emails"),
          count: 0,
          data: [],
          color: "text-blue-500",
          borderColor: "border-blue-500",
        },
        {
          type: "sms_message",
          label: this.$t("sms"),
          color: "text-green-500",
          count: 0,
          borderColor: "border-green-500",
          data: [],
        },
        {
          type: "phone_call",
          label: this.$t("calls"),
          color: "text-orange-500",
          borderColor: "border-orange-500",
          count: 0,
          data: [],
        },
        {
          type: "fb_message",
          label: this.$t("facebook"),
          color: "text-red-500",
          borderColor: "border-red-500",
          count: 0,
          data: [],
        },
        {
          type: "gmb_message",
          label: "GBP",
          color: "text-pink-500",
          borderColor: "border-pink-500",
          count: 0,
          data: [],
        },
        {
          type: "ig_message",
          label: this.$t("instagram"),
          color: "text-purple-500",
          borderColor: "border-purple-500",
          count: 0,
          data: [],
        },
      ],
      messsagesData: {},
      selectedMessageType: "email_message",
    };
  },
  computed: {
    selectedColumnData() {
      return this.columns.find((x) => x.type === this.selectedMessageType) || [];
    },
  },
  watch: {
    $route() {
      this.loadData();
    },
    checkedInsights() {
      this.fillCheckBox();
    },
    selectedColumnData() {
      this.setGraphDataForSelectedMessageType();
    },
  },
  mounted() {
    this.loadData();
    this.fillCheckBox();
  },
  methods: {
    async loadData() {
      const start = new Date(this.$route.query.start).getTime();
      const end = new Date(this.$route.query.end).getTime();
      if (!this.type || !start || !end) {
        return;
      }
      this.isLoading = true;
      const appStore = useAppStore();

      await Promise.all(
        this.columns.map(async (columnItem, index) => {
          const { data } = await ReportingService.getGraphData({
            locationId: appStore.locationId || (this.$route.params.locationId as string),
            companyId: appStore.companyId,
            type: columnItem.type,
            start: this.$route.query.start as string,
            end: this.$route.query.end as string,
          });

          this.columns[index].data = data;
          const totalCount = sumBy(data, "total");
          this.columns[index].count = totalCount;
        })
      );

      this.setGraphDataForSelectedMessageType();
      this.isLoading = false;
    },

    setGraphDataForSelectedMessageType() {
      if (!this.selectedColumnData) {
        return;
      }

      this.option.series[0].data = this.selectedColumnData?.data.map((x) => x.outbound);
      this.option.xAxis[0].data = this.selectedColumnData?.data.map((x) => x.date);

      this.option.series[1] = {
        name: this.$t("inbound"),
        type: "bar",
        barGap: "20%",
        barWidth: 8,
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: "#84ADFF",
          borderRadius: [10, 10, 0, 0],
        },
        data: this.selectedColumnData?.data.map((x) => x.inbound),
      };

      this.option.legend = {
        data: [this.$t("outbound"), this.$t("inbound")],
      };
    },
    insightSelect(val) {
      this.$emit("insightSelectEvent", { chart: this.type, value: val });
    },
    fillCheckBox() {
      this.selection = this.checkedInsights.includes(this.type, 0);
    },
  },
};
</script>
<style scoped>
.label-value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 38px;
}

.select-card {
  background-color: #eff4ff;
  border: 2px solid #155eef;
}
</style>
