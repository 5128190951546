<template>
  <UICard class="rollup__charts-card h-full" :class="{ 'select-card': selection && selectMode }">
    <template #header>
      <div class="flex flex-row items-center justify-between">
        <div
          class="chart-draggble-handler flex items-center"
          :class="{ 'cursor-pointer': isDraggble }"
        >
          <Menu05Icon v-if="isDraggble" class="mr-2 h-4 w-4" />
          <UITextLgMedium>{{ chartTitle }}</UITextLgMedium>
        </div>
        <div>
          <UICheckbox
            v-show="selectMode"
            id="checkbox"
            v-model:checked="selection"
            @update:checked="insightSelect"
          ></UICheckbox>
        </div>
      </div>
    </template>

    <div class="flex">
      <div v-for="label in labels" :key="label.label" class="mr-20">
        <UITextSmMedium class="text-gray-500">{{ label.label }}</UITextSmMedium>
        <UITooltip :id="'loc-' + chartTitle + '-' + label.label + '__stat_title'">
          <template #trigger>
            <UITextLgMedium class="label-value">{{
              $filters.compactNumbers(label.count || 0)
            }}</UITextLgMedium>
          </template>
          {{ $filters.reportNumbers(label.count || 0) }}
        </UITooltip>
      </div>
    </div>
  </UICard>
</template>

<script lang="ts">
import { UICard, UITextLgMedium, UITextSmMedium, UITooltip, UICheckbox } from "@gohighlevel/ghl-ui";
import { Menu05Icon } from "@gohighlevel/ghl-icons/24/outline";
import { THEME_KEY } from "vue-echarts";
import { useAppStore } from "@/store/app";
import { sumBy } from "lodash";
import { LocationService } from "@/services/LocationService";
import { ConversationService } from "@/services/ConversationService";

export default {
  components: {
    UICard,
    UITextLgMedium,
    UITextSmMedium,
    UITooltip,
    Menu05Icon,
    UICheckbox,
  },
  provide: {
    [THEME_KEY]: "shine",
  },
  props: {
    isDraggble: {
      type: Boolean,
    },
    type: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: true,
    },
    chartTitle: {
      type: String,
      required: true,
    },
    selectMode: {
      type: Boolean,
      required: true,
    },
    checkedInsights: {
      type: Array,
      required: true,
    },
  },
  emits: ["insightSelectEvent"],
  data() {
    return {
      isLoading: false,
      selection: false,
      labels: [] as { label: string; count: number; color?: string }[],
    };
  },
  watch: {
    $route() {
      this.loadData();
    },
    checkedInsights() {
      this.fillCheckBox();
    },
  },
  mounted() {
    this.loadData();
    this.fillCheckBox();
  },
  methods: {
    async loadData() {
      if (!this.type || !this.$route.query.start || !this.$route.query.end) {
        return;
      }

      this.isLoading = true;
      this.labels = [];

      const appStore = useAppStore();

      const locationId = appStore.locationId || (this.$route.params.locationId as string);

      const { data } = await ConversationService.getCountByCategory({ locationId, type: "unread" });

      let totalCount;
      if (!data) {
        totalCount = 0;
      } else {
        totalCount = data.count;
      }

      this.labels.push({
        label: this.$t("unread"),
        count: totalCount,
        color: "text-blue-500",
      });

      this.isLoading = false;
    },
    insightSelect(val: boolean) {
      this.$emit("insightSelectEvent", { chart: this.type, value: val });
    },
    fillCheckBox() {
      this.selection = this.checkedInsights.includes(this.type, 0);
    },
  },
};
</script>

<style scoped>
.label-value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 38px;
}

.select-card {
  background-color: #eff4ff;
  border: 2px solid #155eef;
}
</style>
