export default {
  nl: {
    common: {
      thisWeek: "Deze week",
      lastWeek: "Afgelopen week",
      lastNumberOfDays: "Laatste {numberOfDays} dagen",
      thisMonth: "Deze maand",
    },
    subAccounts: "Gebruikersaccounts",
    submitFeedback: "Verzend Feedback",
    createSubAccount: "Maak Sub-Account aan",
    viewScheduleReports: "Bekijk Geplande Rapporten",
    searchSubAccount: "Zoeken op sub-account",
    columns: "Kolommen",
    sortBy: "Sorteer op",
    selectColumns: "Selecteer kolommen",
    maxColumns: "Maximaal 8 kolommen tegelijkertijd",
    applyColumns: "Toepassen van kolommen",
    activeUsers: "Actieve gebruikers",
    appointments: "Afspraken",
    contacts: "Contacten",
    emails: "E-mails",
    textMessages: "Sms-berichten",
    calls: "Oproepen",
    submissions: "Inzendingen",
    reviews: "Recensies",
    sitesCreated: "Sites Gecreëerd",
    name: "Naam",
    formSubmissions: "Formulierinzendingen",
    surveySubmissions: "Enquête-inzendingen",
    facebookFormSubmissions: "Facebook Formulierinzendingen",
    funnels: "Trechters",
    websites: "Websites",
    forms: "Formulieren",
    surveys: "Enquêtes",
    highestFirst: "Hoogste eerst",
    lowestFirst: "Laagste eerst",
    orderBy: "Sorteer op",
    noMoreLocations: "Geen andere locaties gevonden",
    preparingReport: "We zijn bezig met het voorbereiden van jouw rapport",
    preparingReportDesc: "Sluit of herlaad dit venster niet terwijl de export bezig is",
    outOf: "uit",
    subAccountsExported: "sub-accounts geëxporteerd",
    close: "Sluiten",
    scheduledForDeletion: "Gepland voor verwijdering",
    readyToSell: "Klaar om te verkopen",
    closedWon: "Gesloten (gewonnen)",
    wpSaleSuccessful: "WordPress uitverkoop is succesvol",
    wpNotSold: "WordPress nog niet verkocht",
    yxtSaleSuccessful: "Yext-verkoop is succesvol",
    yxtNotSold: "Yext is nog niet verkocht",
    saasSubInactive: "De SaaS-abonnement is inactief",
    saasSubTrialing: "SaaS-abonnement wordt getest",
    saasSubActive: "SaaS abonnement is actief",
    emailRebillingActive: "Email-hernieuwing is actief",
    emailRebillingInactive: "Email opnieuw factureren is inactief",
    twilioRebillingActive: "Twilio-herfacturering is actief",
    twilioRebillingInactive: "Twilio terugkerende facturatie is inactief",
    wpRebillingActive: "WhatsApp facturatie is actief",
    wpRebillingInactive: "WhatsApp rebilling is inactief",
    saasModeActivated: "SaaS-modus is geactiveerd",
    paymentPending: "Betaling is in behandeling",
    saasModeNotActivated: "SaaS-modus is niet geactiveerd",
    managedByLCPhone: "Beheerd door LC Phone",
    migrationToLCPhone: "Onder migratie naar LC Telefoon",
    notManagedByLCPhone: "Niet beheerd door LC Phone",
    managedByLCEmail: "Beheerd door LC E-mail",
    notMangedByLCEmail: "Niet beheerd door LC E-mail",
    remainingWalletBalance: "Restsaldo van deze SaaS-locatie",
    reports: "Rapporten",
    switchToSubAccount: "Overschakelen naar sub-account",
    manageClient: "Beheer klant",
    subAccountDeleted: "Dit sub-account wordt verwijderd op",
    subAccountDeletedInMinute: "Deze sub-account wordt binnen een minuut verwijderd",
    changedMind: "Als je van gedachten bent veranderd",
    clickHere: "Klik hier",
    subAccountPaused:
      "Deze sub-account is gepauzeerd. Jouw klanten kunnen niet inloggen, maar het bureau wel. Om dit account te hervatten",
    subAccountRestricted: "Dit sub-account is gepauzeerd vanwege een uitstaande goedkeuring",
    subAccountVerificationPending:
      "Dit sub-account is gepauzeerd vanwege de in afwachting zijnde e-mail- en telefoonverificatie door de beheerder van het sub-account",
    subAccountDeclined:
      "Dit sub-account is gepauzeerd vanwege afgewezen goedkeuringsaanvraag. U kunt dit sub-account nog steeds hervatten vanaf",
    here: "hier",
    reviewAndResume: "Deze sub-account beoordelen en hervatten",
    incomingCalls: "Inkomende oproepen",
    outgoingCalls: "Uitgaande gesprekken",
    totalTalktime: "Totale spreektijd",
    incomingEmails: "Binnenkomende e-mails",
    outgoingEmails: "Uitgaande e-mails",
    incomingSms: "Inkomende tekstberichten",
    outgoingSms: "Uitgaande tekstberichten",
    contactsCreated: "Aantal gecreëerde contacten",
    appointmentsBooked: "Aantal gemaakte afspraken",
    totalFormSubmissions: "Totale formulierinzendingen",
    totalSurveySubmissions: "Totale enquêteinzendingen",
    totalFacebookFormSubmissions: "Totaal aantal Facebook formulier indieningen",
    incomingGMBMessages: "Inkomende GBP-berichten",
    outgoingGMBMessages: "Uitgaande GBP-berichten",
    gmbMessages: "GBP Berichten",
    fbMessages: "Facebook berichten",
    incomingFbMessages: "Inkomende Facebookberichten",
    outgoingFbMessages: "Uitgaande Facebookberichten",
    igMessages: "Instagramberichten",
    incomingIgMessages: "Inkomende Instagram-berichten",
    outgoingIgMessages: "Uitgaande Instagram-berichten",
    totalReviews: "Totaal recensies",
    positiveReviews: "Positieve beoordelingen",
    negativeReviews: "Negatieve beoordelingen",
    back: "Terug",
    selectDashboards: "selecteer dashboards",
    next: "Volgende",
    backToSubAccounts: "Terug naar Sub-Accounts",
    pauseSchedule: "Pauzeren schema",
    resumeSchedule: "Hervat planning",
    editSchedule: "Bewerk planning",
    addSchedule: "Voeg schema toe",
    selectReportToAdd: "Selecteer rapporten om toe te voegen in de e-mail",
    selectAll: "selecteer alles",
    pauseScheduleConfirm: "Ben je zeker dat je het rapport schema wilt pauzeren?",
    resumeScheduleConfirm: "Ben je zeker dat je het rapportplanning wilt hervatten?",
    tasks: "Taken",
    conversations: "Gesprekken",
    users: "Gebruikers",
    dealOverview: "Overzicht van deals",
    confirmAction: "Bevestig actie",
    yes: "Ja",
    no: "Nee",
    reportDetails: "Rapportdetails",
    reportCreatedOn: "Rapport gemaakt op",
    searchByEmail: "Zoeken op e-mailadres",
    lastActivity: "Laatste activiteit",
    noRecords: "Geen resultaten gevonden",
    scheduleReport: "Schema Rapport",
    schedule: "Schema",
    save: "Opslaan",
    emailSubject: "E-mailonderwerp",
    frequency: "frequentie",
    daily: "Dagelijks",
    weekly: "Wekelijks",
    monthly: "Maandelijks",
    nextScheduleDate: "Volgende geplande datum",
    startingOn: "beginnend op",
    timeOfDay: "Tijdstip van de dag",
    reportsToTimezone: "Rapport wordt verstuurd in de tijdzone van het sub-account",
    selectRecipients: "Selecteer ontvangers",
    invalidEmail: "U heeft een ongeldig e-mailadres ingevuld",
    testEmail: "Test e-mail",
    selectTestRecipients: "Selecteer testontvangers",
    setupSubject: "Stel het onderwerp hierboven in om het rapport te testen",
    sendTest: "Verstuur test",
    scheduleSimilarSchedules: "Plan een vergelijkbaar rapport in voor andere sub-accounts",
    accountSummary: "Accountoverzicht",
    scheduleList: "Tijdschema lijst",
    subAccountName: "Subaccountnaam",
    nextRunDate: "Volgende uitvoerdatum",
    reportSectionsAdded: "Rapportsecties toegevoegd",
    status: "Toestand",
    scheduleAction: "Geplande Actie",
    cancel: "Annuleren",
    pleaseWait: "Even geduld alstublieft",
    confirm: "Bevestigen",
    booked: "Geboekt",
    confirmed: "Bevestigd",
    showed: "Getoond",
    noShow: "Geen Show",
    cancelled: "Geannuleerd",
    unread: "Ongelezen",
    engagement: "Verloving",
    reaction: "Reactie",
    deliverySummary: "Overzicht van bezorging",
    successfulDelivery: "Succesvolle Bezorging",
    opened: "Geopend",
    clicked: "Klikte",
    replied: "Beantwoord",
    unsubscribed: "Ongeabonneerd",
    complained: "klaagde",
    failed: "Mislukt",
    open: "Geopend",
    won: "Gewonnen",
    abandoned: "verlaten",
    lost: "verloren",
    sent: "Verzonden",
    delivered: "Geleverd",
    faceBookLeadForm: "Facebook Lead Form",
    overdue: "Te laat",
    reviewsReceived: "Ontvangen beoordelingen",
    formBuilders: "Formulierbouwers",
    switchToSaaS: "Overschakelen naar SaaS",
    numberOfContacts: "Aantal contacten",
    numberOfAppointments: "Aantal afspraken",
    outreach: "Bereik",
    notSubAccountUser: "Geen subgebruiker-account",
    searchSubAccName: "Zoeken op naam van ondertekenaarsaccount",
    recipients: "Ontvangers",
    scheduleCreated: "E-mail Rapport schema aangemaakt voor",
    scheduleUpdated: "E-mailrapportage planning bijgewerkt voor",
    scheduleNotCreated: "De e-mailschema voor het rapport kon niet worden aangemaakt voor ",
    scheduleNotUpdated: "E-mailrapport schema kon niet worden bijgewerkt voor",
    total: "Totaal",
    inbound: "Inkomend",
    outbound: "Uitgaand",
    totalEmails: "Totaal aantal e-mails",
    inboundEmails: "Inkomende e-mails",
    outboundEmails: "Uitgaande e-mails",
    totalTextMessages: "Totaal aantal tekstberichten",
    inboundTextMessages: "Inkomende tekstberichten",
    outboundTextMessages: "Uitgaande sms-berichten",
    totalCalls: "Totale oproepen",
    inboundCalls: "Inkomende oproepen",
    outboundCalls: "Uitgaande oproepen",
    faceBookLeadFormSubmissions: "Facebook Lead Form Inzendingen",
    inboundGMBMessages: "Inkomende GBP-berichten",
    outboundGMBMessages: "Uitgaande GBP-berichten",
    totalIgMessages: "Totale Instagram-berichten",
    inboundIgMessages: "Inkomende Instagramberichten",
    outboundIgMessages: "Uitgaande Instagramberichten",
    totalfbMessages: "Totale Facebookberichten",
    inboundfbMessages: "Inkomende Facebookberichten",
    outboundfbMessages: "Uitgaande Facebook-berichten",
    sitesCreatedFunnels: "Gecreëerde sites - Funnels",
    sitesCreatedWebsites: "Websites gemaakt - Websites",
    sitesCreatedForms: "Sites gemaakt - Formulieren",
    sitesCreatedSurveys: "Websites Gemaakt - Enquêtes",
    googleReviews: "Google-beoordelingen",
    facebookReviews: "Facebook Beoordelingen",
    inboundCallDuration: "Inkomende gespreksduur (uur)",
    outboundCallDuration: "Uitgaande gespreksduur (uren)",
    pleaseSelect: "Alstublieft selecteer",
    subAccountIsDeleted: "Dit sub-account is verwijderd",
    subAccountIsPaused: "Dit onder-account is gepauzeerd",
    success: "Succes",
    error: "Fout",
    testEmailSent: "Test e-mail verstuurd",
    testEmailNotSent: "Test e-mail kon niet worden verzonden",
    typeToSearch: "Type om te zoeken",
    reviewRequest: "Beoordelingsverzoeken",
    pendingTransferDescription:
      "Andere instanties hebben gevraagd om sub-accounts naar u over te dragen",
    pendingTransferHeading: "Je hebt nog openstaande verzoeken voor overdracht van subaccounts",
    subAccountTransfer: "Subaccount overschrijvingen",
    manageSubAccTransfer: "Beheer uw verzoek tot overboeking van uw ondergeschikte account",
    subAccRequest: "Sub-account {tabName} aanvragen",
    receive: "Ontvang",
    send: "Verstuur",
    release: "Vrijgave",
    requests: "{count} verzoeken",
    subAccLimit: "Limiet voor het gebruik van subaccounts bereikt!",
    subAccPlanLimit:
      "U heeft 3 van de 3 subaccounts gebruikt die zijn toegestaan onder uw huidige abonnement",
    upgradeTime: "Het is tijd voor een upgrade!",
    upgradeHigher:
      "Gelieve te upgraden naar een hoger plan om de sub-account overdracht te accepteren",
    browse: "Bekijk plannen",
    pendingTransferTitle: "Een overdrachtsverzoek voor dit sub-account is al in behandeling",
    unableTransfer: "Kan sub-account niet overdragen naar dit bureau",
    hippaErrorDescription:
      "Het onderliggende account dat u probeert over te zetten, is HIPAA-ingeschakeld en kan niet worden overgezet naar een niet-HIPAA-agentschap",
    pendingTransferErrorDescription:
      "Gelieve het al bestaande overboekingsverzoek voor dit ondergeschikte account te annuleren voordat u een nieuw verzoek indient",
    receivingAgency: "Ontvangende instantie",
    releasingAgency: "Uitgevende instantie",
    noOfContacts: "Aantal contacten",
    requestedDate: "Gevraagde datum",
    action: "Actie",
    cancelTransfer: "Annuleer overdracht",
    decline: "Afwijzing",
    cancelTransferRequest: "Annuleer verzoek tot overdracht van subaccount?",
    cancelRequestDescription:
      "Het ontvangende agentschap zal dit overdrachtsverzoek niet langer zien en de subrekening {naam} zal niet worden overgedragen",
    declineRequest: "Weiger subaccount overdrachtsverzoek?",
    declineRequestDescription:
      "Het verzoek zal worden geweigerd en het sub-account {naam} zal niet worden overgedragen aan uw bureau",
    approveTransfer: "Goedkeuren van de overdracht van een subaccount naar uw bureau?",
    approveTransferDescription: "{name} sub-account zal worden overgezet naar uw bureau",
    transferNow: "Overboeken nu",
    cancelNow: "Annuleer nu",
    transferErrorDetails:
      "Het subaccount dat u probeert over te dragen kan niet worden overgedragen",
    cancelRequest: "Annuleer verzoek",
    received: "Ontvangen",
    approve: "Goedkeuren",
    goBack: "Ga terug",
    done: "Klaar",
    subAccountTransferred: "Het sub-account is succesvol overgeboekt!",
    subAccountTransferDescription:
      "Houd er rekening mee dat hoewel het sub-account is overgedragen, sommige activa zoals gebruikers, telefoonnummers, Mailgun-accounts, Yext-vermeldingen, enz. nog steeds 10-15 minuten kunnen duren voordat ze zichtbaar zijn op de bestemming",
    activeSubscriptionsAlert:
      "De locatie heeft actieve abonnementen. Annuleer alstublieft alle abonnementen voordat u de locatie overdraagt. Lijst van actieve abonnementen: {subscriptions}",
    emailEngagement: "Emailbetrokkenheid",
    emailReaction: "Emailreactie",
    emailDeliverySummary: "E-mail Bezorgsamenvatting",
    facebook: "Facebook",
    sms: "SMS",
    instagram: "Instagram",
    accountSummarySubAccount: "Account Summary - {subAccountName}",
    test: "Test",
    scheduleExists: "Schedule Exists",
    aToZ: "A - Z",
    zToA: "Z - A",
    onlyAdminUsersCanAddNewSubAccounts: "Only Admin users can add new sub-account's.",
    locationName: "Location Name",
    subAccountDeletedIfChangedMind:
      "This Sub-Account will be deleted on {time}, if you've changed your mind",
    eject: "Uitwerpen",
    unableEjectCancel: "Niet in staat om het verzoek tot verwijdering van subaccount te annuleren",
    EjectErrorDetails:
      "Het verzoek om het Subaccount uit te schakelen dat je probeert te annuleren, kan niet geannuleerd worden",
    cancelEjectRequest: "Annuleer verzoek tot uitwerping van subaccount?",
    cancelEjectRequestDescription:
      "De geselecteerde gebruiker zal niet langer in staat zijn om een nieuw agentschap aan te maken uit het Sub-Account {name}",
      transferCompleted:
        "Transferverzoek voor deze locatie is al verwerkt. Vernieuw alstublieft",
      unauthorizedTransfer:
        "U bent niet gemachtigd om deze handeling uit te voeren. Alleen eigenaars van het agentschap kunnen deze handeling uitvoeren",
  },
};
