import { requests } from ".";
import config from "@/config";

export const ReportingService = {
  getAll: (params) => requests().post("/reporting/rollup", params),

  getGraphData: (params: {
    locationId: string;
    companyId: string;
    type: string;
    start: string;
    end: string;
  }) =>
    requests().get("/reporting/rollup", {
      params,
    }),

  getSMSRollupData: (params: {
    locationId: string;
    start: string;
    end: string;
    companyId?: string;
    timezone?: string;
  }) =>
    requests().get("/reporting/sms/rollup", {
      params,
    }),

  getSMSRollupComparison: (params: {
    locationId: string;
    start: string;
    end: string;
    companyId?: string;
    timezone?: string;
    compare: boolean;
  }) =>
    requests().get("/reporting/sms/rollup", {
      params,
    }),

  getEmailRollupData: (params: {
    locationId: string;
    start: string;
    end: string;
    companyId?: string;
    timezone?: string;
  }) =>
    requests().get("/reporting/emails/rollup", {
      params,
    }),

  getEmailRollupComparison: (params: {
    locationId: string;
    start: string;
    end: string;
    companyId?: string;
    timezone?: string;
    compare: boolean;
  }) =>
    requests().get("/reporting/emails/rollup", {
      params,
    }),

  getAppointmentsRollupData: (params: {
    locationId: string;
    start: string;
    end: string;
    companyId?: string;
    timezone?: string;
  }) =>
    requests().get("/reporting/appointments/rollup", {
      params,
    }),

  getAppointmentsRollupComparison: (params: {
    locationId: string;
    start: string;
    end: string;
    companyId?: string;
    timezone?: string;
    compare: boolean;
  }) =>
    requests().get("/reporting/appointments/rollup", {
      params,
    }),

  getOpportunitiesRollupData: (body: { locationId: string; filters: any; aggregations: any[] }) =>
    requests().post("/opportunities/search", JSON.stringify(body), {
      headers: {
        "content-type": "application/json",
      },
    }),

  getOpportunitiesRollupComparison: (body: {
    locationId: string;
    filters: any;
    aggregations: any[];
  }) =>
    requests().post("/opportunities/search", JSON.stringify(body), {
      headers: {
        "content-type": "application/json",
      },
    }),

  createSchedule: (body: {
    locationId: string;
    subject: string;
    frequency: string;
    insights: string[];
    recipients: string[];
    scheduleDate: Date;
    scheduleTime: string;
    status: string;
  }) =>
    requests().post("/reporting/schedule", JSON.stringify(body), {
      headers: {
        "content-type": "application/json",
      },
    }),

  getSchedulesByLocation: (params: { id: string }) =>
    requests().get(`/reporting/schedule/`, { params }),

  getScheduleReports: (params: {
    locationId: string;
    scheduleId: string;
    page: number;
    limit: number;
    search?: string;
  }) => requests().get(`/reporting/schedule/history`, { params }),

  updateScheduleById: (
    id: string,
    body: {
      locationId: string;
      subject: string;
      frequency: string;
      insights: string[];
      recipients: string[];
      scheduleDate: Date;
      scheduleTime: string;
    }
  ) =>
    requests().put(`/reporting/schedule/${id}`, JSON.stringify(body), {
      headers: {
        "content-type": "application/json",
      },
    }),

  updateScheduleStatus: (id: string, body: { status: string }, params: { locationId: string }) =>
    requests().put(`reporting/schedule/${id}/status`, JSON.stringify(body), {
      headers: {
        "content-type": "application/json",
      },
      params,
    }),

  getSchedulesList: (params: { page: number; limit: number }) =>
    requests().get(`reporting/schedule/list`, { params }),

  sendTestMail: (body: {
    locationId: string;
    name: string;
    subject: string;
    status: string;
    frequency: string;
    insights: string[];
    recipients: string[];
  }) =>
    requests().post(`reporting/schedule/test`, JSON.stringify(body), {
      headers: {
        "content-type": "application/json",
      },
    }),

  funnelSearch: (locationId: string) =>
    requests(config.APP_ENGINE_URLS).get(`/funnels/funnel/list`, {
      params: {
        locationId,
        type: "funnel",
        offset: 0,
        limit: 1,
      },
    }),
  websiteSearch: (locationId: string) =>
    requests(config.APP_ENGINE_URLS).get(`/funnels/funnel/list`, {
      params: {
        locationId,
        type: "website",
        offset: 0,
        limit: 1,
      },
    }),
  formSearch: (locationId: string) =>
    requests().get(`/forms/`, {
      params: {
        locationId,
        type: "form",
        skip: 0,
        limit: 1,
      },
    }),
  surveySearch: (locationId: string) =>
    requests().get(`/surveys/`, {
      params: {
        locationId: locationId,
        type: "survey",
        skip: 0,
        limit: 1,
      },
    }),
};
