export enum TransferStatus {
  PENDING = "pending",
  CANCELED = "canceled",
  COMPLETED = "completed",
  REJECTED = "rejected",
}

export enum EjectStatus {
  PENDING = "pending",
  PAYMENT_PENDING = "payment_pending",
  COMPLETED = "completed",
  CANCELED = "cancelled",
}

export enum TransferReply {
  ACCEPT = "accept",
  REJECT = "reject",
  CANCEL = "cancel",
}
