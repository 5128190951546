import { Copy04Icon, Rocket01Icon } from "@gohighlevel/ghl-icons/24/outline";
import { UIAvatar, UIPopover } from "@gohighlevel/ghl-ui";
import dayjs from "dayjs";
import { h } from "vue";
import { useCopy } from "./useCopy";

export function useTable() {
  const { copiedText, copyToClipboard } = useCopy();

  function maskText(email, length) {
    if (!email) {
      return "";
    }
    return email.length > length ? `${email.slice(0, length)}...` : email;
  }

  function popOverCopy(copyText: string, idNode) {
    const popoverText = copyText === copiedText.value ? "copied" : copyText;
    return h(UIPopover, {}, { trigger: () => idNode, default: () => h("span", popoverText) });
  }

  function renderId(id) {
    const maskedId = `${id.slice(0, 4)}**${id.slice(-4)}`;
    const idNode = h(
      "div",
      {
        class: "flex items-center cursor-pointer",
        style: { maxWidth: "160px" },
        onClick: () => copyToClipboard(id),
      },
      [
        h("div", { class: "hl-text-sm-regular text-gray-600 whitespace-nowrap" }, maskedId),
        h(Copy04Icon, {
          class: "text-primary-500 h-3 w-3 !ml-1.5 flex-shrink-0",
        }),
      ]
    );
    // const popoverText = id === copiedText.value ? "copied" : id;
    // return h(UIPopover, {}, { trigger: () => idNode, default: () => h("span", popoverText) });
    return popOverCopy(id, idNode);
  }

  function renderNameEmailAndLogo(accountData: { name: string; email: string; logo?: string }) {
    const maskedEmail = maskText(accountData.email, 20);
    let idNode;
    if (accountData.logo) {
      idNode = h(
        "div",
        {
          class: "flex items-center gap-2",
          style: { maxWidth: "200px" },
          onClick: () => copyToClipboard(accountData.email),
        },
        [
          h("img", { class: "h-8 w-8 mr-1 rounded-full", src: accountData.logo }),
          h("div", {}, [
            h(
              "div",
              { class: "hl-text-sm-medium text-gray-900 whitespace-nowrap" },
              maskText(accountData.name, 35)
            ),
            h("div", { class: "hl-text-sm-regular whitespace-nowrap" }, maskedEmail),
          ]),
        ]
      );
    } else {
      const name = accountData.name.trim().split(" ");
      const initials =
        name.length === 1
          ? name[0].slice(0, 2).toUpperCase()
          : name[0].slice(0, 1).toUpperCase() + name[name.length - 1].slice(0, 1).toUpperCase();
      idNode = h(
        "div",
        {
          class: "flex items-center gap-2	",
          style: { maxWidth: "200px" },
          onClick: () => copyToClipboard(accountData.email),
        },
        [
          h(UIAvatar, { class: "flex-shrink-0" }, [
            h("div", { class: "m-2 align-middle" }, initials),
          ]),
          h("div", {}, [
            h(
              "div",
              { class: "hl-text-sm-medium text-gray-900 whitespace-nowrap" },
              maskText(accountData.name, 35)
            ),
            h("div", { class: "hl-text-sm-regular whitespace-nowrap" }, maskedEmail),
          ]),
        ]
      );
    }
    return popOverCopy(accountData.email, idNode);
  }

  function renderNameAndEmail(accountData: { name: string; email: string }) {
    const maskedEmail = maskText(accountData.email, 20);
    const idNode = h(
      "div",
      { style: { maxWidth: "200px" }, onClick: () => copyToClipboard(accountData.email) },
      [
        h(
          "div",
          { class: "hl-text-sm-medium text-gray-900 whitespace-nowrap" },
          maskText(accountData.name, 35)
        ),
        h("div", { class: "hl-text-sm-regular whitespace-nowrap" }, maskedEmail),
      ]
    );
    return popOverCopy(accountData.email, idNode);
  }

  function renderDate(timestamp: number | string) {
    const date = typeof timestamp === "number" ? dayjs(timestamp * 1000) : dayjs(timestamp);
    return h("div", {}, [
      h(
        "div",
        { class: "hl-text-sm-medium text-gray-900 whitespace-nowrap" },
        date.format("MMM D, YYYY")
      ),
      h("div", { class: "hl-text-sm-regular whitespace-nowrap" }, date.format("hh:mm A")),
    ]);
  }

  function renderEject() {
    return h(
      "div",
      {
        class: "flex cursor-pointer space-x-2",
      },
      [
        h(Rocket01Icon, {
          class: "text-primary-500 h-5 w-5 !ml-1.5 flex-shrink-0",
        }),
        h(
          "div",
          { class: "hl-text-sm-medium text-gray-900 whitespace-nowrap" },
          "Eject to New Agency"
        ),
      ]
    );
  }

  return { renderId, renderNameAndEmail, renderNameEmailAndLogo, renderDate, renderEject };
}
