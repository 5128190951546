export default {
  de_DE: {
    common: {
      thisWeek: "Diese Woche",
      lastWeek: "Letzte Woche",
      lastNumberOfDays: "Letzte {numberOfDays} Tage",
      thisMonth: "Dieser Monat",
    },
    subAccounts: "Unterkonten",
    submitFeedback: "Feedback einreichen",
    createSubAccount: "Erstellen Sie ein Unterkonto",
    viewScheduleReports: "Geplante Berichte anzeigen",
    searchSubAccount: "Suche nach Unterkonto",
    columns: "Spalten",
    sortBy: "Sortieren nach",
    selectColumns: "Spalten auswählen",
    maxColumns: "Maximal 8 Spalten gleichzeitig",
    applyColumns: "Bewerben Sie Spalten",
    activeUsers: "Aktive Benutzer",
    appointments: "Termine",
    contacts: "Kontakte",
    emails: "Emails",
    textMessages: "SMS-Nachrichten",
    calls: "Anrufe",
    submissions: "Einreichungen",
    reviews: "Bewertungen",
    sitesCreated: "Seiten erstellt",
    name: "Name",
    formSubmissions: "Formularübermittlungen",
    surveySubmissions: "Umfrageeinreichungen",
    facebookFormSubmissions: "Facebook-Formulareingaben",
    funnels: "Trichter",
    websites: "Websites",
    forms: "Formen",
    surveys: "Umfragen",
    highestFirst: "Höchste zuerst",
    lowestFirst: "Niedrigste zuerst",
    orderBy: "Bestellen nach",
    noMoreLocations: "Keine weiteren Standorte gefunden",
    preparingReport: "Wir bereiten Ihren Bericht vor",
    preparingReportDesc:
      "Schließen oder aktualisieren Sie dieses Fenster nicht, während der Export läuft",
    outOf: "aus",
    subAccountsExported: "Unterkonten exportiert",
    close: "schließen",
    scheduledForDeletion: "Geplant zur Löschung",
    readyToSell: "Bereit zum Verkaufen",
    closedWon: "Geschlossen (gewonnen)",
    wpSaleSuccessful: "Der WordPress-Verkauf war erfolgreich",
    wpNotSold: "WordPress noch nicht verkauft",
    yxtSaleSuccessful: "Der Verkauf von Yext war erfolgreich",
    yxtNotSold: "Yext noch nicht verkauft",
    saasSubInactive: "SaaS-Abonnement ist inaktiv",
    saasSubTrialing: "SaaS-Abonnement wird getestet",
    saasSubActive: "SaaS-Abonnement ist aktiv",
    emailRebillingActive: "Email-Gebührenabrechnung ist aktiv",
    emailRebillingInactive: "Die E-Mail-Umladung ist inaktiv",
    twilioRebillingActive: "Die Twilio-Nachabrechnung ist aktiv",
    twilioRebillingInactive: "Twilio Neuberechnung ist nicht aktiv",
    wpRebillingActive: "WhatsApp-Rechnungsstellung ist aktiv",
    wpRebillingInactive: "Die Rechnungsstellung für WhatsApp ist deaktiviert",
    saasModeActivated: "SaaS-Modus ist aktiviert",
    paymentPending: "Die Zahlung steht aus",
    saasModeNotActivated: "Der SaaS-Modus ist nicht aktiviert",
    managedByLCPhone: "Verwaltet von LC Phone",
    migrationToLCPhone: "Unter Migration auf LC Phone",
    notManagedByLCPhone: "Nicht verwaltet von LC Phone",
    managedByLCEmail: "Verwaltet von LC E-Mail",
    notMangedByLCEmail: "Nicht verwaltet von der LC-E-Mail",
    remainingWalletBalance: "Verbleibendes Portemonnaie-Guthaben für diesen Standort der SaaS",
    reports: "Berichte",
    switchToSubAccount: "Wechseln zu Nebenkonto",
    manageClient: "Kunden verwalten",
    subAccountDeleted: "Dieses Unterkonto wird gelöscht am",
    subAccountDeletedInMinute: "Dieses Unterkonto wird in einer Minute gelöscht",
    changedMind: "Wenn du deine Meinung geändert hast",
    clickHere: "Hier klicken",
    subAccountPaused:
      "Dieses Unterkonto ist pausiert. Ihre Kunden können sich nicht einloggen, aber die Agentur kann. Um dieses Konto fortzusetzen:",
    subAccountRestricted: "Dieses Unterkonto ist aufgrund ausstehender Genehmigung pausiert",
    subAccountVerificationPending:
      "Dieses Unterkonto ist aufgrund ausstehender E-Mail- und Telefonüberprüfung durch den Unterkonto-Administrator pausiert",
    subAccountDeclined:
      "Dieses Unterkonto ist aufgrund einer abgelehnten Genehmigungsanfrage pausiert. Du kannst dieses Unterkonto jedoch weiterhin von neuem starten",
    here: "hier",
    reviewAndResume: "Diese Unterabteilung überprüfen und fortsetzen",
    incomingCalls: "Eingehende Anrufe",
    outgoingCalls: "Ausgehende Anrufe",
    totalTalktime: "Gesamte Gesprächszeit",
    incomingEmails: "Eingehende E-Mails",
    outgoingEmails: "Ausgehende E-Mails",
    incomingSms: "Eingehende Textnachrichten",
    outgoingSms: "Ausgehende Textnachrichten",
    contactsCreated: "Anzahl der erstellten Kontakte",
    appointmentsBooked: "Anzahl der vereinbarten Termine",
    totalFormSubmissions: "Gesamtanzahl der Formulareingaben",
    totalSurveySubmissions: "Gesamtzahl der Umfrageeinreichungen",
    totalFacebookFormSubmissions: "Gesamtzahl der Facebook-Formulareinreichungen",
    incomingGMBMessages: "Eingehende GBP-Nachrichten",
    outgoingGMBMessages: "Ausgehende GBP-Nachrichten",
    gmbMessages: "GBP Nachrichten",
    fbMessages: "Facebook-Nachrichten",
    incomingFbMessages: "Eingehende Facebook-Nachrichten",
    outgoingFbMessages: "Ausgehende Facebook-Nachrichten",
    igMessages: "Instagram-Nachrichten",
    incomingIgMessages: "Eingehende Instagram-Nachrichten",
    outgoingIgMessages: "Ausgehende Instagram-Nachrichten",
    totalReviews: "Gesamtbewertungen",
    positiveReviews: "Positive Bewertungen",
    negativeReviews: "Negative Bewertungen",
    back: "Rückseite",
    selectDashboards: "Wähle Dashboards aus",
    next: "Weiter",
    backToSubAccounts: "Zurück zu Unterkonten",
    pauseSchedule: "Pauseplan",
    resumeSchedule: "Weiter im Zeitplan",
    editSchedule: "Bearbeiten Sie den Zeitplan",
    addSchedule: "Termin hinzufügen",
    selectReportToAdd: "Wählen Sie Berichte aus, die der E-Mail hinzugefügt werden sollen",
    selectAll: "Alles auswählen",
    pauseScheduleConfirm: "Sind Sie sicher, dass Sie den Berichtszeitplan pausieren möchten?",
    resumeScheduleConfirm: "Bist du sicher, dass du den Berichtsplan fortsetzen möchtest?",
    tasks: "Aufgaben",
    conversations: "Gespräche",
    users: "Benutzer",
    dealOverview: "Dealübersicht",
    confirmAction: "Bestätige Aktion",
    yes: "Ja",
    no: "Nein",
    reportDetails: "Berichtsdetails",
    reportCreatedOn: "Bericht erstellt am",
    searchByEmail: "Suche per E-Mail",
    lastActivity: "Letzte Aktivität",
    noRecords: "Keine Datensätze gefunden",
    scheduleReport: "Bericht planen",
    schedule: "Zeitplan",
    save: "Speichern",
    emailSubject: "Betreff der E-Mail",
    frequency: "Frequenz",
    daily: "täglich",
    weekly: "Wöchentlich",
    monthly: "Monatlich",
    nextScheduleDate: "Nächstes geplantes Datum",
    startingOn: "Anfangend am",
    timeOfDay: "Tageszeit",
    reportsToTimezone: "Der Bericht wird in der Zeitzone des Unterkontos gesendet",
    selectRecipients: "Wählen Sie Empfänger aus",
    invalidEmail: "Sie haben eine ungültige E-Mail-Adresse eingegeben",
    testEmail: "Test-E-Mail",
    selectTestRecipients: "Wählen Sie Testempfänger aus",
    setupSubject: "Richten Sie das Thema oben ein, um den Bericht zu testen",
    sendTest: "Test senden",
    scheduleSimilarSchedules: "Planen Sie einen ähnlichen Bericht für andere Unterkonten",
    accountSummary: "Kontozusammenfassung",
    scheduleList: "Zeitplan Liste",
    subAccountName: "unterkonto Name",
    nextRunDate: "Nächster Ausführungstermin",
    reportSectionsAdded: "Berichtsabschnitte hinzugefügt",
    status: "Status",
    scheduleAction: "Geplante Aktion",
    cancel: "stornieren",
    pleaseWait: "Bitte warten",
    confirm: "Bestätigen",
    booked: "gebucht",
    confirmed: "bestätigt",
    showed: "gezeigt",
    noShow: "Nichterscheinen",
    cancelled: "Abgebrochen",
    unread: "Ungelesen",
    engagement: "Engagement",
    reaction: "Reaktion",
    deliverySummary: "Lieferschein",
    successfulDelivery: "Erfolgreiche Lieferung",
    opened: "Geöffnet",
    clicked: "Geklickt",
    replied: "antwortete",
    unsubscribed: "Abgemeldet",
    complained: "beschwert",
    failed: "Gescheitert",
    open: "Offen",
    won: "Gewonnen",
    abandoned: "Verlassen",
    lost: "Verloren",
    sent: "Gesendet",
    delivered: "geliefert",
    faceBookLeadForm: "Facebook-Leads-Formular",
    overdue: "Überfällig",
    reviewsReceived: "Bewertungen erhalten",
    formBuilders: "Formularentwickler",
    switchToSaaS: "Wechseln Sie zu SaaS",
    numberOfContacts: "Anzahl der Kontakte",
    numberOfAppointments: "Anzahl der Termine",
    outreach: "Outreach",
    notSubAccountUser: "Kein Unterkonto-Benutzer",
    searchSubAccName: "Nach Unterkonto-Namen suchen",
    recipients: "Empfänger",
    scheduleCreated: "E-Mail-Berichtszeitplan erstellt für",
    scheduleUpdated: "E-Mail-Berichtsplan aktualisiert für",
    scheduleNotCreated: "Der E-Mail-Plan konnte nicht erstellt werden für",
    scheduleNotUpdated: "E-Mail-Berichtsplan konnte nicht aktualisiert werden für",
    total: "Gesamt",
    inbound: "Eingehend",
    outbound: "Ausgehend",
    totalEmails: "Gesamtzahl der E-Mails",
    inboundEmails: "Eingehende E-Mails",
    outboundEmails: "Ausgehende E-Mails",
    totalTextMessages: "Gesamtzahl der Textnachrichten",
    inboundTextMessages: "Eingehende Textnachrichten",
    outboundTextMessages: "Ausgehende Textnachrichten",
    totalCalls: "Gesamtanzahl der Anrufe",
    inboundCalls: "Eingehende Anrufe",
    outboundCalls: "Ausgehende Anrufe",
    faceBookLeadFormSubmissions: "Facebook Lead Form Übermittlungen",
    inboundGMBMessages: "Eingehende GBP-Nachrichten",
    outboundGMBMessages: "Ausgehende GBP-Nachrichten",
    totalIgMessages: "Gesamtzahl der Instagram-Nachrichten",
    inboundIgMessages: "Eingehende Instagram-Nachrichten",
    outboundIgMessages: "Ausgehende Instagram-Nachrichten",
    totalfbMessages: "Gesamtzahl der Facebook-Nachrichten",
    inboundfbMessages: "Eingehende Facebook-Nachrichten",
    outboundfbMessages: "Ausgehende Facebook-Nachrichten",
    sitesCreatedFunnels: "Websites erstellt - Funnels",
    sitesCreatedWebsites: "Websites erstellt - Websites",
    sitesCreatedForms: "Seiten erstellt - Formulare",
    sitesCreatedSurveys: "Seiten erstellt - Umfragen",
    googleReviews: "Google Bewertungen",
    facebookReviews: "Facebook-Rezensionen",
    inboundCallDuration: "Eingehende Anrufdauer (Stunden)",
    outboundCallDuration: "Ausgehende Anrufdauer (Stunden)",
    pleaseSelect: "Bitte auswählen",
    subAccountIsDeleted: "Dieses Unterkonto wurde gelöscht",
    subAccountIsPaused: "Dieses Unterkonto wurde pausiert",
    success: "Erfolg",
    error: "Fehler",
    testEmailSent: "Test-E-Mail gesendet",
    testEmailNotSent: "Die Test-E-Mail konnte nicht gesendet werden",
    reviewRequest: "Überprüfungsanforderungen",
    pendingTransferDescription:
      "Andere Agenturen haben darum gebeten, Unterkonten an Sie zu übertragen",
    pendingTransferHeading: "Sie haben ausstehende Unterkontenüberweisungsanfragen",
    subAccountTransfer: "Unter-Konten Überweisungen",
    manageSubAccTransfer: "Verwalten Sie Ihre Unterkonto-Überweisungsanfragen",
    subAccRequest: "Unterkonto {tabName} Anfragen",
    receive: "Erhalten",
    send: "Senden",
    release: "Freigabe",
    requests: "{count} Anfragen",
    subAccLimit: "Unterkonten Nutzungsmaximum erreicht!",
    subAccPlanLimit:
      "Sie haben die maximale Anzahl von 3 Unterkonten verwendet, die unter Ihrem aktuellen Plan erlaubt sind",
    upgradeTime: "Es ist Zeit für ein Upgrade!",
    upgradeHigher:
      "Bitte aktualisieren Sie auf einen höheren Tarif, um die Unterkontoübertragung zu akzeptieren",
    browse: "Durchsuche Pläne",
    pendingTransferTitle: "Es liegt bereits ein Übertragungsantrag für dieses Unterkonto vor",
    unableTransfer: "Nicht möglich, Teil-Konto an diese Agentur zu übertragen",
    hippaErrorDescription:
      "Das Unterkonto, das Sie übertragen möchten, ist HIPAA-fähig und kann nicht an eine nicht-HIPAA-konforme Agentur übertragen werden",
    pendingTransferErrorDescription:
      "Bitte stornieren Sie den bereits bestehenden Überweisungsauftrag für dieses Unterkonto, bevor Sie einen neuen erstellen",
    receivingAgency: "Empfangsstelle",
    releasingAgency: "Freisetzungsagentur",
    noOfContacts: "Anzahl der Kontakte",
    requestedDate: "Gewünschtes Datum",
    action: "Handlung",
    cancelTransfer: "Abbrechen Überweisung",
    decline: "Abnahme",
    cancelTransferRequest: "Abbrechen Sie die Anforderung zur Übertragung des Unterkontos?",
    cancelRequestDescription:
      "Die empfangende Stelle wird diesen Überweisungsantrag nicht mehr sehen, und das Unterkonto {Name} wird nicht übertragen werden",
    declineRequest: "Ablehnen der Anfrage zur Unterkontoübertragung?",
    declineRequestDescription:
      "Die Anfrage wird abgelehnt und das Unterkonto {name} wird nicht an Ihre Agentur übertragen",
    approveTransfer: "Genehmigen Sie die Unterkontenübertragung an Ihre Agentur?",
    approveTransferDescription: "{name} Unterkonto wird an Ihre Agentur übertragen",
    transferNow: "Überweisung jetzt",
    cancelNow: "Abbrechen Sie jetzt",
    transferErrorDetails:
      "Das Unterkonto, das Sie übertragen möchten, kann nicht übertragen werden",
    cancelRequest: "Abbrechen Anfrage",
    received: "Erhalten",
    approve: "Genehmigen",
    goBack: "Zurück gehen",
    done: "Fertig",
    subAccountTransferred: "Das Unterkonto wurde erfolgreich übertragen!",
    subAccountTransferDescription:
      "Bitte beachten Sie, dass obwohl das Unterkonto übertragen wurde, einige Elemente wie Benutzer, Telefonnummern, Mailgun-Konten, Yext-Einträge usw. möglicherweise noch 10-15 Minuten benötigen, um im Ziel sichtbar zu werden",
    activeSubscriptionsAlert:
      "Der Standort hat aktive Abonnements. Bitte kündigen Sie alle Abonnements, bevor Sie den Standort übertragen. Liste aktiver Abonnements: {subscriptions}",
    typeToSearch: "Gib hier deine Suchanfrage ein",
    emailEngagement: "E-Mail-Engagement",
    emailReaction: "E-Mail-Reaktion",
    emailDeliverySummary: "E-Mail-Zustellungsübersicht",
    facebook: "Facebook",
    sms: "SMS",
    instagram: "Instagram",
    accountSummarySubAccount: "Account Summary - {subAccountName}",
    test: "Test",
    scheduleExists: "Schedule Exists",
    aToZ: "A - Z",
    zToA: "Z - A",
    onlyAdminUsersCanAddNewSubAccounts: "Only Admin users can add new sub-account's.",
    locationName: "Location Name",
    subAccountDeletedIfChangedMind:
      "This Sub-Account will be deleted on {time}, if you've changed your mind",
    eject: "Auswerfen",
    unableEjectCancel: "Nicht in der Lage, die Anfrage zur Ausweisung des Unterkontos abzubrechen",
    EjectErrorDetails:
      "Die Unterkontenauswurf-Anforderung, die Sie zu stornieren versuchen, kann nicht storniert werden",
    cancelEjectRequest: "Anforderung zum Abmelden eines Unterkontos abbrechen?",
    cancelEjectRequestDescription:
      "Der ausgewählte Benutzer kann keine neue Agentur mehr aus dem Unterkonto {name} erstellen",
      transferCompleted:
        'Übertragungsanforderung für diesen Standort wurde bereits verarbeitet. Bitte aktualisieren',
      unauthorizedTransfer:
        'Sie sind nicht berechtigt, diese Aktion auszuführen. Nur Agenturinhaber dürfen diese Aktion ausführen',
  },
};
