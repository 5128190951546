<template>
  <UIModal id="modal" v-model:show="showModalComputed" @update:show="modalShowEvent">
    <template #header>
      <UIModalHeader id="modal-header" :title="data.propTitle" @close="cancelCallback">
      </UIModalHeader>
    </template>
    <slot name="content"></slot>
    <div class="flow-row flex justify-center gap-3 p-1 pt-3">
      <UIButton id="cancel-btn" type="default" class="w-1/2" @click="cancelCallback"
        >{{ data.buttons.secondary }}
      </UIButton>
      <UIButton
        id="confirm-btn"
        type="primary"
        class="w-1/2"
        :disabled="btnDisable"
        @click="submit"
      >
        {{ confirmText }}
      </UIButton>
    </div>
  </UIModal>
</template>

<script>
import { UIModal, UIModalHeader, UIModalFooter, UIButton, UISpin } from "@gohighlevel/ghl-ui";

export default {
  components: {
    UIModal,
    UIModalHeader,
    UIButton,
  },
  props: {
    showModal: { type: Boolean },
    data: { default: undefined },
    btnDisable: { type: Boolean },
    spinner: { type: Boolean },
  },
  emits: ["close-modal", "submit"],
  data() {
    return {
      content: "",
    };
  },
  computed: {
    confirmText() {
      if (this.data.buttons.primary) {
        return this.data.buttons.primary;
      }
      return this.btnDisable && this.spinner ? this.$t("pleaseWait") : this.$t("confirm");
    },
    showModalComputed() {
      return this.showModal;
    },
  },
  methods: {
    updateContent(content) {
      this.content = content;
    },
    modalShowEvent(val) {
      if (val === false) {
        this.$emit("close-modal", val);
      }
    },
    cancelCallback() {
      this.$emit("close-modal", false);
    },
    submit() {
      this.$emit("submit");
    },
  },
};
</script>
