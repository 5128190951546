<template>
  <UICard class="rollup__charts-card h-full" :class="{ 'select-card': selection && selectMode }">
    <template #header>
      <div class="flex flex-row items-center justify-between">
        <div
          class="chart-draggble-handler flex items-center"
          :class="{ 'cursor-pointer': isDraggble }"
        >
          <Menu05Icon v-if="isDraggble" class="mr-2 h-4 w-4" />
          <UITextLgMedium>{{ chartTitle }}</UITextLgMedium>
        </div>
        <div>
          <UICheckbox
            v-show="selectMode"
            id="checkbox"
            v-model:checked="selection"
            @update:checked="insightSelect"
          ></UICheckbox>
        </div>
      </div>
    </template>

    <div class="flex">
      <div v-for="label in labels" :key="label.label" class="total-label mr-20">
        <UITextSmMedium class="text-gray-500">{{ label.label }}</UITextSmMedium>
        <UITooltip :id="'loc-deals-' + label.label + '__stat_title'">
          <template #trigger>
            <UITextLgMedium class="label-value"
              >{{ $filters.compactNumbers(label.count || 0) }}
              <sup class="text-xs">
                ({{ currencySymbol }}{{ $filters.compactNumbers(label.revenue || 0) }})
              </sup>
            </UITextLgMedium>
          </template>
          {{ $filters.reportNumbers(label.count || 0) }}
        </UITooltip>

        <div
          v-if="label.comparison"
          v-show="label.comparison"
          class="compare pl-1 pr-1"
          :class="label.comparison && label.comparison.classes"
        >
          <UITextMdMedium
            :id="'loc-' + label.label + '__stat_compare'"
            class="flex items-center p-1 font-semibold"
            style="font-weight: 500; font-size: 12px; line-height: 18px"
          >
            <component
              :is="label.comparison.icon"
              v-if="label.comparison.icon"
              :id="'loc-' + label.label + '__stat_compare_icon'"
              class="h-3 w-3"
            />
            <span>{{ label.comparison.value }}%</span>
          </UITextMdMedium>
        </div>
      </div>
    </div>

    <div v-show="isLoading" class="flex h-full w-full items-center justify-center">
      <UISpin :show="true"></UISpin>
    </div>
    <div v-show="!isLoading">
      <v-chart class="chart" :option="option" autoresize />
    </div>
  </UICard>
</template>

<script lang="ts">
import { getStatComparison } from "@/helpers/report_helper";
import { LocationService } from "@/services/LocationService";
import { ReportingService } from "@/services/ReportingService";
import { useAppStore } from "@/store/app";
import { Menu05Icon } from "@gohighlevel/ghl-icons/24/outline";
import {
UICard,
UICheckbox,
UISpin,
UITextLgMedium,
UITextMdMedium,
UITextSmMedium,
UITooltip,
} from "@gohighlevel/ghl-ui";
import { sumBy } from "lodash";
import VChart, { THEME_KEY } from "vue-echarts";
import { getESQuery, symbol } from "./OpportunitiesAPIHelper";

export default {
  components: {
    UITextMdMedium,
    VChart,
    UICard,
    UITextLgMedium,
    UITextSmMedium,
    UISpin,
    UITooltip,
    Menu05Icon,
    UICheckbox,
  },
  provide: {
    [THEME_KEY]: "shine",
  },
  props: {
    isDraggble: {
      type: Boolean,
    },
    type: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: true,
    },
    chartTitle: {
      type: String,
      required: true,
    },
    selectMode: {
      type: Boolean,
      required: true,
    },
    checkedInsights: {
      type: Array,
      required: true,
    },
  },
  emits: ["insightSelectEvent"],
  data() {
    return {
      isLoading: false,
      selection: false,
      country: "US",
      labels: [] as {
        label: string;
        count: number;
        color?: string;
        index: number;
        comparison?: any;
        revenue?: number;
      }[],
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          bottom: 10,
          data: [],
        },
        xAxis: [
          {
            type: "category",
            axisTick: { show: false, interval: 0 },
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [],
      },
      column: {
        type: "opportunity",
        label: this.$t("sms"),
        color: "text-green-500",
        count: 0,
        borderColor: "border-green-500",
        data: [],
      },
    };
  },
  computed: {
    currencySymbol() {
      return symbol(this.country);
    },
  },
  watch: {
    $route() {
      this.loadData();
    },
    checkedInsights() {
      this.fillCheckBox();
    },
  },
  mounted() {
    this.loadData();
    this.fillCheckBox();
  },
  methods: {
    async loadData() {
      const start = this.$route.query.start as string;
      const end = this.$route.query.end as string;
      if (!this.type || !start || !end) {
        return;
      }

      this.isLoading = true;
      const appStore = useAppStore();
      const locationId = appStore.locationId || (this.$route.params.locationId as string);

      const {
        data: { location },
      } = await LocationService.getById(
        appStore.companyId,
        this.$route.params.locationId as string
      );
      this.country = location.country;

      const esBody = { startDate: start, endDate: end, locationId };
      const { data } = await ReportingService.getOpportunitiesRollupData(getESQuery(esBody, false));

      const { data: previousRangeData } = await ReportingService.getOpportunitiesRollupComparison(
        getESQuery(esBody, true)
      );
      const { open, won, abandoned, lost, openRevenue, wonRevenue, abandonedRevenue, lostRevenue } =
        this.parseData(data);

      this.labels = [];
      this.labels.push({
        label: this.$t("open"),
        count: open,
        color: "color-blue",
        index: 0,
        comparison: getStatComparison(open, this.parsePrevious(previousRangeData, "open")),
        revenue: openRevenue,
      });
      this.labels.push({
        label: this.$t("won"),
        count: won,
        color: "color-green",
        index: 1,
        comparison: getStatComparison(won, this.parsePrevious(previousRangeData, "won")),
        revenue: wonRevenue,
      });
      this.labels.push({
        label: this.$t("abandoned"),
        count: abandoned,
        color: "color-orange",
        index: 2,
        comparison: getStatComparison(
          abandoned,
          this.parsePrevious(previousRangeData, "abandoned"),
          true
        ),
        revenue: abandonedRevenue,
      });
      this.labels.push({
        label: this.$t("lost"),
        count: lost,
        color: "color-red",
        index: 3,
        comparison: getStatComparison(lost, this.parsePrevious(previousRangeData, "lost"), true),
        revenue: lostRevenue,
      });

      this.option.xAxis[0].data = data.aggregations.dateFilters.buckets.map((x) => x.key);

      this.option.series[0] = {
        name: this.$t("open"),
        type: "bar",
        barWidth: 8,
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: "#2970FF",
          borderRadius: [10, 10, 0, 0],
        },
        data: this.parseSeries(data, "open"),
      };
      this.option.series[1] = {
        name: this.$t("won"),
        type: "bar",
        barWidth: 8,
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: "#12B76A",
          borderRadius: [10, 10, 0, 0],
        },
        data: this.parseSeries(data, "won"),
      };
      this.option.series[3] = {
        name: this.$t("abandoned"),
        type: "bar",
        barWidth: 8,
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: "#F79009",
          borderRadius: [10, 10, 0, 0],
        },
        data: this.parseSeries(data, "abandoned"),
      };
      this.option.series[4] = {
        name: this.$t("lost"),
        type: "bar",
        barGap: "20%",
        barWidth: 8,
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: "#F04438",
          borderRadius: [10, 10, 0, 0],
        },
        data: this.parseSeries(data, "lost"),
      };
      this.isLoading = false;
    },
    insightSelect(val) {
      this.$emit("insightSelectEvent", { chart: this.type, value: val });
    },
    fillCheckBox() {
      this.selection = this.checkedInsights.includes(this.type, 0);
    },
    parseData(data: any) {
      return {
        open: this.parseSum(data, "open"),
        won: this.parseSum(data, "won"),
        abandoned: this.parseSum(data, "abandoned"),
        lost: this.parseSum(data, "lost"),
        openRevenue: this.parseRevenue(data, "open"),
        wonRevenue: this.parseRevenue(data, "won"),
        abandonedRevenue: this.parseRevenue(data, "abandoned"),
        lostRevenue: this.parseRevenue(data, "lost"),
      };
    },
    parseSum(data: any, field: string) {
      // returns the count of the docs wrt to the field passed
      return sumBy(data.aggregations.dateFilters.buckets, function (dateBucket: any) {
        return (
          dateBucket.statusFilters.buckets.find((x) => x.key.toLowerCase() === field)?.docCount || 0
        );
      });
    },
    parseRevenue(data: any, field: string) {
      // returns the sum of the monetaryValue of a specific status field
      return sumBy(data.aggregations.dateFilters.buckets, function (dateBucket: any) {
        return (
          dateBucket.statusFilters.buckets.find((x) => x.key.toLowerCase() === field)?.monetaryValue
            .value || 0
        );
      });
    },
    parseSeries(data: any, field: string) {
      // returns an array with values of the field wrt the date buckets
      return data.aggregations.dateFilters.buckets.map((dateBucket: any) => {
        return (
          dateBucket.statusFilters.buckets.find((x) => x.key.toLowerCase() === field)?.docCount || 0
        );
      });
    },
    parsePrevious(data: any, field: string) {
      // returns the count of docs wrt to the field passed on the previousRangeData
      return (
        data.aggregations.statusFilters.buckets.find(
          (statusBucket: any) => statusBucket.key.toLowerCase() === field
        )?.docCount || 0
      );
    },
  },
};
</script>
<style scoped>
.color-blue {
  color: #2970ff;
}

.color-green {
  color: #12b76a;
}

.color-orange {
  color: #f79009;
}

.color-purple {
  color: #875bf7;
}

.color-red {
  color: #f04438;
}

.label-value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 38px;
  white-space: nowrap;
}

.compare {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 24px;
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.total-label {
  min-width: 0px;
}

.select-card {
  background-color: #eff4ff;
  border: 2px solid #155eef;
}
</style>
