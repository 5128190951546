<template>
  <UICard class="rollup__charts-card h-full" :class="{ 'select-card': selection && selectMode }">
    <template #header>
      <div class="flex flex-row items-center justify-between">
        <div
          class="chart-draggble-handler flex items-center"
          :class="{ 'cursor-pointer': isDraggble }"
        >
          <Menu05Icon v-if="isDraggble" class="mr-2 h-4 w-4" />
          <UITextLgMedium>{{ chartTitle }}</UITextLgMedium>
        </div>
        <div>
          <UICheckbox
            v-show="selectMode"
            id="checkbox"
            v-model:checked="selection"
            @update:checked="insightSelect"
          ></UICheckbox>
        </div>
      </div>
    </template>

    <div class="flex">
      <div v-for="label in labels" :key="label.label" class="total-label mr-16">
        <UITextSmMedium class="truncate text-gray-500">{{ label.label }}</UITextSmMedium>
        <UITooltip :id="'loc-appt-' + label.label + '__stat_title'">
          <template #trigger>
            <UITextLgMedium class="label-value">{{
              $filters.compactNumbers(label.count || 0)
            }}</UITextLgMedium>
          </template>
          {{ $filters.reportNumbers(label.count || 0) }}
        </UITooltip>

        <div
          v-if="label.comparison"
          v-show="label.comparison"
          class="compare pl-1 pr-1"
          :class="label.comparison && label.comparison.classes"
        >
          <UITextMdMedium
            :id="'loc-' + label.label + '__stat_compare'"
            class="flex items-center p-1 font-semibold"
            style="font-weight: 500; font-size: 12px; line-height: 18px"
          >
            <component
              :is="label.comparison.icon"
              :id="'loc-' + label.label + '__stat_compare_icon'"
              class="h-3 w-3"
            />
            <span>{{ label.comparison.value }}%</span>
          </UITextMdMedium>
        </div>
      </div>
    </div>

    <div v-show="isLoading" class="flex h-full w-full items-center justify-center">
      <UISpin :show="true"></UISpin>
    </div>
    <div v-show="!isLoading">
      <v-chart class="chart" :option="option" autoresize />
    </div>
  </UICard>
</template>

<script lang="ts">
import {
  UICard,
  UITextLgMedium,
  UITextSmMedium,
  UISpin,
  UITooltip,
  UITextMdMedium,
  UICheckbox,
} from "@gohighlevel/ghl-ui";
import { ReportingService } from "@/services/ReportingService";
import { Menu05Icon } from "@gohighlevel/ghl-icons/24/outline";
import { useAppStore } from "@/store/app";
import moment from "moment";
import { sumBy } from "lodash";
import VChart, { THEME_KEY } from "vue-echarts";
import { getStatComparison } from "@/helpers/report_helper";
import { compactNumbers } from "@/helpers";
export default {
  components: {
    UITextMdMedium,
    VChart,
    UICard,
    UITextLgMedium,
    UITextSmMedium,
    UISpin,
    UITooltip,
    Menu05Icon,
    UICheckbox,
  },
  provide: {
    [THEME_KEY]: "shine",
  },
  props: {
    isDraggble: {
      type: Boolean,
    },
    type: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: true,
    },
    chartTitle: {
      type: String,
      required: true,
    },
    selectMode: {
      type: Boolean,
      required: true,
    },
    checkedInsights: {
      type: Array,
      required: true,
    },
  },
  emits: ["insightSelectEvent"],
  data() {
    return {
      isLoading: false,
      selection: false,
      labels: [] as {
        label: string;
        count: number;
        color?: string;
        index: number;
        comparison?: any;
      }[],
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          bottom: 10,
          data: [],
        },
        xAxis: [
          {
            type: "category",
            axisTick: { show: false, interval: 0 },
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [],
      },
    };
  },
  computed: {
    dateRange: () => {
      const start = new Date(this.$route.query.start).getTime();
      const end = new Date(this.$route.query.end).getTime();
      return [start, end];
    },
  },
  watch: {
    $route() {
      this.loadData();
    },
    checkedInsights() {
      this.fillCheckBox();
    },
  },
  mounted() {
    this.loadData();
    this.fillCheckBox();
  },
  methods: {
    async loadData() {
      const start = new Date(this.$route.query.start).getTime();
      const end = new Date(this.$route.query.end).getTime();
      if (!this.type || !start || !end) {
        return;
      }

      this.isLoading = true;
      const appStore = useAppStore();
      const { data } = await ReportingService.getAppointmentsRollupData({
        locationId: appStore.locationId || (this.$route.params.locationId as string),
        companyId: appStore.companyId,
        start: this.$route.query.start as string,
        end: this.$route.query.end as string,
      });

      const { data: previousRangeData } = await ReportingService.getAppointmentsRollupComparison({
        locationId: appStore.locationId || (this.$route.params.locationId as string),
        companyId: appStore.companyId,
        start: moment(start).format("YYYY-MM-DD"),
        end: moment(end).format("YYYY-MM-DD"),
        compare: true,
      });

      const booked = sumBy(data, "booked");
      const confirmed = sumBy(data, "confirmed");
      const showed = sumBy(data, "showed");
      const noshow = sumBy(data, "noshow");
      const cancelled = sumBy(data, "cancelled");
      this.labels = [];
      this.labels.push({
        label: this.$t("booked"),
        count: booked,
        color: "color-blue",
        index: 0,
        comparison: getStatComparison(booked, previousRangeData[0]?.booked),
      });
      this.labels.push({
        label: this.$t("confirmed"),
        count: confirmed,
        color: "color-green",
        index: 1,
        comparison: getStatComparison(confirmed, previousRangeData[0]?.confirmed),
      });
      this.labels.push({
        label: this.$t("showed"),
        count: showed,
        color: "color-orange",
        index: 2,
        comparison: getStatComparison(showed, previousRangeData[0]?.showed),
      });
      this.labels.push({
        label: this.$t("noShow"),
        count: noshow,
        color: "color-purple",
        index: 3,
        comparison: getStatComparison(noshow, previousRangeData[0]?.noshow, true),
      });
      this.labels.push({
        label: this.$t("cancelled"),
        count: cancelled,
        color: "color-red",
        index: 3,
        comparison: getStatComparison(cancelled, previousRangeData[0]?.cancelled, true),
      });

      this.option.xAxis[0].data = data.map((x) => x.name);
      this.option.series[0] = {
        name: this.$t("booked"),
        type: "bar",
        barWidth: 8,
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: "#2970FF",
          borderRadius: [10, 10, 0, 0],
        },
        data: data.map((x) => x.booked),
      };
      this.option.series[1] = {
        name: this.$t("confirmed"),
        type: "bar",
        barWidth: 8,
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: "#12B76A",
          borderRadius: [10, 10, 0, 0],
        },
        data: data.map((x) => x.confirmed),
      };
      this.option.series[2] = {
        name: this.$t("showed"),
        type: "bar",
        barWidth: 8,
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: "#F79009",
          borderRadius: [10, 10, 0, 0],
        },
        data: data.map((x) => x.showed),
      };
      this.option.series[3] = {
        name: this.$t("noShow"),
        type: "bar",
        barWidth: 8,
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: "#875BF7",
          borderRadius: [10, 10, 0, 0],
        },
        data: data.map((x) => x.noshow),
      };
      this.option.series[3] = {
        name: this.$t("cancelled"),
        type: "bar",
        barGap: "20%",
        barWidth: 8,
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: "#F04438",
          borderRadius: [10, 10, 0, 0],
        },
        data: data.map((x) => x.cancelled),
      };
      this.isLoading = false;
    },
    insightSelect(val) {
      this.$emit("insightSelectEvent", { chart: this.type, value: val });
    },
    fillCheckBox() {
      this.selection = this.checkedInsights.includes(this.type, 0);
    },
  },
};
</script>
<style scoped>
.color-blue {
  color: #2970ff;
}

.color-green {
  color: #12b76a;
}

.color-orange {
  color: #f79009;
}

.color-purple {
  color: #875bf7;
}

.color-red {
  color: #f04438;
}

.label-value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 38px;
}

.compare {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 24px;
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.total-label {
  min-width: 0px;
}

.select-card {
  background-color: #eff4ff;
  border: 2px solid #155eef;
}
</style>
