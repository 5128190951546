<script setup lang="ts">
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://vuejs.org/api/sfc-script-setup.html#script-setup
import { UIContentWrap } from "@gohighlevel/ghl-ui";
import Postmate from "postmate";
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useAppStore } from "./store/app";
import { setupPendo } from "./util/pendoConfig";
import * as Sentry from "@sentry/vue";
import { setSentryMeta } from "common/util/setupSentry";

const i18n = useI18n();

onMounted(() => {
  const appStore = useAppStore();

  if (process.env.NODE_ENV === "development") {
    Postmate.debug = true;
  }

  const handshake = new Postmate.Model({
    "refresh-token": (token: string) => appStore.refreshToken(token),
    "updated-authorization-token": (authorization: string) =>
      appStore.refreshAuthorizationToken(authorization),
    "abort-deletion": (data: object) => appStore.abortDeletion(data),
  });

  handshake.then(({ model }: any) => {
    appStore.initialize(model);
    setLocales(model.locale);
    appStore.setAppHandshake(handshake);
    setupPendo(model);
    setSentryMeta(Sentry, {
      userId: model.userId,
      companyId: model.companyId,
      locationId: model.locationId,
      email: model.userEmail,
      locale: "en-US",
    });
  });
});

function setLocales(lang: string) {
  const locale = getLocaleName(lang);
  localStorage.setItem("locales", locale);
  i18n.locale.value = locale;
}

function getLocaleName(lang: string) {
  let formattedLang: string;
  switch (lang) {
    case "es":
    case "nl":
    case "it":
    case "sv":
    case "da":
    case "fi":
      formattedLang = lang;
      break;
    case "fr-CA":
    case "pt-BR":
    case "en-US":
      formattedLang = lang.replace("-", "_");
      break;
    case "fr-FR":
    case "pt-PT":
      formattedLang = lang.split("-")[0];
      break;
    case "de":
      formattedLang = "de_DE";
      break;
    case "no":
      formattedLang = "nb";
      break;
    default:
      formattedLang = lang;
      break;
  }
  return formattedLang;
}
</script>

<template>
  <UIContentWrap class="bg-gray-25" :locale="($i18n.locale as string)">
    <router-view></router-view>
  </UIContentWrap>
</template>

<style scoped></style>
