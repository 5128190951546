import { requests } from ".";

export const InternalToolsService = {
  fetchLocationTransferRequests: (query: string) =>
    requests().get("/internal-tools/location-transfers?" + query),
  fetchEjectRequests: (query: string) =>
    requests().get("/internal-tools/location-transfers/eject?" + query),
  replyToTransferRequest: (payload: any) =>
    requests().put("/internal-tools/location-transfers", payload),
  replyToEjectRequest: (id: string, payload: any) =>
    requests().put(`/internal-tools/location-transfers/eject/${id}`, payload),
};
