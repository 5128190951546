export default {
  fr: {
    common: {
      thisWeek: "Cette semaine",
      lastWeek: "La semaine dernière",
      lastNumberOfDays: "Derniers {numberOfDays} jours",
      thisMonth: "Ce mois-ci",
    },
    subAccounts: "Sous-comptes",
    submitFeedback: "Soumettre un commentaire",
    createSubAccount: "Créer un sous-compte",
    viewScheduleReports: "Voir les rapports planifiés",
    searchSubAccount: "Recherche par sous-compte",
    columns: "Piliers",
    sortBy: "Trier par",
    selectColumns: "Sélectionner les colonnes",
    maxColumns: "Maximum 8 colonnes à la fois",
    applyColumns: "Appliquer les colonnes",
    activeUsers: "Utilisateurs actifs",
    appointments: "Rendez-vous",
    contacts: "Contact ",
    emails: "Les courriels",
    textMessages: "Messages textes",
    calls: "D'appels",
    submissions: "les soumissions",
    reviews: "Avis",
    sitesCreated: "Sites Créés",
    name: "Nom",
    formSubmissions: "Soumissions de formulaire",
    surveySubmissions: "Soumissions de sondages",
    facebookFormSubmissions: "Soumissions de formulaire sur Facebook",
    funnels: "Les entonnoirs",
    websites: "Sites web",
    forms: "Formulaires",
    surveys: "Enquêtes",
    highestFirst: "Le plus élevé en premier",
    lowestFirst: "Le plus bas en premier",
    orderBy: "Trier par",
    noMoreLocations: "Aucun emplacement supplémentaire trouvé",
    preparingReport: "Nous préparons votre rapport",
    preparingReportDesc: "Ne fermez ni ne rechargez cette fenêtre pendant l'exportation en cours",
    outOf: "hors de",
    subAccountsExported: "Comptes secondaires exportés",
    close: "Fermer",
    scheduledForDeletion: "Programmé pour la suppression",
    readyToSell: "Prêt(e) à vendre",
    closedWon: "Fermé (Gagné)",
    wpSaleSuccessful: "La vente de WordPress est un succès",
    wpNotSold: "WordPress pas encore vendu",
    yxtSaleSuccessful: "La vente de Yext est un succès",
    yxtNotSold: "Yext pas encore vendu",
    saasSubInactive: "L'abonnement SaaS est inactif",
    saasSubTrialing: "L'abonnement SaaS est en phase d'essai",
    saasSubActive: "L'abonnement SaaS est actif",
    emailRebillingActive: "La facturation par e-mail est active",
    emailRebillingInactive: "La facturation par courriel est inactive",
    twilioRebillingActive: "La facturation Twilio est active",
    twilioRebillingInactive: "La facturation Twilio est inactive",
    wpRebillingActive: "Le réabonnement sur WhatsApp est actif",
    wpRebillingInactive: "La facturation de WhatsApp est inactive",
    saasModeActivated: "Le mode SaaS est activé",
    paymentPending: "Le paiement est en attente",
    saasModeNotActivated: "Le mode SaaS n'est pas activé",
    managedByLCPhone: "Géré par LC Phone",
    migrationToLCPhone: "En cours de migration vers LC Phone",
    notManagedByLCPhone: "Non géré par LC Phone",
    managedByLCEmail: "Géré par LC Email",
    notMangedByLCEmail: "Non géré par LC Email",
    remainingWalletBalance: "Solde restant du portefeuille de cette SaaS Location",
    reports: "Rapports",
    switchToSubAccount: "Passer au sous-compte",
    manageClient: "Gérer les clients",
    subAccountDeleted: "Ce sous-compte sera supprimé le",
    subAccountDeletedInMinute: "Ce sous-compte sera supprimé dans une minute",
    changedMind: "Si tu as changé d'avis",
    clickHere: "Cliquez ici",
    subAccountPaused:
      "Cette sous-compte est en pause. Vos clients ne peuvent pas se connecter, mais l'agence peut le faire. Pour reprendre cet compte",
    subAccountRestricted: "Ce sous-compte est en pause en raison de l'approbation en cours",
    subAccountVerificationPending:
      "Ce sous-compte est en pause en attendant la vérification de l'e-mail et du numéro de téléphone par l'administrateur du sous-compte",
    subAccountDeclined:
      "Ce sous-compte est en pause en raison d'une demande d'approbation refusée. Vous pouvez encore reprendre ce sous-compte à partir de",
    here: "ici",
    reviewAndResume: "revoir et reprendre ce sous-compte",
    incomingCalls: "Appels entrants",
    outgoingCalls: "Appels sortants",
    totalTalktime: "Temps de communication total",
    incomingEmails: "Nouveaux emails entrants",
    outgoingEmails: "Courriels sortants",
    incomingSms: "Nouveaux messages (textes entrants)",
    outgoingSms: "Messages sortants de texte",
    contactsCreated: "Nombre de contacts créés",
    appointmentsBooked: "Nombre de rendez-vous pris",
    totalFormSubmissions: "Total des soumissions de formulaire",
    totalSurveySubmissions: "Nombre total de soumissions de sondage",
    totalFacebookFormSubmissions: "Total des soumissions de formulaire sur Facebook",
    incomingGMBMessages: "Messages entrants en GBP",
    outgoingGMBMessages: "Messages sortants en GBP",
    gmbMessages: "Messages GBP",
    fbMessages: "Messages Facebook",
    incomingFbMessages: "Nouveaux messages Facebook entrants",
    outgoingFbMessages: "Messages sortants sur Facebook",
    igMessages: "Messages Instagram",
    incomingIgMessages: "Nouveaux messages Instagram entrants",
    outgoingIgMessages: "Messages sortants sur Instagram",
    totalReviews: "Avis totaux",
    positiveReviews: "Avis positifs",
    negativeReviews: "Avis négatifs",
    back: "En arrière",
    selectDashboards: "Sélectionner des tableaux de bord",
    next: "Suivant",
    backToSubAccounts: "Retour aux sous-comptes",
    pauseSchedule: "Planning de pause",
    resumeSchedule: "Reprenez le programme",
    editSchedule: "Modifier l'horaire",
    addSchedule: "Ajouter un horaire",
    selectReportToAdd: "Sélectionnez les rapports à ajouter dans l'email",
    selectAll: "Tout sélectionner",
    pauseScheduleConfirm:
      "Êtes-vous sûr(e) de vouloir mettre en pause la planification du rapport ?",
    resumeScheduleConfirm: "Êtes-vous sûr(e) de vouloir reprendre le planning des rapports ?",
    tasks: "tâches",
    conversations: "Conversations",
    users: "Utilisateurs",
    dealOverview: "Vue d'ensemble de l'accord",
    confirmAction: "Confirmer l'action",
    yes: "Oui",
    no: "Non",
    reportDetails: "Détails du rapport",
    reportCreatedOn: "Rapport créé le",
    searchByEmail: "Recherche par email",
    lastActivity: "Dernière activité",
    noRecords: "Aucun enregistrement trouvé",
    scheduleReport: "Rapport de programme",
    schedule: "emploi du temps or programme",
    save: "Sauver",
    emailSubject: "Objet de l'email",
    frequency: "Fréquence",
    daily: "quotidien",
    weekly: "Hebdomadaire",
    monthly: "mensuel",
    nextScheduleDate: "Prochaine date prévue",
    startingOn: "À partir de",
    timeOfDay: "Heure de la journée",
    reportsToTimezone: "Le rapport sera envoyé dans le fuseau horaire du sous-compte",
    selectRecipients: "Sélectionner les destinataires",
    invalidEmail: "Vous avez saisi une adresse email invalide",
    testEmail: "Email de test",
    selectTestRecipients: "Sélectionner les destinataires du test",
    setupSubject: "Mettez le sujet au-dessus pour tester le rapport",
    sendTest: "Envoyer un test",
    scheduleSimilarSchedules: "Planifier un rapport similaire pour les autres sous-comptes",
    accountSummary: "Récapitulatif de compte",
    scheduleList: "Liste de planning",
    subAccountName: "Nom du sous-compte",
    nextRunDate: "Prochaine date de lancement",
    reportSectionsAdded: "Sections du rapport ajoutées",
    status: "Le statut",
    scheduleAction: "Plan d'action",
    cancel: "annuler",
    pleaseWait: "Veuillez patienter",
    confirm: "Confirmer",
    booked: "Réservé",
    confirmed: "confirmé",
    showed: "montré",
    noShow: "absence non annoncée",
    cancelled: "annulé",
    unread: "Non lu",
    engagement: "L'engagement",
    reaction: "Réaction",
    deliverySummary: "Récapitulatif de livraison",
    successfulDelivery: "Livraison réussie",
    opened: "Ouvert",
    clicked: "Cliqué",
    replied: "A répondu",
    unsubscribed: "Désabonné",
    complained: "se plaindre",
    failed: "échoué",
    open: "ouvrir",
    won: "gagné",
    abandoned: "abandonné",
    lost: "Perdu",
    sent: "odeur",
    delivered: "Livré",
    faceBookLeadForm: "Formulaire de demande de prospects sur Facebook",
    overdue: "En retard",
    reviewsReceived: "Avis reçus",
    formBuilders: "Constructeurs de formulaires",
    switchToSaaS: "Passer à SaaS",
    numberOfContacts: "Nombre de contacts",
    numberOfAppointments: "Nombre de rendez-vous",
    outreach: "sensibilisation",
    notSubAccountUser: "Non utilisateur d'un compte secondaire",
    searchSubAccName: "Recherche par nom de sous-compte",
    recipients: "Destinataires",
    scheduleCreated: "Planning de rapport d'e-mail créé pour",
    scheduleUpdated: "Rapport d'e-mail programmé mis à jour pour",
    scheduleNotCreated: "L'horaire du rapport par email n'a pas pu être créé pour",
    scheduleNotUpdated:
      "La planification du rapport de messagerie n'a pas pu être mise à jour pour",
    total: "Total (prononcé to-tal)",
    inbound: "Entrant",
    outbound: "Sortant",
    totalEmails: "Total des courriels",
    inboundEmails: "Courrier entrant",
    outboundEmails: "E-mails sortants",
    totalTextMessages: "Total messages texte",
    inboundTextMessages: "Messages texte entrants",
    outboundTextMessages: "Messages sortants",
    totalCalls: "Total d'appels",
    inboundCalls: "Appels entrants",
    outboundCalls: "Appels sortants",
    faceBookLeadFormSubmissions: "Soumissions de formulaires de leads sur Facebook",
    inboundGMBMessages: "Messages entrants en GBP",
    outboundGMBMessages: "Messages sortants en GBP",
    totalIgMessages: "Total des messages Instagram",
    inboundIgMessages: "Messages entrants Instagram",
    outboundIgMessages: "Messages sortants Instagram",
    totalfbMessages: "Messages Facebook totaux",
    inboundfbMessages: "Messages entrants sur Facebook",
    outboundfbMessages: "Messages Facebook sortants",
    sitesCreatedFunnels: "Sites Créés - Funnels",
    sitesCreatedWebsites: "Sites créés - Sites web",
    sitesCreatedForms: "Sites créés - Formulaires",
    sitesCreatedSurveys: "Sites créés - Enquêtes",
    googleReviews: "Avis Google",
    facebookReviews: "Avis sur Facebook",
    inboundCallDuration: "Durée des appels entrants (en heures)",
    outboundCallDuration: "Durée des appels sortants (en heures)",
    pleaseSelect: "S'il vous plaît sélectionnez",
    subAccountIsDeleted: "Ce sous-compte a été supprimé",
    subAccountIsPaused: "Ce sous-compte a été mis en pause",
    success: "Le succès",
    error: "Erreur",
    testEmailSent: "Email de test envoyé",
    testEmailNotSent: "Le courriel de test n'a pas pu être envoyé",
    typeToSearch: "Tapez pour rechercher",
    reviewRequest: "Demandes de révision",
    pendingTransferDescription:
      "D'autres agences ont demandé à transférer des sous-comptes vers vous",
    pendingTransferHeading: "Vous avez des demandes de transfert de sous-comptes en attente",
    subAccountTransfer: "Transferts entre sous-comptes",
    manageSubAccTransfer: "Gérez vos demandes de transfert de sous-compte",
    subAccRequest: "Demandes de sous-comptes {tabName}",
    receive: "Recevoir",
    send: "Envoyer",
    release: "Libération",
    requests: "{count} demandes",
    subAccLimit: "Limite d'utilisation des sous-comptes atteinte !",
    subAccPlanLimit: "Vous avez utilisé les 3 sous-comptes autorisés par votre plan actuel",
    upgradeTime: "Il est temps pour une mise à niveau !",
    upgradeHigher:
      "Veuillez passer à un abonnement supérieur pour accepter le transfert de sous-compte",
    browse: "Parcourir les plans",
    pendingTransferTitle: "Une demande de transfert pour ce sous-compte est déjà en attente",
    unableTransfer: "Impossible de transférer le sous-compte vers cette agence",
    hippaErrorDescription:
      "Le sous-compte que vous essayez de transférer est activé HIPAA et ne peut pas être transféré à une agence non-HIPAA",
    pendingTransferErrorDescription:
      "Veuillez annuler la demande de transfert existante pour ce sous-compte avant de procéder à en créer une nouvelle",
    receivingAgency: "Agence réceptrice",
    releasingAgency: "Agence de diffusion",
    noOfContacts: "Nombre de contacts",
    requestedDate: "Date demandée",
    action: "Action",
    cancelTransfer: "Annuler le transfert",
    decline: "Déclin",
    cancelTransferRequest: "Annuler la demande de transfert de sous-compte ?",
    cancelRequestDescription:
      "L'agence réceptrice ne verra plus cette demande de transfert, et le sous-compte {name} ne sera pas transféré",
    declineRequest: "Refuser la demande de transfert du compte secondaire ?",
    declineRequestDescription:
      "La demande sera refusée et le sous-compte {name} ne sera pas transféré à votre agence",
    approveTransfer: "Approuvez-vous le transfert de sous-compte vers votre agence ?",
    approveTransferDescription: "Le compte secondaire de {name} sera transféré à votre agence",
    transferNow: "Transférer maintenant",
    cancelNow: "Annulez maintenant",
    transferErrorDetails:
      "Le sous-compte que vous essayez de transférer ne peut pas être transféré",
    cancelRequest: "Annuler la demande",
    received: "Reçu",
    approve: "Approuver",
    goBack: "Retourne",
    done: "Fini",
    subAccountTransferred: "Le sous-compte a été transféré avec succès !",
    subAccountTransferDescription:
      "Veuillez noter que même si le sous-compte a été transféré, certains éléments tels que les utilisateurs, les numéros de téléphone, les comptes Mailgun, les référencements Yext, etc. peuvent mettre encore de 10 à 15 minutes pour être mis à jour dans la destination",
    activeSubscriptionsAlert:
      "L'emplacement a des abonnements actifs. Veuillez annuler tous les abonnements avant de transférer l'emplacement. Liste des abonnements actifs : {subscriptions}",
    emailEngagement: "Engagement par e-mail",
    emailReaction: "Réaction par e-mail",
    emailDeliverySummary: "Résumé de livraison par e-mail",
    facebook: "Facebook",
    sms: "SMS",
    instagram: "Instagram",
    accountSummarySubAccount: "Account Summary - {subAccountName}",
    test: "Test",
    scheduleExists: "Schedule Exists",
    aToZ: "A - Z",
    zToA: "Z - A",
    onlyAdminUsersCanAddNewSubAccounts: "Only Admin users can add new sub-account's.",
    locationName: "Location Name",
    subAccountDeletedIfChangedMind:
      "This Sub-Account will be deleted on {time}, if you've changed your mind",
    eject: "Éjecter",
    unableEjectCancel: "Demande d'expulsion de sous-compte impossible à annuler",
    EjectErrorDetails:
      "La demande d'éjection de sous-compte que vous essayez d'annuler ne peut pas être annulée",
    cancelEjectRequest: "Annuler la demande de retrait du sous-compte ?",
    cancelEjectRequestDescription:
      "L'utilisateur sélectionné ne pourra plus créer une nouvelle agence à partir du sous-compte {name}",
      transferCompleted:
        "La demande de transfert pour cet emplacement est déjà traitée. Veuillez rafraîchir",
      unauthorizedTransfer:
        "Vous n'êtes pas autorisé à effectuer cette action. Seuls les propriétaires d'agence peuvent effectuer cette action",
  },
};
