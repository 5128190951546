import { ref } from "vue";

export function useCopy() {
  const copiedText = ref<string>("");

  function copyToClipboard(text: string) {
    const textElem = document.createElement("textarea");
    textElem.innerHTML = text;
    document.body.appendChild(textElem);
    textElem.select();
    document.execCommand("copy");
    navigator.clipboard.writeText(textElem.value);
    copiedText.value = text;
    document.body.removeChild(textElem);
    setInterval(() => (copiedText.value = ""), 2000);
  }

  return { copiedText, copyToClipboard };
}
