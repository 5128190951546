export default {
  nb: {
    common: {
      thisWeek: "Denne uka",
      lastWeek: "Forrige uke",
      lastNumberOfDays: "Siste {numberOfDays} dager",
      thisMonth: "Denne måneden",
    },
    subAccounts: "Underkontoer",
    submitFeedback: "Send tilbakemelding",
    createSubAccount: "Opprettunderkonto",
    viewScheduleReports: "Vis planlagte rapporter",
    searchSubAccount: "Søk etter underkonto",
    columns: "Kolonner",
    sortBy: "Sorter etter",
    selectColumns: "Velg kolonner",
    maxColumns: "Maks 8 kolonner om gangen",
    applyColumns: "Anvend kolonner",
    activeUsers: "Act",
    appointments: "Avtaler",
    contacts: "Kontakter",
    emails: "E-post",
    textMessages: "Tekstmeldinger",
    calls: "Samtaler",
    submissions: "Innsendinger",
    reviews: "Anmeldelser",
    sitesCreated: "Nettsteder opprettet",
    name: "Navn",
    formSubmissions: "Skjemainnsendinger",
    surveySubmissions: "Innsendelse av undersøkelser",
    facebookFormSubmissions: "Facebook Skjemainnsendinger",
    funnels: "Trakter",
    websites: "Nettsteder",
    forms: "Skjemaer",
    surveys: "Undersøkelser",
    highestFirst: "Høyest først",
    lowestFirst: "Laveste først",
    orderBy: "Sorter etter",
    noMoreLocations: "Ingen flere steder funnet",
    preparingReport: "Vi forbereder rapporten din",
    preparingReportDesc: "Ikke lukk eller last inn vinduet mens eksporten pågår",
    outOf: "ut av",
    subAccountsExported: "Delkontoer eksportert",
    close: "nær",
    scheduledForDeletion: "Planlagt for sletting",
    readyToSell: "Klar til å selge",
    closedWon: "Lukket (Vunnet)",
    wpSaleSuccessful: "WordPress salg er vellykket",
    wpNotSold: "WordPress er ikke solgt ennå",
    yxtSaleSuccessful: "Yext-salget er vellykket",
    yxtNotSold: "Yext ikke solgt ennå",
    saasSubInactive: "SaaS-abonnementet er inaktivt",
    saasSubTrialing: "SaaS-abonnement prøver ut",
    saasSubActive: "Abonnementet for SaaS er aktivt",
    emailRebillingActive: "E-post fakturering er aktiv",
    emailRebillingInactive: "E-postfakturering er inaktiv",
    twilioRebillingActive: "Tilbakebetaling via Twilio er aktiv",
    twilioRebillingInactive: "Twilio rebilling er inaktiv",
    wpRebillingActive: "WhatsApp-omfakturering er aktiv",
    wpRebillingInactive: "WhatsApp rebilling er inaktiv",
    saasModeActivated: "SaaS-modus er aktivert",
    paymentPending: "Betaling er under behandling",
    saasModeNotActivated: "SaaS-modus er ikke aktivert",
    managedByLCPhone: "Administreres av LC Phone",
    migrationToLCPhone: "Under migrering til LC-telefonen",
    notManagedByLCPhone: "Ikke administrert av LC Phone",
    managedByLCEmail: "Administrert av LC E-post",
    notMangedByLCEmail: "Administreres ikke av LC E-post",
    remainingWalletBalance: "Gjenstående lommeboksaldo for denne SaaS-plasseringen",
    reports: "Rapporter",
    switchToSubAccount: "Bytt til underkonto",
    manageClient: "Administrere klient",
    subAccountDeleted: "Dette underkontoen vil bli slettet på",
    subAccountDeletedInMinute: "Dette underkontoen vil bli slettet om ett minutt",
    changedMind: "hvis du har ombestemt deg",
    clickHere: "Klikk her",
    subAccountPaused:
      "Denne underkontoen er satt på pause. Dine kunder kan ikke logge inn, men byrået kan. For å gjenoppta denne kontoen",
    subAccountRestricted:
      "Denne underkontoen er midlertidig stoppet på grunn av venter godkjenning",
    subAccountVerificationPending:
      "Dette underkontoen er satt på pause på grunn av manglende bekreftelse av e-post og telefon fra underkontoadministratoren",
    subAccountDeclined:
      "Dette underkontoen er satt på pause på grunn av avslått godkjenningsforespørsel. Du kan fortsatt gjenoppta denne underkontoen fra",
    here: "her",
    reviewAndResume: "å gjennomgå og gjenoppta denne delkontoen",
    incomingCalls: "Innkommende samtaler",
    outgoingCalls: "Utgående samtaler",
    totalTalktime: "Total taletid",
    incomingEmails: "Innkommende e-poster",
    outgoingEmails: "Utgående e-post",
    incomingSms: "Innkommende tekstmeldinger",
    outgoingSms: "Utgående tekstmeldinger",
    contactsCreated: "Antall kontakter opprettet",
    appointmentsBooked: "Antall avtaler bestilt",
    totalFormSubmissions: "Totalt antall innsendte skjemaer",
    totalSurveySubmissions: "Totalt antall innsendte undersøkelser",
    totalFacebookFormSubmissions: "Totalt antall skjema-innsendelser på Facebook",
    incomingGMBMessages: "Innkommende GBP-meldinger",
    outgoingGMBMessages: "Utgående GBP-meldinger",
    gmbMessages: "GBP meldinger",
    fbMessages: "Facebook meldinger",
    incomingFbMessages: "Innkommende Facebook-meldinger",
    outgoingFbMessages: "Utgående Facebook-meldinger",
    igMessages: "Instagram-meldinger",
    incomingIgMessages: "Innkommende Instagram-meldinger",
    outgoingIgMessages: "Utgående Instagram-meldinger",
    totalReviews: "Totalt antall anmeldelser",
    positiveReviews: "Positive vurderinger",
    negativeReviews: "Negative omtaler",
    back: "Tilbake",
    selectDashboards: "Velg instrumentpaneler",
    next: "Neste",
    backToSubAccounts: "Tilbake til underkontoer",
    pauseSchedule: "Pauseplan",
    resumeSchedule: "Gjenoppta timeplan",
    editSchedule: "Rediger timeplan",
    addSchedule: "Legg til timeplan",
    selectReportToAdd: "Velg rapporter å legge til i e-posten",
    selectAll: "Velg alle",
    pauseScheduleConfirm: "Er du sikker på at du vil pause rapportplanen?",
    resumeScheduleConfirm: "Er du sikker på at du ønsker å gjenoppta rapportplanen?",
    tasks: "Oppgaver",
    conversations: "Samtaler",
    users: "Brukere",
    dealOverview: "Deal-oversikt",
    confirmAction: "Bekreft handling",
    yes: "Ja",
    no: "Nei",
    reportDetails: "Rapportdetaljer",
    reportCreatedOn: "Rapport opprettet den",
    searchByEmail: "Søk etter e-post",
    lastActivity: "Siste aktivitet",
    noRecords: "Ingen poster funnet",
    scheduleReport: "Planlegg rapport",
    schedule: "Timeplan",
    save: "Lagre",
    emailSubject: "E-postemne",
    frequency: "Frekvens",
    daily: "Daglig",
    weekly: "Ukentlig",
    monthly: "Månedlig",
    nextScheduleDate: "Neste planlagte dato",
    startingOn: "Starter på",
    timeOfDay: "Tidspunkt på dagen",
    reportsToTimezone: "Rapporten vil bli sendt i underkontoens tidssone",
    selectRecipients: "Velg mottakere",
    invalidEmail: "Du har skrevet inn en ugyldig e-postadresse",
    testEmail: "Test e-post",
    selectTestRecipients: "Velg testmottakere",
    setupSubject: "Sett opp emnet ovenfor for å teste rapporten",
    sendTest: "Send prøve",
    scheduleSimilarSchedules: "Planlegg en lignende rapport for andre underkontoer",
    accountSummary: "Kontooversikt",
    scheduleList: "Timeplanliste",
    subAccountName: "Underkonto Navn",
    nextRunDate: "Neste kjøredato",
    reportSectionsAdded: "Rapportseksjoner lagt til",
    status: "Status",
    scheduleAction: "Planlegg handling",
    cancel: "Avbestille",
    pleaseWait: "Vennligst vent",
    confirm: "bekreft",
    booked: "Booket",
    confirmed: "bekreftet",
    showed: "viste",
    noShow: "Ikke møtt",
    cancelled: "Avlyst",
    unread: "Ulest",
    engagement: "Forlovelse",
    reaction: "Reaksjon",
    deliverySummary: "Leveringsoversikt",
    successfulDelivery: "Vellykket levering",
    opened: "Åpnet",
    clicked: "Klikket",
    replied: "Svarte",
    unsubscribed: "Av-abonnert",
    complained: "Klaget",
    failed: "Mislyktes",
    open: "Åpen",
    won: "Vant",
    abandoned: "Forlatt",
    lost: "Mistet",
    sent: "Sent",
    delivered: "Leveres",
    faceBookLeadForm: "Facebook-ledeskjema",
    overdue: "Forsinket",
    reviewsReceived: "Mottatte anmeldelser",
    formBuilders: "Skjema byggere",
    switchToSaaS: "Bytt til SaaS",
    numberOfContacts: "Antall kontakter",
    numberOfAppointments: "Antall avtaler",
    outreach: "Utstråling",
    notSubAccountUser: "Ikke en underkonto bruker",
    searchSubAccName: "Søk etter datterselskapets navn",
    recipients: "Mottakere",
    scheduleCreated: "E-postrapportplan opprettet for",
    scheduleUpdated: "E-postsrapportplanen er oppdatert for",
    scheduleNotCreated: "E-postrapportplanen kunne ikke opprettes for",
    scheduleNotUpdated: "E-postrapportskjema kunne ikke oppdateres for",
    total: "Total",
    inbound: "Inngående",
    outbound: "Utgående",
    totalEmails: "Totalt antall e-poster",
    inboundEmails: "Innkommende e-post",
    outboundEmails: "Utgående e-postmeldinger",
    totalTextMessages: "Totalt antall tekstmeldinger",
    inboundTextMessages: "Innkommende tekstmeldinger",
    outboundTextMessages: "Utgående tekstmeldinger",
    totalCalls: "Totalt antall samtaler",
    inboundCalls: "Innkommende samtaler",
    outboundCalls: "Utadgående anrop",
    faceBookLeadFormSubmissions: "Facebook Lead Form Innsendelser",
    inboundGMBMessages: "Innkommende GBP-meldinger",
    outboundGMBMessages: "Utgående GBP-meldinger",
    totalIgMessages: "Totale Instagram-meldinger",
    inboundIgMessages: "Inngående Instagram-meldinger",
    outboundIgMessages: "Utadgående Instagram-meldinger",
    totalfbMessages: "Totale Facebook-meldinger",
    inboundfbMessages: "Innkommende Facebook-meldinger",
    outboundfbMessages: "Utgående Facebook-meldinger",
    sitesCreatedFunnels: "Nettsteder opprettet - Funnels",
    sitesCreatedWebsites: "Nettsider opprettet - Nettsider",
    sitesCreatedForms: "Sider opprettet - Skjemaer",
    sitesCreatedSurveys: "Opprettede nettsteder - undersøkelser",
    googleReviews: "Google-anmeldelser",
    facebookReviews: "Facebook anmeldelser",
    inboundCallDuration: "Innkommende samtalevarighet (timer)",
    outboundCallDuration: "Varighet for utgående samtaler (timer)",
    pleaseSelect: "Vennligst velg",
    subAccountIsDeleted: "Denne underkontoen er slettet",
    subAccountIsPaused: "Denne underkontoen har blitt satt på pause",
    success: "Suksess",
    error: "Feil",
    testEmailSent: "Test e-post sendt",
    testEmailNotSent: "Testeeposten kunne ikke sendes",
    typeToSearch: "Skriv inn søk",
    reviewRequest: "Gjennomgangsforespørsler",
    pendingTransferDescription: "Andre byråer har bedt om å overføre underkontoer til deg",
    pendingTransferHeading: "Du har ventende forespørsler om overføring av underkontoer",
    subAccountTransfer: "Delkontotransaksjoner",
    manageSubAccTransfer: "Administrer overføringsforespørslene for din underkonto",
    subAccRequest: "Underkonto {tabName} forespørsler",
    receive: "Motta",
    send: "Send",
    release: "Slipp ut",
    requests: "{count} Forespørsler",
    subAccLimit: "Sub-konto bruksbegrensningen er nådd!",
    subAccPlanLimit: "Du har brukt 3/3 underkontoer tillatt under din nåværende plan",
    upgradeTime: "Det er tid for en oppgradering!",
    upgradeHigher:
      "Vennligst oppgrader til et høyere abonnement for å godta overføringen av underkontoen",
    browse: "Bla gjennom planer",
    pendingTransferTitle:
      "En overføringsforespørsel for denne underkontoen er allerede under behandling",
    unableTransfer: "Kan ikke overføre underkonto til dette byrået",
    hippaErrorDescription:
      "Underkontoen du prøver å overføre er HIPAA-aktivert, og kan ikke overføres til en ikke-HIPAA byrå",
    pendingTransferErrorDescription:
      "Vennligst avbryt den allerede eksisterende overføringsforespørselen for denne delkontoen før du fortsetter med å opprette en ny",
    receivingAgency: "Mottakende organ",
    releasingAgency: "Utgivelsesbyrå",
    noOfContacts: "Antall kontakter",
    requestedDate: "Ønsket dato",
    action: "Handling",
    cancelTransfer: "Avbryt overføring",
    decline: "Nedgang",
    cancelTransferRequest: "Avbryt forespørselen om overføring av underkonto?",
    cancelRequestDescription:
      "Mottakerorganet vil ikke lenger se denne overføringsforespørselen, og underkontoen {navn} vil ikke bli overført",
    declineRequest: "Avslå underkonto-overføringsforespørsel?",
    declineRequestDescription:
      "Forespørselen vil bli avslått, og underkontoen {name} vil ikke bli overført til byrået ditt",
    approveTransfer: "Godkjenn overføring av underkonto til ditt byrå?",
    approveTransferDescription: "{name} underkonto vil bli overført til byrået ditt",
    transferNow: "Overfør nå",
    cancelNow: "Avbestill nå",
    transferErrorDetails: "Den subkontoen du prøver å overføre, kan ikke overføres",
    cancelRequest: "Avbryt forespørsel",
    received: "Mottatt",
    approve: "Godkjenne",
    goBack: "Gå tilbake",
    done: "Ferdig",
    subAccountTransferred: "Den underkontoen har blitt vellykket overført!",
    subAccountTransferDescription:
      "Vennligst merk at selv om underkontoen har blitt overført, kan noen eiendeler som brukere, telefonnumre, Mailgun-kontoer, Yext-listinger osv. fortsatt ta 10-15 minutter å reflektere i destinasjonen",
    activeSubscriptionsAlert:
      "Stedet har aktive abonnementer. Vennligst avbryt alle abonnementer før du overfører stedet. Liste over aktive abonnementer: {subscriptions}",
    emailEngagement: "Participación por correo electrónico",
    emailReaction: "Réaction par e-mail",
    emailDeliverySummary: "Resumo de entrega por e-mail",
    facebook: "Facebook",
    sms: "SMS",
    instagram: "Instagram",
    accountSummarySubAccount: "Account Summary - {subAccountName}",
    test: "Test",
    scheduleExists: "Schedule Exists",
    aToZ: "A - Z",
    zToA: "Z - A",
    onlyAdminUsersCanAddNewSubAccounts: "Only Admin users can add new sub-account's.",
    locationName: "Location Name",
    subAccountDeletedIfChangedMind:
      "This Sub-Account will be deleted on {time}, if you've changed your mind",
    eject: "Ejekt",
    unableEjectCancel: "Kan ikke kansellere forespørsel om å kaste underkonto",
    EjectErrorDetails:
      "Forespørselen om å kaste underkonto som du prøver å kansellere, kan ikke kanselleres.",
    cancelEjectRequest: "Kansellere forespørsel om å kaste underkonto?",
    cancelEjectRequestDescription:
      "Den valgte brukeren vil ikke lenger kunne opprette et nytt byrå ut av underkontoen {navn}.",
      transferCompleted:
        "Forespørsel om overføring for denne lokasjonen er allerede behandlet. Vennligst oppdater.",
      unauthorizedTransfer:
        "Du har ikke autorisasjon til å utføre denne handlingen. Kun byråeiere kan utføre denne handlingen.",
  },
};
