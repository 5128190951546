<template>
  <UICard class="rollup__charts-card h-full" :class="{ 'select-card': selection && selectMode }">
    <template #header>
      <div class="flex flex-row items-center justify-between">
        <div
          class="chart-draggble-handler flex items-center"
          :class="{ 'cursor-pointer': isDraggble }"
        >
          <Menu05Icon v-if="isDraggble" class="mr-2 h-4 w-4" />
          <UITextLgMedium>{{ chartTitle }}</UITextLgMedium>
        </div>
        <div>
          <UICheckbox
            v-show="selectMode"
            id="checkbox"
            v-model:checked="selection"
            @update:checked="insightSelect"
          ></UICheckbox>
        </div>
      </div>
    </template>

    <div class="flex">
      <UITabs :type="'line'">
        <UITabPane name="engagement" :tab="$t('engagement')">
          <div class="top-level flex">
            <div v-for="label in engagementLabels" :key="label.label" class="total-label mr-16">
              <UITextSmMedium class="text-gray-500">{{ label.label }}</UITextSmMedium>
              <UITooltip :id="'loc-emails-' + label.label + '__stat_title'">
                <template #trigger>
                  <UITextLgMedium class="label-value">{{
                    $filters.compactNumbers(label.count || 0)
                  }}</UITextLgMedium>
                </template>
                {{ $filters.reportNumbers(label.count || 0) }}
              </UITooltip>

              <div
                v-if="label.comparison"
                v-show="label.comparison"
                class="compare pl-1 pr-1"
                :class="label.comparison && label.comparison.classes"
              >
                <UITextMdMedium
                  :id="'loc-' + label.label + '__stat_compare'"
                  class="compare-label flex items-center p-1 font-semibold"
                >
                  <component
                    :is="label.comparison.icon"
                    :id="'loc-' + label.label + '__stat_compare_icon'"
                    class="h-3 w-3"
                  />
                  <span>{{ label.comparison.value }}%</span>
                </UITextMdMedium>
              </div>
            </div>
          </div>

          <div v-show="isLoading" class="flex h-full w-full items-center justify-center">
            <UISpin :show="true"></UISpin>
          </div>
          <div v-show="!isLoading">
            <v-chart class="chart" :option="engagementChartOpts" autoresize />
          </div>
        </UITabPane>
        <UITabPane name="reaction" :tab="$t('reaction')">
          <div class="top-level flex">
            <div v-for="label in reactionLabels" :key="label.label" class="total-label mr-16">
              <UITextSmMedium class="text-gray-500">{{ label.label }}</UITextSmMedium>
              <UITooltip :id="'loc-emails-' + label.label + '__stat_title'">
                <template #trigger>
                  <UITextLgMedium class="label-value">{{
                    $filters.compactNumbers(label.count || 0)
                  }}</UITextLgMedium>
                </template>
                {{ $filters.reportNumbers(label.count || 0) }}
              </UITooltip>

              <div
                v-if="label.comparison"
                v-show="label.comparison"
                class="compare pl-1 pr-1"
                :class="label.comparison && label.comparison.classes"
              >
                <UITextMdMedium
                  :id="'loc-' + label.label + '__stat_compare'"
                  class="compare-label flex items-center p-1 font-semibold"
                >
                  <component
                    :is="label.comparison.icon"
                    :id="'loc-' + label.label + '__stat_compare_icon'"
                    class="h-3 w-3"
                  />
                  <span>{{ label.comparison.value }}%</span>
                </UITextMdMedium>
              </div>
            </div>
          </div>
          <div v-show="isLoading" class="flex h-full w-full items-center justify-center">
            <UISpin :show="true"></UISpin>
          </div>
          <div v-show="!isLoading">
            <v-chart class="chart" :option="reactionChartOpts" autoresize />
          </div>
        </UITabPane>
        <UITabPane name="delivery-summary" :tab="$t('deliverySummary')">
          <div class="top-level flex">
            <div
              v-for="label in deliverySummaryLabels"
              :key="label.label"
              class="total-label mr-16"
            >
              <UITextSmMedium class="text-gray-500">{{ label.label }}</UITextSmMedium>
              <UITooltip :id="'loc-emails-' + label.label + '__stat_title'">
                <template #trigger>
                  <UITextLgMedium class="label-value">{{
                    $filters.compactNumbers(label.count || 0)
                  }}</UITextLgMedium>
                </template>
                {{ $filters.reportNumbers(label.count || 0) }}
              </UITooltip>

              <div
                v-if="label.comparison"
                v-show="label.comparison"
                class="compare pl-1 pr-1"
                :class="label.comparison && label.comparison.classes"
              >
                <UITextMdMedium
                  :id="'loc-' + label.label + '__stat_compare'"
                  class="compare-label flex items-center p-1 font-semibold"
                >
                  <component
                    :is="label.comparison.icon"
                    :id="'loc-' + label.label + '__stat_compare_icon'"
                    class="h-3 w-3"
                  />
                  <span>{{ label.comparison.value }}%</span>
                </UITextMdMedium>
              </div>
            </div>
          </div>
          <div v-show="isLoading" class="flex h-full w-full items-center justify-center">
            <UISpin :show="true"></UISpin>
          </div>
          <div v-show="!isLoading">
            <v-chart class="chart" :option="deliveryChartOpts" autoresize />
          </div>
        </UITabPane>
      </UITabs>
    </div>
  </UICard>
</template>

<script lang="ts">
import {
  UICard,
  UITextLgMedium,
  UITextSmMedium,
  UISpin,
  UITooltip,
  UITabs,
  UITabPane,
  UITextMdMedium,
  UICheckbox,
} from "@gohighlevel/ghl-ui";
import { ReportingService } from "@/services/ReportingService";
import { Menu05Icon } from "@gohighlevel/ghl-icons/24/outline";
import { useAppStore } from "@/store/app";
import moment from "moment";
import { clone, sumBy } from "lodash";
import VChart, { THEME_KEY } from "vue-echarts";
import { getStatComparison } from "@/helpers/report_helper";
import { emailChartOptions } from "@/helpers";
const defaultZeroStats = {
  outbound: 0,
  delivered: 0,
  opened: 0,
  clicked: 0,
  replied: 0,
  unsubscribed: 0,
  complained: 0,
  failed: 0,
  temporaryFail: 0,
  permanentFail: 0,
};

export default {
  components: {
    UITextSmMedium,
    UITooltip,
    UITextMdMedium,
    UISpin,
    VChart,
    UICard,
    UITextLgMedium,
    UITabPane,
    UITabs,
    Menu05Icon,
    UICheckbox,
  },
  provide: {
    [THEME_KEY]: "shine",
  },
  props: {
    isDraggble: {
      type: Boolean,
    },
    type: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: true,
    },
    chartTitle: {
      type: String,
      required: true,
    },
    selectMode: {
      type: Boolean,
      required: true,
    },
    checkedInsights: {
      type: Array,
      required: true,
    },
  },
  emits: ["insightSelectEvent"],
  data() {
    return {
      isLoading: false,
      selection: false,
      apiResponse: [],
      series: {
        engagement: [],
        reaction: [],
        deliverySummary: [],
      },
      engagementChartOpts: emailChartOptions(),
      reactionChartOpts: emailChartOptions(),
      deliveryChartOpts: emailChartOptions(),
      engagementLabels: [],
      reactionLabels: [],
      deliverySummaryLabels: [],
      previousRangeData: clone(defaultZeroStats),
      totals: clone(defaultZeroStats),
      columns: [
        {
          type: "engagement",
          label: "Engagement",
          count: 0,
          data: [],
          color: "text-blue-500",
          borderColor: "border-blue-500",
        },
        {
          type: "reaction",
          label: "Reaction",
          color: "text-green-500",
          count: 0,
          borderColor: "border-green-500",
          data: [],
        },
        {
          type: "delivery_summary",
          label: "Delivery Summary",
          color: "text-orange-500",
          borderColor: "border-orange-500",
          count: 0,
          data: [],
        },
      ],
      selectedMessageType: "engagement",
    };
  },
  computed: {
    selectedColumnData() {
      return this.columns.find((x) => x.type === this.selectedMessageType) || [];
    },
  },
  watch: {
    checkedInsights() {
      this.fillCheckBox();
    },
    $route() {
      this.loadData();
    },
    selectedColumnData() {
      this.setGraphDataForSelectedMessageType();
    },
  },
  mounted() {
    this.loadData();
    this.fillCheckBox();
  },
  methods: {
    async loadData() {
      const start = new Date(this.$route.query.start).getTime();
      const end = new Date(this.$route.query.end).getTime();
      if (!this.type || !start || !end) {
        return;
      }
      this.isLoading = true;
      const appStore = useAppStore();
      this.apiResponse = [];
      this.previousRangeData = clone(defaultZeroStats);
      this.totals = clone(defaultZeroStats);
      this.engagementLabels = [];
      this.reactionLabels = [];
      this.deliverySummaryLabels = [];

      const { data } = await ReportingService.getEmailRollupData({
        locationId: appStore.locationId || (this.$route.params.locationId as string),
        companyId: appStore.companyId,
        start: this.$route.query.start as string,
        end: this.$route.query.end as string,
      });

      const { data: previousRangeData } = await ReportingService.getEmailRollupComparison({
        locationId: appStore.locationId || (this.$route.params.locationId as string),
        companyId: appStore.companyId,
        start: this.$route.query.start as string,
        end: this.$route.query.end as string,
        compare: true,
      });

      if (previousRangeData.length) {
        this.previousRangeData = previousRangeData[0];
      }

      this.apiResponse = data;
      data.map((obj: any) => {
        this.totals.outbound += obj.outbound;
        this.totals.delivered += obj.delivered;
        this.totals.opened += obj.opened;
        this.totals.clicked += obj.clicked;
        this.totals.replied += obj.replied;
        this.totals.unsubscribed += obj.unsubscribed;
        this.totals.complained += obj.complained;
        this.totals.failed += obj.permanentFail + obj.temporaryFail;
      });

      this.setupLabels();
      this.setChartSeries();
      this.isLoading = false;
    },

    setChartSeries() {
      const xAxisLabels = this.apiResponse.map((x) => x.name);
      this.engagementChartOpts.xAxis[0].data = xAxisLabels;
      this.reactionChartOpts.xAxis[0].data = xAxisLabels;
      this.deliveryChartOpts.xAxis[0].data = xAxisLabels;
      const purpleBarOpts = {
        type: "bar",
        barGap: "20%",
        barWidth: 8,
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: "#875BF7",
          borderRadius: [10, 10, 0, 0],
        },
      };
      const blueBarOpts = {
        type: "bar",
        barGap: "20%",
        barWidth: 8,
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: "#2970FF",
          borderRadius: [10, 10, 0, 0],
        },
      };
      const orangeBarOpts = {
        type: "bar",
        barGap: "20%",
        barWidth: 8,
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: "#F79009",
          borderRadius: [10, 10, 0, 0],
        },
      };
      const greenBarOpts = {
        type: "bar",
        barGap: "20%",
        barWidth: 8,
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: "#12B76A",
          borderRadius: [10, 10, 0, 0],
        },
      };
      // this.engagementChartOpts.series[0] = {
      //   name: "Sent",
      //   data: this.apiResponse.map((x) => x.outbound),
      //   ...purpleBarOpts,
      // };
      this.engagementChartOpts.series[0] = {
        name: this.$t("delivered"),
        data: this.apiResponse.map((x) => x.delivered),
        ...blueBarOpts,
      };
      this.engagementChartOpts.series[1] = {
        name: this.$t("opened"),
        data: this.apiResponse.map((x) => x.opened),
        ...orangeBarOpts,
      };
      this.engagementChartOpts.series[2] = {
        name: this.$t("clicked"),
        data: this.apiResponse.map((x) => x.clicked),
        ...greenBarOpts,
      };

      this.reactionChartOpts.series[0] = {
        name: this.$t("replied"),
        data: this.apiResponse.map((x) => x.replied),
        ...blueBarOpts,
      };

      this.reactionChartOpts.series[1] = {
        name: this.$t("unsubscribed"),
        data: this.apiResponse.map((x) => x.unsubscribed),
        ...orangeBarOpts,
      };

      this.reactionChartOpts.series[2] = {
        name: this.$t("complained"),
        data: this.apiResponse.map((x) => x.complained),
        ...greenBarOpts,
      };

      this.deliveryChartOpts.series[0] = {
        name: this.$t("failed"),
        type: "bar",
        barGap: "20%",
        barWidth: 8,
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: "#F04438",
          borderRadius: [10, 10, 0, 0],
        },
        data: this.apiResponse.map((x) => x.temporaryFail + x.permanentFail),
      };
    },

    setupLabels() {
      // this.engagementLabels.push({
      //   label: "Sent",
      //   count: this.totals.outbound,
      //   color: "text-purple-500",
      //   index: 0,
      //   comparison: getStatComparison(this.totals.outbound, this.previousRangeData?.outbound),
      // });
      this.engagementLabels.push({
        label: this.$t("successfulDelivery"),
        count: this.totals.delivered,
        color: "text-blue-500",
        index: 0,
        comparison: getStatComparison(this.totals.delivered, this.previousRangeData?.delivered),
      });
      this.engagementLabels.push({
        label: this.$t("opened"),
        count: this.totals.opened,
        color: "text-orange-500",
        index: 0,
        comparison: getStatComparison(this.totals.opened, this.previousRangeData?.opened),
      });
      this.engagementLabels.push({
        label: this.$t("clicked"),
        count: this.totals.clicked,
        color: "text-green-500",
        index: 0,
        comparison: getStatComparison(this.totals.clicked, this.previousRangeData?.clicked),
      });
      this.reactionLabels.push({
        label: this.$t("replied"),
        count: this.totals.replied,
        color: "text-blue-500",
        index: 0,
        comparison: getStatComparison(this.totals.replied, this.previousRangeData?.replied),
      });
      this.reactionLabels.push({
        label: this.$t("unsubscribed"),
        count: this.totals.unsubscribed,
        color: "text-orange-500",
        index: 0,
        comparison: getStatComparison(
          this.totals.unsubscribed,
          this.previousRangeData?.unsubscribed,
          true
        ),
      });
      this.reactionLabels.push({
        label: this.$t("complained"),
        count: this.totals.complained,
        color: "text-red-500",
        index: 0,
        comparison: getStatComparison(
          this.totals.complained,
          this.previousRangeData?.complained,
          true
        ),
      });

      this.deliverySummaryLabels.push({
        label: this.$t("failed"),
        count: this.totals.failed,
        color: "text-red-500",
        index: 0,
        comparison: getStatComparison(
          this.totals.failed,
          this.previousRangeData?.permanentFail + this.previousRangeData?.temporaryFail,
          true
        ),
      });
    },

    setGraphDataForSelectedMessageType() {
      if (!this.selectedColumnData) {
        return;
      }
      this.chartOptions.xAxis[0].data = this.apiResponse.map((x) => x.date);
      this.chartOptions.series[0].data = this.apiResponse?.data.map((x) => x.outbound);

      this.chartOptions.series[1] = {
        name: this.$t("inbound"),
        type: "bar",
        barGap: 0,
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: "#84ADFF",
        },
        data: this.selectedColumnData?.data.map((x) => x.inbound),
      };

      this.option.legend = {
        data: [this.$t("outbound"), this.$t("inbound")],
      };
    },
    insightSelect(val: boolean) {
      this.$emit("insightSelectEvent", { chart: "email_engagement", value: val });
      this.$emit("insightSelectEvent", { chart: "email_reactions", value: val });
      this.$emit("insightSelectEvent", { chart: "email_delivery", value: val });
    },
    fillCheckBox() {
      const emailTags = ["email_engagement", "email_reactions", "email_delivery"];
      this.selection = emailTags.every((tag) => this.checkedInsights.includes(tag, 0));
    },
  },
};
</script>
<style scoped>
.text-blue {
  color: #2970ff;
}
.top-level {
  display: flex;
  flex-flow: row wrap;
}

.section {
  display: flex;
  flex-flow: row nowrap;
  margin-right: 12px;
  margin-top: 12px;
  flex: 1;
  min-width: 0;
}

.section-child {
  display: flex;
  flex-flow: column nowrap;
  /* align-items: center; */
  flex: 1;
  min-width: 0;
}

.section-child-title {
  width: 150.67px;
  height: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
}

.section-child-metric {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 38px;
  margin-top: 10px;
}

.label-value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 38px;
}
.compare {
  display: inline-flex;
  border-radius: 16px;
}
.compare-label {
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  border-radius: 16px;
}
.rollup__charts-card .hl-card-content {
  height: 260px;
}
.rollup__charts-card .chart {
  height: 260px;
}
.total-label {
  min-width: 0px;
}

.select-card {
  background-color: #eff4ff;
  border: 2px solid #155eef;
}
</style>
