import { createI18n } from "vue-i18n";
import en from "./lan/en-US";
import es from "./lan/es";
import da from "./lan/da";
import de from "./lan/de";
import fi from "./lan/fi";
import frCa from "./lan/fr-CA";
import frFr from "./lan/fr-FR";
import it from "./lan/it";
import nl from "./lan/nl";
import no from "./lan/no";
import ptBr from "./lan/pt-BR";
import ptPt from "./lan/pt-PT";
import sv from "./lan/sv";

export const i18n = createI18n({
  locale: localStorage.getItem("locale") || "en-US",
  globalInjection: true,
  legacy: false,
  runtimeOnly: true,
  fallbackLocale: "en-US",
  messages: {
    ...en,
    ...es,
    ...da,
    ...de,
    ...fi,
    ...frCa,
    ...frFr,
    ...it,
    ...nl,
    ...no,
    ...ptBr,
    ...ptPt,
    ...sv,
  },
});

export function translate(key: string, vars?: any): string {
  return i18n.global.t(key, vars) as string;
}
