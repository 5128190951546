import { defineStore } from "pinia";
import { SaasService } from "@/services/SaasService";
export const useCompanyStore = defineStore("company", {
  state: () => ({
    companyId: "",
    companyData: {},
    walletBalance: {},
  }),
  actions: {
    async init(companyId: string) {
      try {
        this.companyId = companyId;
        const { data } = await SaasService.getLocationWalletBalance(this.companyId);
        this.walletBalance = data.balances;
      } catch (err: Error | any) {
        console.error(err);
      }
    },
    setCompanyData(data: any) {
      this.companyData = data;
    },
  },
});
