<template>
  <div>
    <div class="tbl-header flex p-4">
      <div>
        <UITextLgMedium id="report-details" class="text-lg text-gray-900">
          {{ $t("reportDetails") }}
        </UITextLgMedium>
      </div>
      <div>
        <UITextMdNormal id="report-created-date" class="text-md text-gray-500">
          ({{ $t("reportCreatedOn") }} {{ scheduleCreationTime }})
        </UITextMdNormal>
      </div>
      <div>
        <UIInput id="table-input" v-model="searchTerm" :placeholder="$t('searchByEmail')">
          <template #prefix>
            <SearchLgIcon class="h-4 w-4" />
          </template>
        </UIInput>
      </div>
    </div>

    <UITable
      v-if="tableData.length !== 0"
      id="schedule-table"
      :columns="tableHeading"
      :data="computedData"
      :page-count="tableConfigs.pages"
      :enable-text-search="false"
      :enable-filter="false"
      :loading="isLoading"
      :page="tableConfigs.page"
      @update:page="pageChange"
    >
    </UITable>
    <UICard v-else>
      <UITextMdMedium class="text-center"> {{ $t("noRecords") }}</UITextMdMedium>
    </UICard>
  </div>
</template>

<script>
import {
  UITextLgMedium,
  UITextMdNormal,
  UITable,
  UIInput,
  UIContentWrap,
  UICard,
  UITextMdMedium,
} from "@gohighlevel/ghl-ui";

import { SearchLgIcon } from "@gohighlevel/ghl-icons/24/outline";

import moment from "moment";

import { UserService } from "../../services/UserService";
import { ReportingService } from "../../services/ReportingService.ts";

export default {
  components: {
    UITextLgMedium,
    UITextMdNormal,
    UITable,
    UIInput,
    UICard,
    UIContentWrap,
    SearchLgIcon,
    UITextMdMedium,
    // UITag,
  },
  props: ["location", "scheduleBody"],
  data() {
    return {
      // tabs: ["Enrolled", "Delivered", "Opened", "Clicked"],
      // tabSelected: "Enrolled",
      isLoading: false,
      tableConfigs: {
        page: 1,
        limit: 10,
        pages: 1,
      },
      tableHeading: [
        { title: this.$t("name"), key: "name" },
        { title: this.$t("emails"), key: "email" },
        // { title: "Report Created on", key: "reportCreatedOn" },
        { title: this.$t("lastActivity"), key: "lastActivity" },
      ],
      tableData: [],
      computedData: [],
      users: [],
      searchTerm: "",
      debounceTimer: null,
      scheduleCreationTime: null,
    };
  },
  watch: {
    searchTerm(searchText) {
      // starts searching the text after 0.5 sec
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }

      this.debounceTimer = setTimeout(async () => {
        this.tableConfigs.page = 1;
        this.tableConfigs.limit = 10;
        await this.fetchData();
        clearTimeout(this.debounceTimer);
        this.debounceTimer = null;
      }, 1000);
    },
    scheduleBody(newVal) {
      if (newVal) {
        this.scheduleCreationTime = moment(newVal.data.createdAt).format("ll");
        this.fetchData();
      }
    },
  },
  async mounted() {
    await this.getUsers();
    this.scheduleCreationTime = moment(this.scheduleBody.data.createdAt).format("ll");
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      if (this.location && this.scheduleBody) {
        const paramsObject = {
          locationId: this.location.id,
          page: this.tableConfigs.page,
          limit: this.tableConfigs.limit,
          scheduleId: this.scheduleBody.data._id,
        };

        if (this.searchTerm.length > 0) {
          paramsObject.search = this.searchTerm;
        }
        const { data } = await ReportingService.getScheduleReports(paramsObject);
        this.tableConfigs.pages = Math.ceil(data.totalCount / this.tableConfigs.limit);
        this.populateTable(data.data);
      }
      this.isLoading = false;
    },
    async getUsers() {
      // http req to get users
      const { data } = await UserService.getUsersList({
        locationId: this.location.id,
        companyId: this.location.companyId,
        type: "account",
        role: "admin",
      });
      this.users = data.users;
    },
    populateTable(data) {
      this.tableData = [];
      for (const item of data) {
        this.tableData.push({
          name: this.findName(item.recipient),
          email: item.recipient,
          reportCreatedOn: moment(item.createdAt).format("ll"),
          lastActivity: moment(item.updatedAt).format("lll"),
        });
      }
      this.computedData = this.tableData;
    },
    findName(email) {
      const foundUser = this.users.find((user) => user.email === email);
      return foundUser
        ? `${foundUser.firstName} ${foundUser.lastName}`
        : this.$t("notSubAccountUser");
    },
    async pageChange(val) {
      this.tableConfigs.page = val;
      await this.fetchData();
    },
  },
};
</script>
<style scoped>
.tbl-header {
  height: 32px;
  border-radius: 8px 8px 0px 0px;
  background: var(--base-white);
  border: 1px solid #eaecf0;
  border-bottom: none;
  box-shadow: 0 1px 3px #1018281a, 0 1px 2px #1018280f;
  align-items: center;
}

.tbl-header div {
  margin-right: 5px;
  margin-left: 5px;
}
.tbl-header div:last-child {
  margin-left: auto;
}
#report-created-date {
  padding-top: 2px;
}

.table-container {
  border-top: none;
}
</style>
