<template>
  <div>
    <div class="flex gap-2">
      <div class="flex gap-2">
        <div class="my-auto text-lg font-semibold text-gray-900">
          {{
            $t('subAccRequest', {
              tabName: tabName === 'sent' ? $t('release') : $t('receive'),
            })
          }}
        </div>
        <div
          v-if="totalRequests > 0"
          class="my-auto rounded-2xl bg-gray-100 px-2 py-0.5 align-middle text-sm font-medium"
        >
          {{ $t('requests', { count: totalRequests }) }}
        </div>
      </div>

      <div class="ml-auto w-80">
        <UIInput
          :id="'search'"
          v-model="searchInput"
          :placeholder="$t('searchSubAccName')"
          @update:model-value="updateSearchInput"
        >
          <template #prefix
            ><SearchLgIcon class="h-5 w-5"></SearchLgIcon
          ></template>
        </UIInput>
      </div>
    </div>

    <UIContentWrap id="transfer-table">
      <UITable
        id="transfer-request-table"
        class="mt-16 flex content-center items-center justify-center text-center"
        :columns="table.tableHeading"
        :data="table.computedData"
        :striped="true"
        :enable-filter="false"
        :enable-text-search="false"
        :loading="table.isLoading"
      >
        <template #pagination />
      </UITable>
      <br />
    </UIContentWrap>
  </div>

  <UIModal v-model:show="actionModalDetails.show" :width="400">
    <template #header>
      <UIModalHeader
        id="modal-header"
        :type="actionModalDetails.modalType"
        :icon="actionModalDetails.icon"
        @close="actionModalDetails = defaultActionValues"
      >
      </UIModalHeader>
    </template>
    <div class="text-lg font-semibold">{{ actionModalDetails.heading }}</div>
    <div class="font-regular pt-2 text-sm text-gray-600">
      {{ actionModalDetails.description }}
    </div>
    <template #footer>
      <UIModalFooter
        id="modal-footer"
        :positive-text="actionModalDetails.positiveButtonText"
        :type="actionModalDetails.positiveButtonType"
        :negative-text="actionModalDetails.negativeButtonText"
        :loading="buttonDisable"
        @negative-click="actionModalDetails = defaultActionValues"
        @positive-click="transferRequestReply"
      >
      </UIModalFooter>
    </template>
  </UIModal>

  <UIModal v-model:show="errorDetails.show" :width="400">
    <template #header>
      <UIModalHeader
        id="error-modal-header"
        type="error"
        :title="errorDetails.title"
        :icon="SlashCircle01Icon"
        @close="errorDetails = defaultErrorValues"
      ></UIModalHeader>
    </template>
    <div>
      <UIAlert id="warning" type="warning" :closable="false">
        <template #title>{{ errorDetails.description }}</template>
      </UIAlert>
    </div>
    <template #footer>
      <UIModalFooter
        id="modal-footer"
        :positive-text="errorDetails.buttonText"
        @positive-click="errorDetails = defaultErrorValues"
      >
      </UIModalFooter>
    </template>
  </UIModal>

  <UIModal v-model:show="showUpsellModal" :width="400">
    <template #header>
      <UIModalHeader
        id="error-modal-header"
        type="warning"
        :title="$t('subAccLimit')"
        :description="$t('subAccPlanLimit')"
        :icon="Upload04Icon"
        @close="showUpsellModal = false"
      ></UIModalHeader>
    </template>
    <div
      class="mt-4 flex !h-32 gap-2 rounded-lg border border-solid border-gray-200"
    >
      <img
        src="https://storage.googleapis.com/preview-production-assets/saas/img/upgrade-upsell.svg"
      />
      <div class="!my-auto">
        <div class="text-base font-semibold text-gray-800">
          {{ $t('upgradeTime') }}
        </div>
        <div class="font-regular text-sm text-gray-600">
          {{ $t('upgradeHigher') }}
        </div>
      </div>
    </div>
    <template #footer>
      <UIButton
        id="browse-plans"
        type="primary"
        class="mt-3 w-full"
        @click.prevent="navigateToBilling"
        >{{ $t('browse') }}</UIButton
      >
    </template>
  </UIModal>

  <UIModal v-model:show="showSuccessModal" :width="400">
    <template #header>
      <UIModalHeader
        id="error-modal-header"
        type="success"
        :icon="CheckCircleIcon"
        @close="showSuccessModal = false"
      ></UIModalHeader>
    </template>
    <div class="text-lg font-semibold">{{ $t('subAccountTransferred') }}</div>
    <div class="font-regular pt-2 text-sm text-gray-600">
      {{ $t('subAccountTransferDescription') }}
    </div>
    <template #footer>
      <UIModalFooter
        id="modal-footer"
        :positive-text="$t('done')"
        :type="'primary'"
        :negative-text="actionModalDetails.negativeButtonText"
        @positive-click="showSuccessModal = false"
      />
    </template>
  </UIModal>
</template>

<script setup lang="ts">
import { router } from '@/router';
import { InternalToolsService } from '@/services/InternalToolsService';
import { useAppStore } from '@/store/app';
import {
  AlertCircleIcon,
  CheckCircleIcon,
  DotsVerticalIcon,
  SearchLgIcon,
  SlashCircle01Icon,
  Upload04Icon,
} from '@gohighlevel/ghl-icons/24/outline';
import {
  renderIcon,
  UIAlert,
  UIButton,
  UIContentWrap,
  UIDropdown,
  UIInput,
  UIModal,
  UIModalFooter,
  UIModalHeader,
  UITable,
  UITag,
} from '@gohighlevel/ghl-ui';
import { defineProps, h, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useTable } from '../../composables/useTable';
import { EjectStatus, TransferReply, TransferStatus } from './Transfer.const';
const { renderNameAndEmail, renderNameEmailAndLogo, renderDate, renderEject } =
  useTable();
const props = defineProps({ tabName: { type: String, required: true } });
const { t } = useI18n();
const appStore = useAppStore();
const buttonDisable = ref<boolean>(false);
const searchInput = ref<string>('');
const showSuccessModal = ref<boolean>(false);
// const selectedRow = ref<any>("");
const totalRequests = ref<number>(0);
const showUpsellModal = ref<boolean>(false);
const selectedRowDetails = ref<any>({
  id: '',
  action: '',
});
const transferErrors = (code: string, extraDetails?: string) => {
  const errors = {
    PENDING_TRANSFER_REQUEST: {
      code: 'PENDING_TRANSFER_REQUEST',
      heading: t('pendingTransferTitle'),
      message: t('pendingTransferDescription'),
    },
    SUB_ACCOUNT_HIPAA_ENABLED: {
      code: 'SUB_ACCOUNT_HIPAA_ENABLED',
      heading: t('unableTransfer'),
      message: t('hippaErrorDescription'),
    },
    SUB_ACCOUNT_LIMIT_EXCEEDED: {
      code: 'SUB_ACCOUNT_LIMIT_EXCEEDED',
      heading: t('unableTransfer'),
      message: t('pendingTransferDescription'),
    },
    ACTIVE_SUBSCRIPTIONS: {
      code: 'ACTIVE_SUBSCRIPTIONS',
      heading: t('unableTransfer'),
      message: t('activeSubscriptionsAlert', { subscriptions: extraDetails }),
    },
    TRANSFER_REQUEST_ALREADY_COMPLETED: {
      code: 'TRANSFER_REQUEST_ALREADY_COMPLETED',
      heading: t('unableTransfer'),
      message: t('transferCompleted'),
    },
  };
  return errors[code];
};

const defaultErrorValues = {
  showModal: false,
  title: '',
  description: '',
  buttonText: t('goBack'),
};
const errorDetails = ref<any>(defaultErrorValues);

const defaultActionValues = {
  show: false,
  type: '', //transfer or eject
  icon: SlashCircle01Icon,
  heading: '',
  description: '',
  negativeButtonText: '',
  positiveButtonText: '',
  modalType: '',
  positiveButtonType: '',
};
const actionModalDetails = ref<any>(defaultActionValues);

const table = ref<any>({
  isLoading: false,
  tableHeading: [
    {
      title: t('subAccountName'),
      key: 'subAccountDetails',
      render(row) {
        return renderNameAndEmail(row.subAccountDetails);
      },
    },
    {
      title: t('receivingAgency'),
      key: 'receivingCompanyDetails',
      render(row) {
        return props.tabName === 'eject'
          ? renderEject()
          : renderNameEmailAndLogo(row.receivingCompanyDetails);
      },
    },

    {
      title: t('requestedDate'),
      key: 'createdAt',
      render(row) {
        return renderDate(row.createdAt);
      },
    },
  ],
  data: [],
  computedData: [],
  search: { text: '', timer: null },
});

onMounted(async () => {
  await fetchTransferRequestData();
  if (props.tabName === 'received') {
    table.value.tableHeading.splice(2, 0, {
      title: t('noOfContacts'),
      key: 'contactsCount',
    });
    // { title: t("noOfContacts"), key: "contactsCount" },

    table.value.tableHeading.push({
      title: t('action'),
      key: 'action',
      render(row) {
        if (row.status === TransferStatus.PENDING) {
          return h('div', { class: 'flex items-center gap-2' }, [
            h(
              UIButton,
              {
                id: 'id-testing',
                size: 'small',
                disabled: row.status === 'pending' ? false : true,
                type: 'error',
                onClick: function () {
                  selectedRowDetails.value = {
                    action: TransferReply.REJECT,
                    id: row._id,
                  };
                  actionModalDetails.value = {
                    show: true,
                    type: 'transfer',
                    icon: AlertCircleIcon,
                    heading: t('declineRequest'),
                    description: t('declineRequestDescription', {
                      name: row.subAccountDetails.name,
                    }),
                    negativeButtonText: t('back'),
                    positiveButtonText: t('cancelTransfer'),
                    positiveButtonType: 'error',
                    modalType: 'error',
                  };
                },
              },
              {
                default: () => [
                  h('span', { class: 'hl-text-sm-semibold ' }, t('decline')),
                ],
              }
            ),
            h(
              UIButton,
              {
                id: 'accept-transfer',
                type: 'primary',
                size: 'small',
                disabled: row.status === 'pending' ? false : true,
                onClick: function () {
                  selectedRowDetails.value = {
                    action: TransferReply.ACCEPT,
                    id: row._id,
                  };
                  actionModalDetails.value = {
                    show: true,
                    type: 'transfer',
                    icon: CheckCircleIcon,
                    heading: t('approveTransfer'),
                    description: t('approveTransferDescription', {
                      name: row.subAccountDetails.name,
                    }),
                    negativeButtonText: t('back'),
                    positiveButtonText: t('transferNow'),
                    positiveButtonType: 'primary',
                    modalType: 'success',
                  };
                },
              },
              {
                default: () => [
                  h('span', { class: 'hl-text-sm-semibold' }, t('approve')),
                ],
              }
            ),
          ]);
        }
        let type = 'default';
        switch (row.status) {
          case TransferStatus.COMPLETED:
            type = 'success';
            break;
          case TransferStatus.CANCELED:
            type = 'warning';
            break;
          case TransferStatus.REJECTED:
            type = 'error';
            break;
          default:
            type = 'default';
        }
        return h(
          UITag,
          { id: 'request-status', type: type, round: true, bordered: false },
          {
            default: () =>
              row.status.charAt(0).toUpperCase() + row.status.slice(1),
          }
        );
      },
    });

    table.value.tableHeading[1] = {
      title: t('releasingAgency'),
      key: 'releasingCompanyDetails',
      render(row) {
        return renderNameEmailAndLogo(row.releasingCompanyDetails);
      },
    };
  }

  if (props.tabName === 'sent') {
    table.value.tableHeading.splice(2, 0, {
      title: t('noOfContacts'),
      key: 'contactsCount',
    });
    table.value.tableHeading.push({
      title: t('status'),
      key: 'status',
      render(row) {
        let type = 'default';
        switch (row.status) {
          case TransferStatus.COMPLETED:
            type = 'success';
            break;
          case TransferStatus.CANCELED:
            type = 'warning';
            break;
          case TransferStatus.REJECTED:
            type = 'error';
            break;
          default:
            type = 'default';
        }
        return h(
          UITag,
          { id: 'request-status', type: type, round: true, bordered: false },
          {
            default: () =>
              row.status.charAt(0).toUpperCase() + row.status.slice(1),
          }
        );
      },
    });
    table.value.tableHeading.push({
      title: '',
      key: 'action-buttons',
      render(row) {
        return h(
          UIDropdown,
          {
            options: [
              {
                label: t('cancelRequest'),
                key: TransferReply.CANCEL,
                disabled: row.status === TransferStatus.PENDING ? false : true,
              },
            ],
            id: 'valueDropdown',
            onSelect: val => {
              selectedRowDetails.value = {
                action: val,
                id: row._id,
              };
              actionModalDetails.value = {
                show: true,
                type: 'transfer',
                icon: AlertCircleIcon,
                heading: t('cancelTransferRequest'),
                description: t('cancelRequestDescription', {
                  name: row.subAccountDetails.name,
                }),
                negativeButtonText: t('back'),
                positiveButtonText: t('cancelNow'),
                positiveButtonType: 'error',
                modalType: 'error',
              };
            },
          },
          [
            h(renderIcon(DotsVerticalIcon), {
              class: '!outline-none cursor-pointer',
              style: 'outline:none;',
            }),
          ]
        );
      },
    });
  }

  if (props.tabName === 'eject') {
    table.value.tableHeading.push({
      title: t('status'),
      key: 'status',
      render(row) {
        let type = 'default';
        switch (row.status) {
          case EjectStatus.COMPLETED:
            type = 'success';
            break;
          case EjectStatus.PAYMENT_PENDING:
            type = 'info';
            break;
          case EjectStatus.CANCELED:
            type = 'warning';
            break;
          default:
            type = 'default';
        }
        return h(
          UITag,
          { id: 'request-status', type: type, round: true, bordered: false },
          {
            default: () =>
              row.status
                .split('_')
                .map(status => status.charAt(0).toUpperCase() + status.slice(1))
                .join(' '),
          }
        );
      },
    });
    table.value.tableHeading.push({
      title: '',
      key: 'action-buttons',
      render(row) {
        return h(
          UIDropdown,
          {
            options: [
              {
                label: t('cancelRequest'),
                key: 'cancel',
                disabled: row.status === EjectStatus.PENDING ? false : true,
              },
            ],
            id: 'valueDropdown',
            onSelect: val => {
              selectedRowDetails.value = {
                action: val,
                id: row._id,
              };
              actionModalDetails.value = {
                show: true,
                type: 'eject',
                icon: AlertCircleIcon,
                heading: t('cancelEjectRequest'),
                description: t('cancelEjectRequestDescription', {
                  name: row.subAccountDetails.name,
                }),
                negativeButtonText: t('back'),
                positiveButtonText: t('cancelNow'),
                positiveButtonType: 'error',
                modalType: 'error',
              };
            },
          },
          [
            h(renderIcon(DotsVerticalIcon), {
              class: '!outline-none cursor-pointer',
              style: 'outline:none;',
            }),
          ]
        );
      },
    });
  }
});

function updateSearchInput() {
  table.value.computedData = table.value.data.filter(row => {
    if (
      row.subAccountDetails.name
        .toLowerCase()
        .includes(searchInput.value.toLowerCase())
    ) {
      return row;
    }
  });
}

function navigateToBilling() {
  //settings/billing
  const path = `/settings/billing`;
  if (appStore.appHandshake) {
    appStore.appHandshake.then(parent => {
      parent.emit('route-change', {
        path,
      });
    });
  }
  router.push(path);
}

async function transferRequestReply() {
  try {
    buttonDisable.value = true;

    if (!appStore.user?.isAgencyOwner) {
      throw {
        errorCode: 'NOT_AUTHORIZED',
        message: t('unauthorizedTransfer'),
      };
    }

    //TRANSFER_REQUEST_ALREADY_COMPLETED

    if (!appStore?.companyId) {
      return setTimeout(async () => transferRequestReply(), 1000);
    }
    const payload = {
      companyId: appStore?.companyId,
      requestId: selectedRowDetails.value.id,
      reply: selectedRowDetails.value.action,
    };
    const ejectPayload = {
      companyId: appStore?.companyId,
      reply: selectedRowDetails.value.action,
    };

    actionModalDetails.value.type === 'eject'
      ? await InternalToolsService.replyToEjectRequest(
          selectedRowDetails.value.id,
          ejectPayload
        )
      : await InternalToolsService.replyToTransferRequest(payload);

    if (selectedRowDetails.value.action === TransferReply.ACCEPT) {
      showSuccessModal.value = true;
    }
    await fetchTransferRequestData();
  } catch (error) {
    if (error?.response?.data?.errorCode) {
      const transferErrorDetails = transferErrors(
        error?.response?.data?.errorCode,
        error?.response?.data?.extraDetails
      );
      if (transferErrorDetails?.code === 'SUB_ACCOUNT_LIMIT_EXCEEDED') {
        showUpsellModal.value = true;
      } else if (transferErrorDetails) {
        errorDetails.value = {
          show: true,
          title: transferErrorDetails.heading,
          description: transferErrorDetails.message,
        };
      }
    }

    if (!errorDetails.value.show && !showUpsellModal.value) {
      let errorMessage;
      if (
        error?.response?.status === 401 ||
        error?.errorCode === 'NOT_AUTHORIZED'
      ) {
        errorMessage = t('unauthorizedTransfer');
      }
      errorDetails.value = {
        show: true,
        title:
          actionModalDetails.value.type === 'eject'
            ? t('unableEjectCancel')
            : t('unableTransfer'),
        description:
          actionModalDetails.value.type === 'eject'
            ? t('EjectErrorDetails')
            : errorMessage || t('transferErrorDetails'),
      };
    }
  } finally {
    actionModalDetails.value = defaultActionValues;
    buttonDisable.value = false;
  }
}

async function fetchTransferRequestData() {
  try {
    if (props.tabName === 'eject') {
      return fetchEjectRequestData();
    }
    table.value.isLoading = true;
    if (!appStore.companyId) {
      return setTimeout(async () => fetchTransferRequestData(), 1000);
    }
    let queryString = `companyId=${appStore.companyId}`;
    if (props.tabName === 'received') {
      queryString += `&type=received`;
    }
    if (props.tabName === 'sent') {
      queryString += `&type=sent`;
    }

    const { data: transferRequestData } =
      await InternalToolsService.fetchLocationTransferRequests(queryString);
    totalRequests.value = transferRequestData.length;
    table.value.data = transferRequestData;
    table.value.computedData = transferRequestData;
  } catch (error) {
    table.value.data = [];
    table.value.computedData = [];
  } finally {
    table.value.isLoading = false;
  }
}

async function fetchEjectRequestData() {
  try {
    table.value.isLoading = true;
    if (!appStore.companyId) {
      return setTimeout(async () => fetchEjectRequestData(), 1000);
    }
    const queryString = `companyId=${appStore.companyId}`;

    const { data: transferRequestData } =
      await InternalToolsService.fetchEjectRequests(queryString);
    totalRequests.value = transferRequestData.length;
    table.value.data = transferRequestData;
    table.value.computedData = transferRequestData;
  } catch (error) {
    table.value.data = [];
    table.value.computedData = [];
  } finally {
    table.value.isLoading = false;
  }
}
</script>

<style scoped></style>
