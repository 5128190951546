import moment from "moment-timezone";
import currencyList from "@/helpers/currency";

export function getESQuery(esBody: { [key: string]: string }, compare: boolean) {
  const { startDate, endDate, locationId } = esBody;
  return {
    locationId,
    filters: [
      {
        field: "date_added",
        operator: "range",
        value: getQueryDateRange(startDate, endDate, compare),
      },
    ],
    aggregations: [
      compare
        ? {
            name: "status_filters",
            type: "terms",
            field: "status",
            options: {
              min_doc_count: 0,
            },
          }
        : {
            name: "date_filters",
            type: "range",
            field: "date_added",
            options: {
              ranges: getAggDateRanges(startDate, endDate),
            },
            aggregations: [
              {
                name: "status_filters",
                type: "terms",
                field: "status",
                options: {
                  min_doc_count: 0,
                },
                aggregations: [
                  {
                    name: "monetary_value",
                    type: "sum",
                    field: "monetary_value",
                  },
                ],
              },
            ],
          },
    ],
  };
}

function getQueryDateRange(startDate: string, endDate: string, compare: boolean) {
  if (compare) {
    const daysDiff = moment(endDate).add(1, "day").diff(moment(startDate), "days");
    return {
      gte: moment(startDate).subtract(daysDiff, "d").format("x"),
      lte: moment(startDate).subtract(1, "d").endOf("D")
.format("x"),
    };
  }
  return {
    gte: moment(startDate).format("x"),
    lte: moment(endDate).endOf("day").format("x"),
  };
}

function getAggDateRanges(startDate: string, endDate: string) {
  let start = moment(startDate).startOf("D");
  const end = moment(endDate).endOf("D");
  const rangeArray: { [key: string]: any }[] = [];

  const diffInMonths = end.diff(start, "M");
  let unit = "M",
    numUnits = 1;
  if (diffInMonths > 1) {
    unit = "M";
    numUnits = 1;
  } else {
    unit = "days";
    if (end.diff(start, "days") <= 7) {
      numUnits = 1;
    } else {
      numUnits = 6;
    }
  }

  while (start.isBefore(end)) {
    let temp: moment.Moment;
    if (numUnits === 1) {
      temp = start.clone().endOf(unit as moment.DurationInputArg2);
    } else {
      temp = start
        .clone()
        .add(numUnits as moment.DurationInputArg1, unit as moment.DurationInputArg2)
        .endOf("D");
    }
    if (temp.isAfter(end)) {
      temp = end;
    }
    rangeArray.push({
      from: start.format("x"),
      to: temp.format("x"),
      key: getRangeLabel(start, temp),
    });
    start = temp.add(1).startOf("day");
  }
  return rangeArray;
}

function getRangeLabel(start: moment.Moment, end: moment.Moment) {
  const label1 = start.format("MMM DD");
  const label2 = end.format("MMM DD");
  if (label1 === label2) {
    return label1;
  }
  return `${label1} - ${label2}`;
}

export function symbol(value: string) {
  return currencyList[value] || "$";
}
