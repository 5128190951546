<template>
  <div id="location-transfer" class="container-height pl-8 pr-8">
    <div class="mt-11 px-3">
      <UIButton
        id="location-transfer__btn-back-to-account"
        type="default"
        class="font-bold"
        @click="backToAccount"
      >
        <UITextSmMedium
          class="inline-flex cursor-pointer content-center items-center font-bold text-blue-500"
        >
          <ArrowLeftIcon class="mr-3 h-5 w-5" />
          {{ $t("backToSubAccounts") }}
        </UITextSmMedium>
      </UIButton>

      <div class="mb-4 mt-6 justify-between">
        <UIDisplaySmMedium>{{ $t("subAccountTransfer") }}</UIDisplaySmMedium>
        <UITextSmMedium class="text-gray-500">{{ $t("manageSubAccTransfer") }}</UITextSmMedium>
      </div>
    </div>
    <hr />
    <div class="m-2">
      <UITabs
        :type="'line'"
        :animated="true"
        :value="undefined"
        :default-value="defaultTab"
        :justify-content="'start'"
      >
        <UITabPane name="received" :tab="$t('received')"
          ><TransferTable :tab-name="'received'"
        /></UITabPane>
        <UITabPane name="sent" :tab="$t('sent')"><TransferTable :tab-name="'sent'" /></UITabPane>
        <UITabPane name="eject" :tab="$t('eject')"><TransferTable :tab-name="'eject'" /></UITabPane>
      </UITabs>
    </div>
  </div>
</template>

<script setup lang="ts">
import { router } from "@/router";
import { useAppStore } from "@/store/app";
import { ArrowLeftIcon } from "@gohighlevel/ghl-icons/24/outline";
import {
UIButton,
UIDisplaySmMedium,
UITabPane,
UITabs,
UITextSmMedium,
} from "@gohighlevel/ghl-ui";
import { onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import TransferTable from "./TransferTable.vue";
const { t } = useI18n();
const defaultTab = ref<string>("received");

onBeforeMount(() => {
  const query = router.currentRoute.value.query;
  if (query?.tab && ["received", "sent", "eject"].includes(query.tab as string)) {
    defaultTab.value = query.tab as string;
  }
});

function backToAccount() {
  const appStore = useAppStore();
  const path = `/sub-accounts`;
  if (appStore.appHandshake) {
    appStore.appHandshake.then((parent) => {
      parent.emit("route-change", {
        path,
      });
    });
  }
  router.push(path);
}
</script>

<style scoped>
.container-height {
  min-height: calc(100vh - 90px);
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e5e7eb;
  margin: 1em 0;
  padding: 0;
}
</style>
