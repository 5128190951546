export default {
  sv: {
    common: {
      thisWeek: "Denna vecka",
      lastWeek: "Förra veckan",
      lastNumberOfDays: "Senaste {numberOfDays} dagarna",
      thisMonth: "Denna månad",
    },
    subAccounts: "Underkonton",
    submitFeedback: "Skicka feedback",
    createSubAccount: "Skapa underkonto",
    viewScheduleReports: "Visa schemalagda rapporter",
    searchSubAccount: "Sök efter underkonto",
    columns: "Kolumner",
    sortBy: "Sortera efter",
    selectColumns: "Välj kolumner",
    maxColumns: "Max 8 kolumner åt gången",
    applyColumns: "Ansök kolumner",
    activeUsers: "Aktiva användare",
    appointments: "Möten",
    contacts: "Kontakter",
    emails: "E-post",
    textMessages: "SMS-meddelanden",
    calls: "Samtal",
    submissions: "Inlämningar",
    reviews: "Recensioner",
    sitesCreated: "Webbplatser skapade",
    name: "Namn",
    formSubmissions: "Formulärinskickningar",
    surveySubmissions: "Enkätsvar",
    facebookFormSubmissions: "Facebook formulärinskick",
    funnels: "Trattar",
    websites: "Hemsidor",
    forms: "Former",
    surveys: "Undersökningar",
    highestFirst: "Högst först",
    lowestFirst: "Lägst först",
    orderBy: "Beställ av",
    noMoreLocations: "Ingen fler platser hittades",
    preparingReport: "Vi håller på att förbereda din rapport",
    preparingReportDesc: "Stäng inte eller ladda om det här fönstret medan exporten pågår",
    outOf: "ut av",
    subAccountsExported: "Underkonton exporterade",
    close: "Stäng",
    scheduledForDeletion: "Bokad för radering",
    readyToSell: "Färdig att sälja",
    closedWon: "Stängd (Vunnen)",
    wpSaleSuccessful: "WordPress-försäljningen är framgångsrik",
    wpNotSold: "WordPress inte såld än",
    yxtSaleSuccessful: "Försäljningen av Yext är framgångsrik",
    yxtNotSold: "Yext inte sålt ännu",
    saasSubInactive: "SaaS-prenumerationen är inaktiv",
    saasSubTrialing: "SaaS-prenumeration testas",
    saasSubActive: "SaaS-prenumerationen är aktiv",
    emailRebillingActive: "E-post omdebitering är aktiv",
    emailRebillingInactive: "E-poståterbetalning är inaktiv",
    twilioRebillingActive: "Twilio-omdebitering är aktiverad",
    twilioRebillingInactive: "Twilio återdebitering är inaktiv",
    wpRebillingActive: "WhatsApp återfakturering är aktiv",
    wpRebillingInactive: "WhatsApp-omdebitering är inaktiv",
    saasModeActivated: "SaaS-läge är aktiverat",
    paymentPending: "Betalningen är avvaktande",
    saasModeNotActivated: "SaaS-läge är inte aktiverat",
    managedByLCPhone: "Hanteras av LC Phone",
    migrationToLCPhone: "Under migration till LC Phone",
    notManagedByLCPhone: "Inte hanterad av LC Phone",
    managedByLCEmail: "hanteras av LC Email",
    notMangedByLCEmail: "Inte hanteras av LC-e-post",
    remainingWalletBalance: "Återstående plånbokssaldo för denna SaaS-plats",
    reports: "Rapporter",
    switchToSubAccount: "Byt till delkonto",
    manageClient: "Hantera klient",
    subAccountDeleted: "Den här delkontot kommer att bli raderat den",
    subAccountDeletedInMinute: "Denna delkonto kommer att raderas om en minut",
    changedMind: "om du har ändrat dig",
    clickHere: "Klicka här",
    subAccountPaused:
      "Denna delkonto är pausat. Dina klienter kan inte logga in men byrå kan. För att återuppta detta konto",
    subAccountRestricted: "Denna underkonto är pausat på grund av väntande godkännande",
    subAccountVerificationPending:
      "Denna underkonto är pausat på grund av förväntad e-post- och telefonverifiering från underkontots administratör",
    subAccountDeclined:
      "Denna underkonto är pausat på grund av avböjd godkännandebegäran. Du kan fortfarande återuppta detta underkonto från",
    here: "här",
    reviewAndResume: "för att granska och återuppta detta underkonto",
    incomingCalls: "Inkommande samtal",
    outgoingCalls: "Utgående samtal",
    totalTalktime: "Totala samtalstiden",
    incomingEmails: "Inkommande e-postmeddelanden",
    outgoingEmails: "Utgående e-postmeddelanden",
    incomingSms: "Inkommande textmeddelanden",
    outgoingSms: "Utgående textmeddelanden",
    contactsCreated: "Antal kontakter skapade",
    appointmentsBooked: "Antal bokade möten",
    totalFormSubmissions: "Totalt antal formulärinlämningar",
    totalSurveySubmissions: "Totalt antal undersökningssvar",
    totalFacebookFormSubmissions: "Totala Facebook-formulärsändningar",
    incomingGMBMessages: "Inkommande GBP-meddelanden",
    outgoingGMBMessages: "Utgående GBP-meddelanden",
    gmbMessages: "GBP Meddelanden",
    fbMessages: "Facebook meddelanden",
    incomingFbMessages: "Inkommande Facebook-meddelanden",
    outgoingFbMessages: "Utgående Facebook-meddelanden",
    igMessages: "Instagram-meddelanden",
    incomingIgMessages: "Inkommande Instagram-meddelanden",
    outgoingIgMessages: "Utgående Instagram-meddelanden",
    totalReviews: "Totala recensioner",
    positiveReviews: "Positiva recensioner",
    negativeReviews: "Negativa recensioner",
    back: "Tillbaka",
    selectDashboards: "Välj instrumentbrädor",
    next: "Nästa",
    backToSubAccounts: "Tillbaka till underkonton",
    pauseSchedule: "Pauschema",
    resumeSchedule: "Återuppta schemat",
    editSchedule: "Redigera schema",
    addSchedule: "Lägg till schema",
    selectReportToAdd: "Välj rapporter att lägga till i e-posten",
    selectAll: "Välj alla",
    pauseScheduleConfirm: "Är du säker på att du vill pausa rapport schemat?",
    resumeScheduleConfirm: "Är du säker på att du vill återuppta schemaläggningen för rapporten?",
    tasks: "Uppgifter",
    conversations: "Samtal",
    users: "Användare",
    dealOverview: "Översikt över affären",
    confirmAction: "Bekräfta åtgärd",
    yes: "Ja",
    no: "Nej",
    reportDetails: "Rapportdetaljer",
    reportCreatedOn: "Rapport skapad den",
    searchByEmail: "Sök efter e-post",
    lastActivity: "Senaste aktivitet",
    noRecords: "Inga poster hittades",
    scheduleReport: "Schema Rapport",
    schedule: "Schema",
    save: "Spara",
    emailSubject: "Ämne för e-post",
    frequency: "Frekvens",
    daily: "Daglig",
    weekly: "Veckovis",
    monthly: "Månadsvis",
    nextScheduleDate: "Nästa schemalagda datum",
    startingOn: "Börjar på",
    timeOfDay: "Tid på dagen",
    reportsToTimezone: "Rapporten kommer att skickas i delkontots tidszon",
    selectRecipients: "Välj mottagare",
    invalidEmail: "Du har angett en ogiltig e-postadress",
    testEmail: "Test e-post",
    selectTestRecipients: "Välj testmottagare",
    setupSubject: "Ställ in ämnet ovanför för att testa rapporten",
    sendTest: "Skicka test",
    scheduleSimilarSchedules: "Schema en liknande rapport för de andra underkontona",
    accountSummary: "Kontosammanfattning",
    scheduleList: "Schema lista",
    subAccountName: "Underkonto namn",
    nextRunDate: "Nästa körningsdatum",
    reportSectionsAdded: "Rapportsektioner tillagda",
    status: "Status",
    scheduleAction: "Planera handling",
    cancel: "Avbryt",
    pleaseWait: "Vänta snälla",
    confirm: "Bekräfta",
    booked: "Bokad",
    confirmed: "Bekräftad",
    showed: "Visade",
    noShow: "Ingen Show",
    cancelled: "Inställt",
    unread: "Olästa",
    engagement: "Förlovningsring",
    reaction: "Reaktion",
    deliverySummary: "Leveranssammanfattning",
    successfulDelivery: "Lyckad leverans",
    opened: "Öppnad",
    clicked: "Klickade",
    replied: "Svarade",
    unsubscribed: "Avregistrerad",
    complained: "Klagade",
    failed: "Misslyckades",
    open: "Öppen",
    won: "Vann",
    abandoned: "Övergiven",
    lost: "Förlorad",
    sent: "Sent",
    delivered: "Levererad",
    faceBookLeadForm: "Facebook-läshändelseformulär",
    overdue: "Försenad",
    reviewsReceived: "Recensioner mottagna",
    formBuilders: "Formbyggare",
    switchToSaaS: "Byt till SaaS",
    numberOfContacts: "Antal kontakter",
    numberOfAppointments: "Antal bokningar",
    outreach: "Utbildning_bortom_väggarna",
    notSubAccountUser: "Inte en användare av underkonto",
    searchSubAccName: "Sök efter delkontots namn",
    recipients: "Mottagare",
    scheduleCreated: "E-postschemat skapat för",
    scheduleUpdated: "E-postrapport-schema uppdaterat för",
    scheduleNotCreated: "E-postrapportens schema kunde inte skapas för",
    scheduleNotUpdated: "E-postrapportens schema kunde inte uppdateras för",
    total: "Total",
    inbound: "Inkommande",
    outbound: "Utgående",
    totalEmails: "Totala antalet e-postmeddelanden",
    inboundEmails: "Inkommande e-postmeddelanden",
    outboundEmails: "Utgående e-postmeddelanden",
    totalTextMessages: "Totala SMS-meddelanden",
    inboundTextMessages: "Inkommande textmeddelanden",
    outboundTextMessages: "Utgående textmeddelanden",
    totalCalls: "Totala samtal",
    inboundCalls: "Inkommande samtal",
    outboundCalls: "Utgående samtal",
    faceBookLeadFormSubmissions: "Facebook-leadformulärinlämningar",
    inboundGMBMessages: "Inkommande GBP-meddelanden",
    outboundGMBMessages: "Utgående meddelanden GBP",
    totalIgMessages: "Totala Instagram-meddelanden",
    inboundIgMessages: "Inkommande Instagram-meddelanden",
    outboundIgMessages: "Utgående Instagram-meddelanden",
    totalfbMessages: "Totala Facebook-meddelanden",
    inboundfbMessages: "Inkommande meddelanden på Facebook",
    outboundfbMessages: "Utgående Facebook-meddelanden",
    sitesCreatedFunnels: "Webbplatser skapade - Säljtrattar",
    sitesCreatedWebsites: "Webbplatser skapade - webbplatser",
    sitesCreatedForms: "Skapade webbplatser - Formulär",
    sitesCreatedSurveys: "Webbplatser skapade - undersökningar",
    googleReviews: "Google-recensioner",
    facebookReviews: "Facebook recensioner",
    inboundCallDuration: "Inkommande samtal varaktighet (timmar)",
    outboundCallDuration: "Utgående samtalstid (timmar)",
    pleaseSelect: "Var god välj",
    subAccountIsDeleted: "Denna underkonto har raderats",
    subAccountIsPaused: "Denna underkonto har pausats",
    success: "Framgång",
    error: "Fel",
    testEmailSent: "Test e-post skickad",
    testEmailNotSent: "Test mejl kunde inte skickas",
    typeToSearch: "Skriv för att söka",
    reviewRequest: "Granskningsbegäran",
    pendingTransferDescription: "Andra myndigheter har begärt att överföra delkonton till dig",
    pendingTransferHeading: "Du har väntande förfrågningar om överföring av delkonton",
    subAccountTransfer: "Delkontoöverföringar",
    manageSubAccTransfer: "Hantera dina underkontotransferförfrågningar",
    subAccRequest: "Delkonto {tabName}-begäranden",
    receive: "Ta emot",
    send: "Skicka",
    release: "Släppa",
    requests: "{count} Förfrågningar",
    subAccLimit: "Underkonton användningsgräns nådd!",
    subAccPlanLimit: "Du har använt 3 av 3 delkonton som tillåts enligt din nuvarande plan",
    upgradeTime: "Det är dags för en uppgradering!",
    upgradeHigher: "Uppgradera till ett högre plan för att acceptera överföringen av underkonton",
    browse: "Bläddra i planer",
    pendingTransferTitle: "En överföringsbegäran för detta delkonto är redan under behandling",
    unableTransfer: "Kan inte överföra delkonto till detta företag",
    hippaErrorDescription:
      "Den underkonto du försöker överföra är HIPAA-aktiverat och kan inte överföras till en icke-HIPAA-byrå",
    pendingTransferErrorDescription:
      "Vänligen avbryt den redan befintliga överföringsbegäran för detta underkonto innan du fortsätter med att göra en ny",
    receivingAgency: "Mottagande myndighet",
    releasingAgency: "Utgivande myndighet",
    noOfContacts: "Antal kontakter",
    requestedDate: "Önskat datum",
    action: "Åtgärd",
    cancelTransfer: "Avbryt överföring",
    decline: "Nedgång",
    cancelTransferRequest: "Avbryt begäran om överföring av underkonto?",
    cancelRequestDescription:
      "Mottagarorganet kommer inte längre att se denna överföringsförfrågan och delkontot {namn} kommer inte att överföras",
    declineRequest: "Avböj begäran om överföring av underkonto?",
    declineRequestDescription:
      "Begäran kommer att avslås och delkontot {name} kommer inte att överföras till din byrå",
    approveTransfer: "Godkänna underkontotransfer till ditt byrå?",
    approveTransferDescription: "Underkontot för {name} kommer att överföras till din byrå",
    transferNow: "Överför nu",
    cancelNow: "Avbryt nu",
    transferErrorDetails: "Det underkonto som du försöker överföra kan inte överföras",
    cancelRequest: "Avbryt förfrågan",
    received: "Mottagen",
    approve: "Godkänna",
    goBack: "Gå tillbaka",
    done: "Klar",
    subAccountTransferred: "Den underkonto har framgångsrikt överförts!",
    subAccountTransferDescription:
      "Observera att även om underkontot har överförts kan vissa tillgångar som användare, telefonnummer, Mailgun-konton, Yext-listor osv. fortfarande ta 10-15 minuter innan de uppdateras på destinationsplatsen",
    activeSubscriptionsAlert:
      "Platsen har aktiva prenumerationer. Vänligen avbryt alla prenumerationer innan du överför platsen. Lista över aktiva prenumerationer: {subscriptions}",
    emailEngagement: "E-postengagemang",
    emailReaction: "E-postreaktion",
    emailDeliverySummary: "E-postleveranssammanfattning",
    facebook: "Facebook",
    sms: "SMS",
    instagram: "Instagram",
    accountSummarySubAccount: "Account Summary - {subAccountName}",
    test: "Test",
    scheduleExists: "Schedule Exists",
    aToZ: "A - Z",
    zToA: "Z - A",
    onlyAdminUsersCanAddNewSubAccounts: "Only Admin users can add new sub-account's.",
    locationName: "Location Name",
    subAccountDeletedIfChangedMind:
      "This Sub-Account will be deleted on {time}, if you've changed your mind",
    eject: "Kasta ut",
    unableEjectCancel: "Kan inte avboka begäran om att kasta ut delkonto",
    EjectErrorDetails: "Begäran om att avsluta delkontot som du försöker avbryta kan inte avbrytas",
    cancelEjectRequest: "Avbryt begäran om att avsluta delkonto?",
    cancelEjectRequestDescription:
      "Den valda användaren kommer inte längre att kunna skapa en ny byrå utifrån delkontot {name}",
      transferCompleted:
        "Överföringsbegäran för denna plats har redan behandlats. Vänligen uppdatera.",
      unauthorizedTransfer:
        "Du har inte behörighet att utföra denna handling. Endast byråägare kan utföra denna handling.",
  },
};
