<template>
  <div class="container-height">
    <UIToolbar class="mb-16 mt-5">
      <UIToolbarGroup>
        <UIButton id="btn-1" text @click="emitClose">
          <ArrowLeftIcon class="mr-3 h-5 w-5 text-gray-100" />
          <span class="text-sm text-gray-100"> {{ $t("back") }} </span>
        </UIButton>
      </UIToolbarGroup>
      <UIToolbarGroup>
        <UITextLgMedium class="text-white"> {{ $t("scheduleReport") }} </UITextLgMedium>
      </UIToolbarGroup>
      <UIToolbarGroup>
        <UIButton
          v-if="!editMode"
          id="schedule-btn"
          type="primary"
          :disabled="!formValid || !validEmails || isLoading"
          @click="onSchedule"
        >
          <ClockIcon class="mr-2 h-5 w-5"></ClockIcon>
          {{ $t("schedule") }}
        </UIButton>
        <UIButton
          v-else
          id="edit-schedule-btn"
          type="primary"
          :disabled="editDisable || (scheduleStateCompare && insightsCompare)"
          @click="onEditSchedule"
        >
          <ClockIcon class="mr-2 h-5 w-5"></ClockIcon>
          {{ $t("save") }}
        </UIButton>
      </UIToolbarGroup>
    </UIToolbar>

    <section class="w-100 flex flex-row justify-center p-0">
      <div class="flex w-3/5 flex-col items-start justify-center p-0">
        <UIFormItem :label="$t('emailSubject')" class="w-full">
          <UIInput id="frequency-input" v-model="scheduleForm.subject" />
        </UIFormItem>
        <div class="flex w-full flex-row items-start gap-2 p-0">
          <UIFormItem :label="$t('frequency')" class="w-1/3">
            <UISelect
              id="starting-datepicker"
              v-model:value="scheduleForm.frequency"
              :options="frequencyOptions"
            >
            </UISelect>
          </UIFormItem>
          <UIFormItem :label="nextRunDateLabel" class="w-1/3">
            <UIDatepicker
              id="date-picker"
              v-model:value="scheduleForm.scheduleDate"
              class="w-full"
              :is-date-disabled="minDate"
            />
          </UIFormItem>
          <div class="flex w-1/3 flex-col gap-1">
            <UIFormItem :label="$t('timeOfDay')" class="w-full" :show-feedback="false">
              <UISelect
                id="starting-timepicker"
                v-model:value="scheduleForm.scheduleTime"
                :options="timeOptions"
              ></UISelect>
            </UIFormItem>
            <UITextXsMedium class="ml-1 text-gray-500">
              {{ $t("reportsToTimezone") }}
            </UITextXsMedium>
          </div>
        </div>

        <UIDivider />

        <UIFormItem :label="$t('recipients')" class="w-full">
          <UIPopover :show="!validEmails">
            <template #trigger>
              <UISelect
                id="select-id"
                v-model:value="scheduleForm.recipients"
                multiple
                :options="recipientsOptions"
                :placeholder="$t('selectRecipients')"
                type="avatar"
                :filterable="true"
                :tag="true"
              />
            </template>
            <span>{{ $t("invalidEmail") }}</span>
          </UIPopover>
        </UIFormItem>

        <div class="flex w-full flex-row items-center gap-2 p-0">
          <div class="flex w-4/5 flex-col gap-2">
            <UIFormItem :label="$t('testEmail')" class="w-full" :show-feedback="false">
              <UIPopover :show="!validTestEmails">
                <template #trigger>
                  <UISelect
                    id="select-test-id"
                    v-model:value="testEmails"
                    multiple
                    :disabled="!testMailEnable"
                    :options="recipientsOptions"
                    :placeholder="$t('selectTestRecipients')"
                    type="avatar"
                    :filterable="true"
                    :tag="true"
                  />
                </template>
                <span>{{ $t("invalidEmail") }}</span>
              </UIPopover>
            </UIFormItem>
            <div class="h-4">
              <UITextXsMedium
                v-show="!testMailEnable"
                class="flex flex-row items-center text-gray-500"
              >
                <InfoCircleIcon class="mr-1 h-5 w-5" />
                {{ $t("setupSubject") }}
              </UITextXsMedium>
            </div>
          </div>
          <UIButton
            id="pg-agency-locations__btn--email-test"
            class="w-1/5"
            :disabled="sendEmailDisabled"
            @click="onSendTestMail"
          >
            <Send03Icon class="mr-2 h-5 w-5"></Send03Icon>
            {{ $t("sendTest") }}
          </UIButton>
        </div>
      </div>
    </section>
  </div>
  <Modal
    :show-modal="modalVisible"
    :data="modalData"
    :btn-disable="confirmDisable"
    :spinner="bulkSchedule"
    @close-modal="toggleModal"
    @submit="submitModal"
  >
    <template #content>
      <div class="flex flex-row items-center gap-2">
        <UISwitch
          id="pg-agency-locations__switch--schedule-similar"
          v-model:value="modalData.showInput"
          @update:value="toggleModalInput"
        ></UISwitch>
        <p class="inline-block">{{ $t("scheduleSimilarSchedules") }}</p>
      </div>
      <UISelect
        v-show="modalData.showInput"
        id="select-loc-id"
        v-model:value="otherLocationSelections"
        multiple
        :options="otherLocationOptions"
        :placeholder="$t('searchSubAccName')"
        type="avatar"
        :filterable="true"
        :scrollable="true"
        @search="searchLocation"
      />
    </template>
  </Modal>
</template>

<script>
import { useAppStore } from "@/store/app";
import {
  UIButton,
  UIDatepicker,
  UIDivider,
  UIFormItem,
  UIInput,
  UIPopover,
  UISelect,
  UISwitch,
  UITextLgMedium,
  UITextXsMedium,
  UIToolbar,
  UIToolbarGroup,
  useNotification,
} from "@gohighlevel/ghl-ui";

import {
  ArrowLeftIcon,
  ClockIcon,
  InfoCircleIcon,
  Send03Icon,
} from "@gohighlevel/ghl-icons/24/outline";

import moment from "moment";

import { LocationService } from "../../services/LocationService";
import { ReportingService } from "../../services/ReportingService";
import { UserService } from "../../services/UserService";

import TimeOptions from "./helpers/options/timeOptions.js";

import Modal from "./helpers/modal/Modal.vue";

export default {
  components: {
    UIButton,
    UIToolbar,
    UIToolbarGroup,
    UITextLgMedium,
    ArrowLeftIcon,
    UIFormItem,
    UIInput,
    UISelect,
    UIDivider,
    ClockIcon,
    UIDatepicker,
    Send03Icon,
    Modal,
    UISwitch,
    UIPopover,
    UITextXsMedium,
    InfoCircleIcon,
  },
  props: ["locationData", "scheduleBody", "insightsArray"],
  emits: ["close-schedule"],
  data() {
    return {
      frequencyOptions: [
        {
          label: this.$t("daily"), //"Daily",
          value: "daily",
        },
        {
          label: this.$t("weekly"),
          value: "weekly",
        },
        {
          label: this.$t("monthly"),
          value: "monthly",
        },
      ],
      timeOptions: TimeOptions,
      otherLocationOptions: [],
      otherLocationSelections: [],
      recipientsOptions: [],
      scheduleForm: {
        subject: this.$t("accountSummarySubAccount", {
          subAccountName: this.locationData.name,
        }),
        frequency: "daily",
        scheduleDate: null,
        scheduleTime: null,
        recipients: "",
      },
      testEmails: [],
      modalVisible: false,
      modalData: {
        propTitle: this.$t("scheduleReport"),
        showInput: false,
        buttons: { secondary: this.$t("cancel") },
      },
      insights: this.insightsArray,
      timer: null,
      bulkSchedule: false,
      successLocations: [],
      errorLocations: [],
      editMode: false,
      isLoading: true,
      reportActionVerb: "created",
      isUpdating: false,
      isSendingTestMail: false,
      scheduleId: null,
      tempScheduleTime: null,
      notification: null,
      schedule: null,
      scheduleFormState: null,
    };
  },
  computed: {
    formValid() {
      // returns true when form is valid
      for (const key in this.scheduleForm) {
        if (
          this.scheduleForm[key] === "" ||
          (typeof this.scheduleForm[key] === "string" &&
            this.scheduleForm[key].trim().length === 0) ||
          this.scheduleForm[key] === null ||
          (typeof this.scheduleForm[key] === "object" &&
            Object.keys(this.scheduleForm[key]).length === 0)
        ) {
          return false;
        }
      }
      return true;
    },
    validEmails() {
      // returns true if all emails are valid
      if (this.scheduleForm.recipients === "") {
        return true;
      } else if (typeof this.scheduleForm.recipients === "object") {
        if (Object.keys(this.scheduleForm.recipients).length === 0) {
          return true;
        }
        for (const key of Object.keys(this.scheduleForm.recipients)) {
          if (!this.validateEmail(this.scheduleForm.recipients[key])) {
            return false;
          }
        }
        return true;
      }
      return false;
    },
    validTestEmails() {
      // returns true if all emails are valid
      if (this.testEmails === "") {
        return true;
      } else if (typeof this.testEmails === "object") {
        if (Object.keys(this.testEmails).length === 0) {
          return true;
        }
        for (const key of Object.keys(this.testEmails)) {
          if (!this.validateEmail(this.testEmails[key])) {
            return false;
          }
        }
        return true;
      }
      return false;
    },
    sendEmailDisabled() {
      // returns true if button needs to be disabled
      if (
        this.testEmails.length === 0 ||
        (this.testEmails.length > 0 && !this.validTestEmails) ||
        this.isSendingTestMail ||
        !this.testMailEnable
      ) {
        return true;
      }
      return false;
    },
    confirmDisable() {
      if (
        (this.modalData.showInput && this.otherLocationSelections.length === 0) ||
        this.bulkSchedule
      ) {
        return true;
      }
      return false;
    },
    editDisable() {
      return this.isUpdating || !this.formValid || !this.validEmails || this.isLoading;
    },
    scheduleStateCompare() {
      // return true if the state is same
      for (const key of Object.keys(this.scheduleForm)) {
        if (this.scheduleForm[key] !== this.scheduleFormState[key]) {
          return false;
        }
      }
      return true;
    },
    insightsCompare() {
      // returns true if insights are same
      if (!this.schedule) {
        if (this.insights.length !== this.scheduleBody.data.insights.length) {
          return false;
        }
        return this.insights.every((insight) =>
          this.scheduleBody.data.insights.includes(insight, 0)
        );
      }
      if (this.insights.length !== this.schedule.insights.length) {
        return false;
      }
      return this.insights.every((insight) => this.schedule.insights.includes(insight, 0));
    },
    nextRunDateLabel() {
      return this.editMode ? this.$t("nextScheduleDate") : this.$t("startingOn");
    },
    testMailEnable() {
      // returns true when subject and frequency are added
      if (this.scheduleForm.subject.trim().length === 0 || this.scheduleForm.frequency === null) {
        return false;
      }
      return true;
    },
  },
  watch: {
    "scheduleForm.scheduleDate"(newVal) {
      if (newVal < Date.now()) {
        this.timeOptions.forEach((time) => {
          if (time.value.split(":")[0] <= moment(Date.now()).format("HH")) {
            time.disabled = true;
            if (this.scheduleForm.scheduleTime === time.value) {
              this.tempScheduleTime = this.scheduleForm.scheduleTime;
              this.scheduleForm.scheduleTime = null;
            }
          }
        });
      } else {
        this.timeOptions.forEach((time) => {
          time.disabled = false;
        });
        if (this.tempScheduleTime && !this.scheduleForm.scheduleTime) {
          this.scheduleForm.scheduleTime = this.tempScheduleTime;
        }
      }
    },
  },
  mounted() {
    this.setupNotification();
    this.loadData();
  },
  methods: {
    setupNotification() {
      this.notification = useNotification();
    },
    async enableNotification(notificationConfig) {
      await this.notification.create(notificationConfig);
      // For padding top in scroll container and avoid global styles adding
      const elem = document.getElementsByClassName("n-scrollbar-container");
      elem[0].style["padding-top"] = "60px";
    },
    async loadData() {
      // base function to load data
      this.loading = true;
      if (this.scheduleBody && this.scheduleBody.editScheduleMode) {
        this.editMode = true;
        this.populateForm();
      }
      const userData = await this.getUsers();
      this.populateRecipients(userData);
      this.isLoading = false;
    },
    async getUsers() {
      // http req to get users
      const { data } = await UserService.getUsersList({
        locationId: this.locationData.id,
        companyId: this.locationData.companyId,
        type: "account",
        role: "admin",
      });
      return data;
    },
    populateRecipients(data) {
      // populate recipientOptions array
      this.recipientsOptions = [];
      let locationEmailExists = false;
      for (const user of data.users) {
        if (user.email === this.locationData.email) {
          locationEmailExists = true;
        }
        this.recipientsOptions.push({ label: user.email, value: user.email });
      }
      if (!locationEmailExists && this.locationData.email && this.locationData.email !== "") {
        this.recipientsOptions.push({
          label: this.locationData.email,
          value: this.locationData.email,
        });
      }
    },
    populateForm() {
      const { subject, frequency, recipients, nextRunTime, scheduleTime } = this.scheduleBody.data;

      this.scheduleForm = {
        subject,
        frequency,
        scheduleDate: this.getScheduleDate(nextRunTime),
        scheduleTime,
        recipients,
      };
      this.scheduleFormState = { ...this.scheduleForm };
    },
    getScheduleDate(nextRunTime) {
      const tzOffset = moment.tz.zone(this.locationData.timezone).parse(Date.now()); // today's date offset for the timezone considering dst (daylight saving)
      // find minutes passed for the scheduled date in utc
      const date = new Date(nextRunTime);
      const minutes = date.getUTCMinutes();
      const hours = date.getUTCHours();
      const minutesPassed = hours * 60 + minutes;
      const offset = minutesPassed - tzOffset;
      const milliSecOnDate = parseInt(moment(nextRunTime.split("T")[0]).format("x"));
      const milliSecOnDay = 8.64e7;
      if (offset < 0) {
        return milliSecOnDate - milliSecOnDay;
      } else if (offset > 1440) {
        // 1440 minutes in a day
        return milliSecOnDate + milliSecOnDay;
      }
      return milliSecOnDate;
    },
    onSchedule() {
      // on clicking 'Schedule'
      if (!this.formValid || this.editMode) {
        return;
      }
      this.toggleModal();
    },
    async onEditSchedule() {
      // console.log("edit api");
      if (!this.formValid || !this.editMode) {
        return;
      }
      this.scheduleForm.subject = this.scheduleForm.subject.trim();
      this.isUpdating = true;
      const res = await Promise.allSettled([this.editSchedule()]);
      this.isUpdating = false;
      this.createAlert(res, "updated");
    },
    async editSchedule() {
      const id = this.scheduleBody?.data._id || this.scheduleId;
      const { data } = await ReportingService.updateScheduleById(id, {
        ...this.scheduleForm,
        scheduleDate: moment(this.scheduleForm.scheduleDate).format("YYYY-MM-DD"),
        insights: this.insights,
        locationId: this.locationData.id,
        status: this.scheduleBody?.data ? this.scheduleBody.data.status : this.schedule.status,
      }); // this.scheduleId
      return data;
    },
    async createSchedule(body) {
      // POST Req to create schedule
      body.status = "published";
      const { data } = await ReportingService.createSchedule(body);
      return data;
    },
    async onSendTestMail() {
      // on sending test mail
      if (this.sendEmailDisabled) {
        return;
      }
      this.isSendingTestMail = true;
      const testMailBody = {
        locationId: this.locationData.id,
        name: this.$t("test"),
        subject: this.scheduleForm.subject.trim(),
        frequency: this.scheduleForm.frequency,
        insights: this.insights,
        recipients: this.testEmails,
        status: "draft",
      };
      try {
        const { data } = await ReportingService.sendTestMail(testMailBody);
        this.createEmailAlerts(this.$t("testEmailSent"), "success");
      } catch (e) {
        this.createEmailAlerts(this.$t("testEmailNotSent"), "error");
      }
      this.isSendingTestMail = false;
    },
    createEmailAlerts(message, type) {
      this.enableNotification({
        title: type === "success" ? this.$t("success") : this.$t("error"),
        type: type,
        content: message,
        duration: 5000,
      });
    },
    emitClose() {
      // on clicking Back button
      this.$emit("close-schedule");
    },
    minDate(ts) {
      // to disable older dates on datepicker (will disable the dates which return true)
      return Date.now() - 8.64e7 > ts;
    },
    toggleModal() {
      // to toggle modal visibility
      this.modalVisible = !this.modalVisible;
    },
    toggleModalInput(val) {
      // toggle input field visibility inside modal
      this.modalData.showInput = val;
    },
    async submitModal() {
      // on submitting modal
      this.scheduleForm.subject = this.scheduleForm.subject.trim();
      await this.bulkScheduler();
      this.toggleModal();
    },
    async bulkScheduler() {
      const batchScheduleArray = [
        this.createSchedule({
          ...this.scheduleForm,
          scheduleDate: moment(this.scheduleForm.scheduleDate).format("YYYY-MM-DD"),
          insights: this.insights,
          locationId: this.locationData.id,
        }),
      ];

      for (const loc of this.otherLocationSelections) {
        batchScheduleArray.push(
          this.createSchedule({
            ...this.scheduleForm,
            scheduleDate: moment(this.scheduleForm.scheduleDate).format("YYYY-MM-DD"),
            insights: this.insights,
            locationId: loc.id,
            subject: this.$t("accountSummarySubAccount", {
              subAccountName: loc.name,
            }),
            recipients: [loc.email],
          })
        );
      }

      this.bulkSchedule = true;
      const res = await Promise.allSettled(batchScheduleArray);

      this.createAlert(res, "created");
      this.bulkSchedule = false;

      this.modalData.showInput = false;
      this.otherLocationSelections = [];
    },
    createAlert(res, verb) {
      this.successLocations = [];
      this.errorLocations = [];
      this.reportActionVerb = verb;
      for (const promiseResp of res) {
        if (promiseResp.status === "fulfilled") {
          if (promiseResp.value.data.locationId === this.locationData.id) {
            // this.successLocations.push(this.locationData.name);
            this.enableNotification({
              title: this.$t("success"),
              type: "success",
              content:
                this.reportActionVerb === "created"
                  ? `${this.$t("scheduleCreated")} ${
                      this.locationData.name ? this.locationData.name : "unknown"
                    }`
                  : `${this.$t("scheduleUpdated")} ${
                      this.locationData.name ? this.locationData.name : "unknown"
                    }`,
              duration: 5000,
            });

            this.scheduleId = promiseResp.value.data._id;
            this.schedule = promiseResp.value.data;
            this.scheduleFormState = { ...this.scheduleForm };
            this.editMode = true;
          } else {
            // this.successLocations.push(
            // this.otherLocationSelections.find(
            //   (loc) => loc.id === promiseResp.value.data.locationId
            // ).name
            // );
            this.enableNotification({
              title: this.$t("success"),
              type: "success",
              content:
                this.reportActionVerb === "created"
                  ? `${this.$t("scheduleCreated")} ${this.otherLocationSelections
                      .find((loc) => loc.id === promiseResp.value.data.locationId)
                      .name.trim()}`
                  : `${this.$t("scheduleUpdated")} ${this.otherLocationSelections
                      .find((loc) => loc.id === promiseResp.value.data.locationId)
                      .name.trim()}`,
              duration: 5000,
            });
          }
        } else if (promiseResp.status === "rejected") {
          if (JSON.parse(promiseResp.reason.config.data).locationId === this.locationData.id) {
            // this.errorLocations.push(this.locationData.name);
            this.enableNotification({
              title: this.$t("error"),
              type: "error",
              content:
                this.reportActionVerb === "created"
                  ? `${this.$t("scheduleNotCreated")} ${
                      this.locationData.name ? this.locationData.name : "unknown"
                    }`
                  : `${this.$t("scheduleNotUpdated")} ${
                      this.locationData.name ? this.locationData.name : "unknown"
                    }`,
              duration: 5000,
            });
          } else {
            // this.errorLocations.push(
            //   this.otherLocationSelections.find(
            //     (loc) => loc.id === JSON.parse(promiseResp.reason.config.data).locationId
            //   ).name
            // );
            this.enableNotification({
              title: this.$t("error"),
              type: "error",
              content:
                this.reportActionVerb === "created"
                  ? `${this.$t("scheduleNotCreated")} ${this.otherLocationSelections
                      .find(
                        (loc) => loc.id === JSON.parse(promiseResp.reason.config.data).locationId
                      )
                      .name.trim()}`
                  : `${this.$t("scheduleNotUpdated")} ${this.otherLocationSelections
                      .find(
                        (loc) => loc.id === JSON.parse(promiseResp.reason.config.data).locationId
                      )
                      .name.trim()}`,
              duration: 5000,
            });
          }
        }
      }
    },
    handleAlertClose(array) {
      this[array].length = 0;
    },
    validateEmail(emailString) {
      // returns null if string is not an email id
      return String(emailString)
        .toLowerCase()
        .match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
    },
    searchLocation(searchText) {
      // starts searching the text after 0.5 sec
      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(
        async () => {
          await this.searchLocations(searchText);
        },
        500,
        searchText
      );
    },
    async searchLocations(searchText) {
      // clear timer
      clearTimeout(this.timer);
      this.timer = null;

      // api call
      const appStore = useAppStore();
      let { data } = await LocationService.getAll(appStore.companyId, searchText);
      data = await this.disablePrevScheduled(data);
      // keeping previous already selected options in the array of options
      this.otherLocationOptions = this.otherLocationOptions.filter((option) =>
        this.otherLocationSelections.includes(option.value, 0)
      );

      // only adding unique locations by id
      for (const loc of data.locations) {
        if (
          this.otherLocationOptions.findIndex((location) => location.value === loc._id) === -1 &&
          loc._id !== this.locationData.id
        ) {
          this.otherLocationOptions.push({
            label: loc.name + " " + `${loc.disabled ? `(${this.$t("scheduleExists")})` : ""}`,
            value: { id: loc._id, name: loc.name, email: loc.prospectInfo.email },
            disabled: loc.disabled ? true : false,
          });
        }
      }
    },
    async disablePrevScheduled(data) {
      const scheduledLocationsArray = [];
      for (const loc of data.locations) {
        scheduledLocationsArray.push(this.getSchedules(loc._id));
      }
      const scheduledLocResp = await Promise.allSettled(scheduledLocationsArray);
      for (const resp of scheduledLocResp) {
        if (resp.status === "fulfilled" && resp.value.count) {
          data.locations.find((loc) => loc._id === resp.value.data[0].locationId).disabled = true;
        }
      }
      return data;
    },
    async getSchedules(locationId) {
      // http req to get schedules for this location
      const { data } = await ReportingService.getSchedulesByLocation({
        locationId: locationId,
      });

      return data;
    },
  },
};
</script>

<style scoped>
.container-height {
  min-height: calc(100vh - 90px);
}
</style>
