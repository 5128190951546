export default {
  da: {
    common: {
      thisWeek: "Denne uge",
      lastWeek: "Sidste uge",
      lastNumberOfDays: "Sidste {numberOfDays} dage",
      thisMonth: "Denne måned",
    },
    subAccounts: "Underkonti",
    submitFeedback: "Indsend Feedback",
    createSubAccount: "Opret underkonto",
    viewScheduleReports: "Se planlagte rapporter",
    searchSubAccount: "Søg efter underkonto",
    columns: "Kolonner",
    sortBy: "Sorter efter",
    selectColumns: "Vælg kolonner",
    maxColumns: "Højst 8 kolonner ad gangen",
    applyColumns: "Anvend kolonner",
    activeUsers: "Aktive brugere",
    appointments: "Aftaler",
    contacts: "Kontakter",
    emails: "E-mails",
    textMessages: "Tekstbeskeder",
    calls: "Kald",
    submissions: "Indsendelser",
    reviews: "Anmeldelser",
    sitesCreated: "Oprettede websteder",
    name: "Navn",
    formSubmissions: "Formindsendelser",
    surveySubmissions: "Undersøgelsesindsendelser",
    facebookFormSubmissions: "Facebook formindsendelser",
    funnels: "Tragte",
    websites: "Hjemmesider",
    forms: "Skemaer",
    surveys: "Undersøgelser",
    highestFirst: "Højest først",
    lowestFirst: "Laveste først",
    orderBy: "Sortér efter",
    noMoreLocations: "Ingen flere steder fundet",
    preparingReport: "Vi er i gang med at forberede din rapport",
    preparingReportDesc: "Luk eller genindlæs ikke dette vindue, mens eksporten er i gang",
    outOf: "ud af",
    subAccountsExported: "Underkonti eksporteret",
    close: "Tæt",
    scheduledForDeletion: "Planlagt til sletning",
    readyToSell: "Klar til at sælge",
    closedWon: "Lukket (Vundet)",
    wpSaleSuccessful: "Salget af WordPress er vellykket",
    wpNotSold: "WordPress er endnu ikke solgt",
    yxtSaleSuccessful: "Yext salg er vellykket",
    yxtNotSold: "Yext ikke solgt endnu",
    saasSubInactive: "SaaS-abonnementet er inaktivt",
    saasSubTrialing: "SaaS-abonnement testes",
    saasSubActive: "SaaS-abonnement er aktivt",
    emailRebillingActive: "Email rebilling er aktiv",
    emailRebillingInactive: "Email-afregning er inaktiv",
    twilioRebillingActive: "Twilio-rengøring er aktiv",
    twilioRebillingInactive: "Twilio genfakturering er inaktiv",
    wpRebillingActive: "WhatsApp rebilling er aktiv",
    wpRebillingInactive: "WhatsApp rebilling er inaktiv",
    saasModeActivated: "SaaS-tilstand er aktiveret",
    paymentPending: "Betalingen er afventende",
    saasModeNotActivated: "SaaS-tilstand er ikke aktiveret",
    managedByLCPhone: "Administreret af LC Phone",
    migrationToLCPhone: "Under migration til LC Phone",
    notManagedByLCPhone: "Ikke administreret af LC Phone",
    managedByLCEmail: "Administreret af LC E-mail",
    notMangedByLCEmail: "Ikke håndteret af LC-email",
    remainingWalletBalance: "Resterende saldo på denne SaaS-placering",
    reports: "Rapporter",
    switchToSubAccount: "Skift til Underkonto",
    manageClient: "Administrer klient",
    subAccountDeleted: "Denne underkonto vil blive slettet den",
    subAccountDeletedInMinute: "Dette underkonto vil blive slettet om et minut",
    changedMind: "hvis du har forandret mening",
    clickHere: "Klik her",
    subAccountPaused:
      "Denne underkonto er sat på pause. Dine kunder kan ikke logge ind, men bureauet kan. For at genoptage denne konto",
    subAccountRestricted:
      "Denne underkonto er midlertidigt sat på pause på grund af afventende godkendelse",
    subAccountVerificationPending:
      "Denne underkonto er sat på pause på grund af afventende verificering af e-mail og telefon fra underkonto-administratoren",
    subAccountDeclined:
      "Denne underkonto er sat på pause på grund af afvist godkendelsesanmodning. Du kan stadig genoptage denne underkonto fra",
    here: "her",
    reviewAndResume: "at gennemgå og genoptage denne underkonto",
    incomingCalls: "Indgående opkald",
    outgoingCalls: "Udgående opkald",
    totalTalktime: "Samlet taletid",
    incomingEmails: "Indgående e-mails",
    outgoingEmails: "Udgående e-mails",
    incomingSms: "Indkomne tekster",
    outgoingSms: "Udgående sms-beskeder",
    contactsCreated: "Antal oprettede kontakter",
    appointmentsBooked: "Antal bookede aftaler",
    totalFormSubmissions: "Samlet antal formularindsendelser",
    totalSurveySubmissions: "Samlede undersøgelsesindsendelser",
    totalFacebookFormSubmissions: "Samlede Facebook-formularindsendelser",
    incomingGMBMessages: "Indgående GBP-beskeder",
    outgoingGMBMessages: "Udgående GBP-meddelelser",
    gmbMessages: "GBP beskeder",
    fbMessages: "Facebook Beskeder",
    incomingFbMessages: "Indgående Facebook beskeder",
    outgoingFbMessages: "Udgående Facebook-beskeder",
    igMessages: "Instagram Beskeder",
    incomingIgMessages: "Indkommende Instagram-beskeder",
    outgoingIgMessages: "Udgående Instagram-beskeder",
    totalReviews: "Totale anmeldelser",
    positiveReviews: "Positive anmeldelser",
    negativeReviews: "Negative anmeldelser",
    back: "Tilbage",
    selectDashboards: "Vælg instrumentbrætter",
    next: "Næste",
    backToSubAccounts: "Tilbage til underkonti",
    pauseSchedule: "Pauseplan",
    resumeSchedule: "Fortsæt tidsplan",
    editSchedule: "Rediger Tidsplan",
    addSchedule: "Tilføj tidsplan",
    selectReportToAdd: "Vælg rapporter, der skal tilføjes i e-mailen",
    selectAll: "Vælg alle",
    pauseScheduleConfirm: "Er du sikker på, at du vil sætte rapportplanen på pause?",
    resumeScheduleConfirm: "Er du sikker på, at du vil genoptage rapportplanen?",
    tasks: "Opgaver",
    conversations: "Samtaler",
    users: "Brugere",
    dealOverview: "Aftaleoversigt",
    confirmAction: "Bekræft handling",
    yes: "Ja",
    no: "Nej",
    reportDetails: "Rapportdetaljer",
    reportCreatedOn: "Rapport oprettet den",
    searchByEmail: "søg efter e-mail",
    lastActivity: "Seneste aktivitet",
    noRecords: "Ingen poster fundet",
    scheduleReport: "Tidsplan Rapport",
    schedule: "Tidsplan",
    save: "Gem",
    emailSubject: "Emne for e-mail",
    frequency: "Frekvens",
    daily: "daglig",
    weekly: "Ugentlig",
    monthly: "Månedlig",
    nextScheduleDate: "Næste planlagte dato",
    startingOn: "Begyndende fra",
    timeOfDay: "Tidspunkt på dagen",
    reportsToTimezone: "Rapporten sendes i undertiden for den underordnede konto",
    selectRecipients: "Vælg modtagere",
    invalidEmail: "Du har indtastet en ugyldig e-mailadresse",
    testEmail: "Test e-mail",
    selectTestRecipients: "Vælg testmodtagere",
    setupSubject: "Opstil emnet ovenfor for at teste rapporten",
    sendTest: "Send test",
    scheduleSimilarSchedules: "Planlæg en lignende rapport for andre underkonti",
    accountSummary: "Kontosammendrag",
    scheduleList: "Tidsplanliste",
    subAccountName: "Underkonto navn",
    nextRunDate: "Næste kørselsdato",
    reportSectionsAdded: "Rapportsektioner er tilføjet",
    status: "Status",
    scheduleAction: "Planlæg handling",
    cancel: "afbestille",
    pleaseWait: "Vent venligst",
    confirm: "Bekræft",
    booked: "Booked - Booket",
    confirmed: "Bekræftet",
    showed: "viste",
    noShow: "Ingen Show",
    cancelled: "aflyst",
    unread: "ub læste",
    engagement: "Engagement",
    reaction: "Reaktion",
    deliverySummary: "Leveringsresumé",
    successfulDelivery: "Vellykket levering",
    opened: "Åbnet",
    clicked: "Klikket",
    replied: "Svarede",
    unsubscribed: "Afmeldt",
    complained: "Klagede",
    failed: "Mislykkedes",
    open: "Åben",
    won: "Vandt",
    abandoned: "Forladt",
    lost: "Mistet",
    sent: "Sent",
    delivered: "Leveret",
    faceBookLeadForm: "Facebook-leadformular",
    overdue: "Forsinket",
    reviewsReceived: "Modtagne anmeldelser",
    formBuilders: "Form Byggere",
    switchToSaaS: "Skift til SaaS",
    numberOfContacts: "Antal kontakter",
    numberOfAppointments: "Antal aftaler",
    outreach: "Udvidelse",
    notSubAccountUser: "Ikke en underkontobrugere",
    searchSubAccName: "Søg efter underkontoens navn",
    recipients: "Modtagere",
    scheduleCreated: "E-mail rapportplan oprettet for",
    scheduleUpdated: "E-mailrapportplanen er opdateret for",
    scheduleNotCreated: "Emailrapportplan kunne ikke oprettes for",
    scheduleNotUpdated: "E-mailrapportplanen kunne ikke opdateres for",
    total: "Total",
    inbound: "Indgående",
    outbound: "Udgående",
    totalEmails: "Total e-mails",
    inboundEmails: "Indgående e-mails",
    outboundEmails: "Udgående e-mails",
    totalTextMessages: "Total tekstbeskeder",
    inboundTextMessages: "Indgående tekstbeskeder",
    outboundTextMessages: "Udgående SMS-beskeder",
    totalCalls: "Samtale i alt",
    inboundCalls: "Indgående opkald",
    outboundCalls: "Udgående opkald",
    faceBookLeadFormSubmissions: "Facebook Lead Form Indsendelser",
    inboundGMBMessages: "Indkommende GBP-beskeder",
    outboundGMBMessages: "Udgående GBP-beskeder",
    totalIgMessages: "Samlede Instagram-beskeder",
    inboundIgMessages: "Indgående Instagram-beskeder",
    outboundIgMessages: "Udgående Instagram-beskeder",
    totalfbMessages: "Total Facebook-beskeder",
    inboundfbMessages: "Indgående Facebook-beskeder",
    outboundfbMessages: "Udgående Facebook-beskeder",
    sitesCreatedFunnels: "Websites oprettet - Funnels",
    sitesCreatedWebsites: "Oprettede hjemmesider - Websites",
    sitesCreatedForms: "Websites oprettet - Formularer",
    sitesCreatedSurveys: "Sider oprettet - undersøgelser",
    googleReviews: "Google anmeldelser",
    facebookReviews: "Facebook anmeldelser",
    inboundCallDuration: "Indgående Opkaldsvarighed (timer)",
    outboundCallDuration: "Udgående Opkaldsvarighed (timer)",
    pleaseSelect: "Vælg venligst",
    subAccountIsDeleted: "Dette underkonto er blevet slettet",
    subAccountIsPaused: "Denne underkonto er blevet sat på pause",
    success: "Succes",
    error: "Fejl",
    testEmailSent: "Test email sendt",
    testEmailNotSent: "Testemail kunne ikke sendes",
    reviewRequest: "Gennemgangsanmodninger",
    pendingTransferDescription: "Andre agenturer har anmodet om at overføre underkonti til dig",
    pendingTransferHeading: "Du har ventende anmode om underkontooverførsler",
    subAccountTransfer: "Underkonto Overførsler",
    manageSubAccTransfer: "Administrer dine underkontooverførselsanmodninger",
    subAccRequest: "Underkonto {tabName} Anmodninger",
    receive: "Modtag",
    send: "Sende",
    release: "Udgivelse",
    requests: "{count} Anmodninger",
    subAccLimit: "Underkonti brugsgrænse nået!",
    subAccPlanLimit: "Du har brugt 3/3 under-konti tilladt under din nuværende plan",
    upgradeTime: "Det er tid til en opgradering!",
    upgradeHigher:
      "Vær venlig at opgradere til en højere plan for at acceptere overførslen af underkontoen",
    browse: "Gennemse planer",
    pendingTransferTitle:
      "En anmodning om overførsel for denne underkonto er allerede under behandling",
    unableTransfer: "Kan ikke overføre underkonto til dette agentur",
    hippaErrorDescription:
      "Underkontoen, som du forsøger at overføre, er HIPAA-aktiveret, og kan ikke overføres til en ikke-HIPAA-agentur",
    pendingTransferErrorDescription:
      "Venligst annuller den allerede eksisterende overførselsanmodning for denne underkonto, inden du fortsætter med at oprette en ny",
    receivingAgency: "Modtagerorgan",
    releasingAgency: "Frigivelsesagenturm",
    noOfContacts: "Antal kontakter",
    requestedDate: "Anmodet dato",
    action: "Handling",
    cancelTransfer: "Annuller overførsel",
    decline: "Afvise",
    cancelTransferRequest: "Annuller underkonto-overførselsanmodning?",
    cancelRequestDescription:
      "Modtagerorganet vil ikke længere se denne overførselsanmodning, og underkontoen {name} vil ikke blive overført",
    declineRequest: "Afvis underkonto-overførselsanmodning?",
    declineRequestDescription:
      "Anmodningen vil blive afvist, og underkontoen {name} vil ikke blive overført til dit bureau",
    approveTransfer: "Godkender du underkontooverførsel til dit agentur?",
    approveTransferDescription: "{name} underkonto vil blive overført til din agentur",
    transferNow: "Overfør Nu",
    cancelNow: "Annullér nu",
    transferErrorDetails: "Underkontoen, som du forsøger at overføre, kan ikke overføres",
    cancelRequest: "Afbestil anmodning",
    received: "Modtaget",
    approve: "Godkende",
    goBack: "Gå tilbage",
    done: "Færdig",
    subAccountTransferred: "Underkontoen er blevet succesfuldt overført!",
    subAccountTransferDescription:
      "Bemærk venligst, at selvom underkontoen er blevet overført, kan nogle aktiver som brugere, telefonnumre, Mailgun-konti, Yext-opslag osv. stadig tage 10-15 minutter at afspejle sig i destinationsstedet",
    activeSubscriptionsAlert:
      "Lokationen har aktive abonnementer. Annullér venligst alle abonnementer, før du overfører lokationen. Liste over aktive abonnementer: {subscriptions}",
    typeToSearch: "Skriv og søg",
    emailEngagement: "E-mail engagement",
    emailReaction: "Email Reaktion",
    emailDeliverySummary: "E-mail leveringsoversigt",
    facebook: "Facebook",
    sms: "SMS",
    instagram: "Instagram",
    accountSummarySubAccount: "Account Summary - {subAccountName}",
    test: "Test",
    scheduleExists: "Schedule Exists",
    aToZ: "A - Z",
    zToA: "Z - A",
    onlyAdminUsersCanAddNewSubAccounts: "Only Admin users can add new sub-account's.",
    locationName: "Location Name",
    subAccountDeletedIfChangedMind:
      "This Sub-Account will be deleted on {time}, if you've changed your mind",
    eject: "Udskub",
    unableEjectCancel: "Ikke i stand til at annullere anmodning om Sub-konto Eject",
    EjectErrorDetails:
      "Den Underkonto-udkastanmodning, som du forsøger at annullere, kan ikke annulleres",
    cancelEjectRequest: "Annuller anmodning om at frigive underkonto?",
    cancelEjectRequestDescription:
      "Den valgte bruger vil ikke længere kunne oprette et nyt agentur ud fra underkontoen {name}",
    transferCompleted:
      'Anmodningen om overførsel for denne placering er allerede behandlet. Opdater venligst.',
    unauthorizedTransfer:
      'Du har ikke tilladelse til at udføre denne handling. Kun ejere af agenturet kan udføre denne handling.',
  },
};
