import { translate } from "@/locales";
import moment from "moment";
import { computed } from "vue";

export function secondsToHms(seconds) {
  const d = Number(seconds);

  if (d <= 0) {
    return "0m";
  }
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? h + "hr " : "";
  const mDisplay = m + "m ";
  const sDisplay = s + "s ";

  return hDisplay + mDisplay + sDisplay;
}

export function companyInSaasPlan(company) {
  const saasPlans = ["agency_monthly_497", "agency_annual_497"];
  const companyPlan = company.stripe_active_plan;
  return company.is_enterprise || saasPlans.includes(companyPlan);
}

export function formatDateString(dtString) {
  const date = new Date(dtString);
  const dateStr = new Intl.DateTimeFormat("en-US", {
    month: "short",
    year: "numeric",
    day: "numeric",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }).format(date);
  const timeStr = new Intl.DateTimeFormat("en-US", {
    hour12: true,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeStyle: "full",
  }).format(date);
  return dateStr + " " + timeStr;
}

const _getDateRangeShortcuts = computed(() => ({
  [`${translate("common.thisWeek")}`]: [
    moment().startOf("week").startOf("d")
.valueOf(),
    moment().startOf("d").valueOf(),
  ],
  [`${translate("common.lastWeek")}`]: [
    moment().subtract(1, "w").startOf("week").startOf("d").valueOf(),
    moment().subtract(1, "w").endOf("week").startOf("d").valueOf(),
  ],
  [`${translate("common.lastNumberOfDays", { numberOfDays: 7 })}`]: [
    moment().subtract(1, "w").startOf("d").valueOf(),
    moment().subtract(1, "d").startOf("d").valueOf(),
  ],
  [`${translate("common.thisMonth")}`]: [
    moment().startOf("month").startOf("d")
.valueOf(),
    moment().startOf("d").valueOf(),
  ],
}));

if (moment("2022-10-01").isAfter(moment())) {
  _getDateRangeShortcuts.value[`${translate("common.lastNumberOfDays", { numberOfDays: 30 })}`] = [
    moment().subtract(30, "days").startOf("d").valueOf(),
    moment().startOf("d").valueOf(),
  ];
}

export const getDateRangeShortcuts = _getDateRangeShortcuts;

export const isDateDisabled = (ts: number) => {
  return moment("2022-10-01").isAfter(moment(ts));
};

export const reportNumbers = (value) => {
  if (!value) {
    value = 0;
  }
  const locale = "US";
  return Number(value).toLocaleString(locale, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
};

export const compactNumbers = (num = 0) => {
  return Intl.NumberFormat("en", { notation: "compact" }).format(num);
};

export const emailChartOptions = () => {
  return {
    grid: {
      top: 20,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      bottom: 10,
      data: [],
    },
    xAxis: [
      {
        type: "category",
        axisTick: { show: false },
        data: [],
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    series: [],
  };
};
