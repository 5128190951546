import { compactNumbers, reportNumbers } from "@/helpers";
import "@gohighlevel/ghl-ui/dist/style.css";
import * as Sentry from "@sentry/vue";
import { setupSentry } from "common/util/setupSentry";
import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./App.vue";
import { i18n } from "./locales";
import { router } from "./router";
import "./style.css";

const app = createApp(App);
setupSentry(Sentry, app, router, process.env.NODE_ENV as string);
app.use(createPinia());
app.use(i18n);
app.use(router);
app.mount("#app");

app.config.globalProperties.$filters = {
  reportNumbers,
  compactNumbers,
};
