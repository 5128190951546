import Postmate from "postmate";
import { defineStore } from "pinia";
import { useCompanyStore } from "./company";
import config from "@/config";

export interface AppStore {
  locationId: string;
  locationName: string;
  userEmail: string;
  userId: string;
  token: string;
  appHandshake: Postmate.Model | undefined;
  authorization: string;
  companyId: string;
  timezone: string;
  appType: "STANDALONE" | "EMBED";
  requestRetryCount: number;
  company: { [key: string]: string | boolean } | null;
  mode: string;
  user: { [key: string]: string | boolean } | null;
}

export const useAppStore = defineStore("app", {
  state: (): AppStore => ({
    locationId: (import.meta.env.VITE_LOCATION_ID as string) || "",
    locationName: "",
    userId: (import.meta.env.VITE_USER_ID as string) || "",
    userEmail: (import.meta.env.VITE_USER_EMAIL as string) || "",
    token: (import.meta.env.VITE_TOKEN_ID as string) || "",
    appType: import.meta.env.VITE_APP_TYPE || "STANDALONE",
    companyId: (import.meta.env.VITE_COMPANY_ID as string) || "",
    timezone: "",
    appHandshake: undefined,
    authorization: "",
    requestRetryCount: 0,
    company: null,
    user: {},
    mode: config.mode,
  }),
  actions: {
    updateRequestRetryCount(count: number) {
      this.requestRetryCount = count;
    },
    initialize(payload: AppStore) {
      this.locationId = payload.locationId;
      this.userId = payload.userId;
      this.userEmail = payload.userEmail;
      this.token = payload.token;
      this.companyId = payload.companyId;
      this.authorization = payload.authorization;
      this.timezone = payload.timezone;
      this.appType = payload.appType;
      this.company = payload.company;
      this.user = payload.user;
      const companyStore = useCompanyStore();
      companyStore.init(payload.companyId);
      companyStore.setCompanyData(payload.company);
    },
    refreshToken(newToken: string) {
      this.token = newToken;
    },
    refreshAuthorizationToken(newToken: string) {
      this.authorization = newToken;
    },
    abortDeletion(data: object) {
      // console.log(data);
    },
    setAppHandshake(payload: any) {
      // console.log("setAppHandshake", payload);
      this.appHandshake = payload;
    },
  },
});
