<template>
  <div id="pg-agency-location-reporting" class="container-height pl-8 pr-8">
    <div class="mt-11 px-3">
      <UIButton
        id="pg-agency-location-reporting__btn-back-to-account"
        type="default"
        class="font-bold"
        @click.prevent="backToAccount"
      >
        <UITextSmMedium
          class="inline-flex cursor-pointer content-center items-center font-bold text-blue-500"
        >
          <ArrowLeftIcon class="mr-3 h-5 w-5" />
          {{ $t("backToSubAccounts") }}
        </UITextSmMedium>
      </UIButton>

      <div v-if="!isLoading" class="mb-4 mt-6 flex justify-between">
        <UIDisplaySmMedium> {{ company.name }} - {{ $t("scheduleList") }} </UIDisplaySmMedium>
      </div>
    </div>

    <div v-if="isLoading" class="mt-16 flex content-center items-center justify-center text-center">
      <UISpin id="ui-spinner" :show="true" size="large"></UISpin>
    </div>

    <UITable
      v-if="
        !isLoading &&
        (table.data.length > 0 || (table.data.length === 0 && table.search.text.length !== 0))
      "
      id="schedule-table"
      v-model:searchText="table.search.text"
      class="mt-16 flex content-center items-center justify-center text-center"
      :columns="table.tableHeading"
      :data="table.computedData"
      :page-count="table.configs.pages"
      :enable-text-search="true"
      :enable-filter="false"
      :search-input-placeholder="$t('typeToSearch')"
      :loading="table.isLoading"
      :page="table.configs.page"
      table-layout="fixed"
      @update:page="pageChange"
    >
    </UITable>
    <UICard
      v-else-if="!isLoading && table.data.length === 0 && table.search.text.length === 0"
      class="mt-16 flex content-center items-center justify-center text-center"
    >
      <UITextMdMedium class="text-center"> {{ $t("noRecords") }} </UITextMdMedium>
    </UICard>
  </div>
  <Modal
    :show-modal="modal.isVisible"
    :data="modal.data"
    :btn-disable="action.spinner || action.disable"
    :spinner="action.spinner"
    @close-modal="toggleModal"
    @submit="confirmModal"
  >
    <template #content>
      <div class="flex flex-row items-center gap-2">
        <p class="inline-block">
          {{ modalMessage }}
        </p>
      </div>
    </template>
  </Modal>
</template>

<script>
import moment from "moment";
import {
  UIButton,
  UITextSmMedium,
  UIDisplaySmMedium,
  UITextMdMedium,
  UICard,
  UITable,
  UITooltip,
  UISpin,
} from "@gohighlevel/ghl-ui";
import { ArrowLeftIcon, PauseCircleIcon, PlayCircleIcon } from "@gohighlevel/ghl-icons/24/outline";
import { useCompanyStore } from "@/store/company";
import { ReportingService } from "../../services/ReportingService.ts";
import { LocationService } from "../../services/LocationService.ts";
import { useAppStore } from "@/store/app";
import { h } from "vue";
import Modal from "./helpers/modal/Modal.vue";
import OfflineStatusSVG from "@/assets/offline_status.svg";
import OnlineStatusSVG from "@/assets/online_status.svg";

export default {
  components: {
    UIButton,
    ArrowLeftIcon,
    UITextSmMedium,
    UIDisplaySmMedium,
    UITextMdMedium,
    UICard,
    UITable,
    PauseCircleIcon,
    PlayCircleIcon,
    Modal,
    UITooltip,
    UISpin,
  },
  props: [],
  emits: [],
  data() {
    return {
      company: {
        id: "",
        name: "",
      },
      table: {
        isLoading: false,
        tableHeading: [
          { title: this.$t("subAccountName"), key: "subAccName", align: "center" },
          { title: this.$t("emailSubject"), key: "emailSubject", align: "center" },
          { title: this.$t("frequency"), key: "frequency", align: "center" },
          { title: this.$t("nextScheduleDate"), key: "nextRunDate", align: "center" },
          { title: this.$t("recipients"), key: "recipients", align: "center" },
          {
            title: this.$t("reportSectionsAdded"),
            key: "reportSections",
            align: "center",
          },
          {
            title: this.$t("status"),
            key: "status",
            align: "center",
            render: (elem, index) => {
              return h(UITooltip, null, {
                default: () =>
                  elem.locationStatus === "deleted"
                    ? this.$t("subAccountIsDeleted")
                    : elem.locationStatus === "inactive"
                    ? this.$t("subAccountIsPaused")
                    : elem.status === "published"
                    ? this.$t("pauseSchedule")
                    : this.$t("resumeSchedule"),
                trigger: () => {
                  return this.action.spinner
                    ? this.action.schedule.id === elem.id
                      ? h(UISpin, { size: "small" })
                      : elem.status === "published"
                      ? h(OfflineStatusSVG, {
                          class: "h-5 w-5 cursor-pointer pg-agency-locations__btn--pause",

                          onClick: async () => {
                            this.action.schedule = elem;
                            await this.toggleHandler();
                          },
                        })
                      : h(OnlineStatusSVG, {
                          class: "h-5 w-5 cursor-pointer pg-agency-locations__btn--resume",
                          onClick: async () => {
                            this.action.schedule = elem;
                            await this.toggleHandler();
                          },
                        })
                    : elem.status === "published"
                    ? h(OfflineStatusSVG, {
                        class: "h-5 w-5 cursor-pointer pg-agency-locations__btn--pause",
                        onClick: async () => {
                          this.action.schedule = elem;
                          await this.toggleHandler();
                        },
                      })
                    : h(OnlineStatusSVG, {
                        class: "h-5 w-5 cursor-pointer pg-agency-locations__btn--resume",
                        onClick: async () => {
                          this.action.schedule = elem;
                          await this.toggleHandler();
                        },
                      });
                  // return h(
                  //   UIButton,
                  //   {
                  //     id: `schedule-pause-btn-${index}`,
                  //     type: "default",
                  //     loading: this.action.spinner && this.action.schedule.id === elem.id,
                  //     disabled: this.action.schedule !== null,
                  //     onClick: async () => {
                  //       this.action.schedule = elem;
                  //       await this.toggleHandler();
                  //     },
                  //   },
                  //   {
                  //     default: () => {
                  //       return this.action.spinner
                  //         ? this.action.schedule.id === elem.id
                  //           ? null
                  //           : elem.status === "published"
                  //           ? h(PauseCircleIcon, {
                  //               class: "h-5 w-5 pg-agency-locations__btn--pause",
                  //             })
                  //           : h(PlayCircleIcon, {
                  //               class: "h-5 w-5 pg-agency-locations__btn--resume",
                  //             })
                  //         : elem.status === "published"
                  //         ? h(PauseCircleIcon, { class: "h-5 w-5 pg-agency-locations__btn--pause" })
                  //         : h(PlayCircleIcon, {
                  //             class: "h-5 w-5 pg-agency-locations__btn--resume",
                  //           });
                  //     },
                  //   }
                  // );
                },
              });
            },
          },
        ],
        data: [],
        computedData: [],
        search: { text: "", timer: null },
        configs: {
          page: 1,
          limit: 10,
          pages: 1,
        },
      },
      modal: {
        isVisible: false,
        data: {
          propTitle: this.$t("scheduleAction"),
          buttons: {
            secondary: this.$t("cancel"),
          },
        },
      },
      action: {
        schedule: null,
        spinner: false,
        disable: false,
      },
      isLoading: true,
    };
  },
  computed: {
    modalMessage() {
      if (this.action.schedule && this.action.schedule.locationStatus === "deleted") {
        return this.$t("subAccountIsDeleted");
      } else if (this.action.schedule && this.action.schedule.locationStatus === "inactive") {
        return this.$t("subAccountIsPaused");
      } else if (this.action.schedule) {
        return this.modal.isVisible && this.action.schedule.status === "published"
          ? this.$t("pauseScheduleConfirm")
          : this.$t("resumeScheduleConfirm");
      }
      return "";
    },
  },
  watch: {
    "table.search.text"(newVal) {
      // starts searching the text after 0.5 sec
      if (this.table.search.timer) {
        clearTimeout(this.table.search.timer);
      }
      if (this.table.data.length === 0 && newVal.length === 0) {
        this.isLoading = true;
      }

      this.table.search.timer = setTimeout(
        async (len, newVal) => {
          await this.fetchData();
          if (len === 0 && newVal.length === 0) {
            this.isLoading = false;
          }
        },
        500,
        this.table.data.length,
        newVal
      );
    },
  },
  async mounted() {
    this.fetchInitialData();
  },
  methods: {
    backToAccount() {
      const appStore = useAppStore();
      const path = `/sub-accounts`;
      if (appStore.appHandshake) {
        appStore.appHandshake.then((parent) => {
          parent.emit("route-change", {
            path,
          });
        });
      }

      this.$router.push(path);
    },
    loadCompany() {
      if (!this.isAdmin()) {
        this.backToAccount();
      }
      const companyStore = useCompanyStore();
      if (!Object.keys(companyStore.companyData).length) {
        return setTimeout(this.loadCompany, 1000);
      }
      this.company.id = companyStore.companyData.id;
      this.company.name = companyStore.companyData.name;
    },
    async fetchData() {
      this.table.isLoading = true;
      const paramsObject = {
        page: this.table.configs.page,
        limit: this.table.configs.limit,
        search: this.table.search.text ? this.table.search.text : null,
      };
      const { data } = await ReportingService.getSchedulesList(paramsObject);
      this.table.configs.pages = Math.ceil(data.totalCount / this.table.configs.limit);
      await this.populateTable(data.data);
      this.table.isLoading = false;
    },
    async populateTable(data) {
      this.table.data = [];
      if (data) {
        for (const item of data) {
          this.table.data.push({
            id: item._id,
            subAccName: item.locationName,
            locationId: item.locationId,
            emailSubject: item.subject,
            frequency: this.$t(item.frequency),
            nextRunDate: moment(item.nextRunTime).tz(item.locationTimezone).format("lll"),
            recipients: item.recipients.join(", "),
            reportSections: this.insightsListModify(item.insights),
            status: item.status,
            locationStatus: item.deleted
              ? "deleted"
              : item.status !== "published"
              ? await this.getLocationStatus(item.locationId)
              : "active",
          });
        }
        this.table.computedData = this.table.data;
      }
    },
    async toggleHandler() {
      try {
        const status = await this.getLocationStatus(this.action.schedule.locationId);
        switch (status) {
          case "deleted":
            this.action.schedule.locationStatus = "deleted";
            this.action.disable = true;
            break;
          case "inactive":
            this.action.schedule.locationStatus = "inactive";
            this.action.disable = true;
            break;
          default:
            this.action.schedule.locationStatus = "active";
            this.action.disable = false;
            break;
        }
      } catch (e) {
        this.action.schedule.locationStatus = "deleted";
        this.action.disable = true;
      }
      this.toggleModal("open");
    },
    toggleModal(action) {
      // to toggle modal visibility
      this.modal.isVisible = !this.modal.isVisible;
      if (!action) {
        this.action.schedule = null;
      }
    },
    async confirmModal() {
      await this.pauseResumeSchedule();
      this.toggleModal();
    },
    async pauseResumeSchedule() {
      this.action.spinner = true;
      const id = this.action.schedule.id;
      await ReportingService.updateScheduleStatus(
        id,
        {
          status: this.action.schedule.status === "published" ? "draft" : "published",
        },
        { locationId: this.action.schedule.locationId }
      );

      await this.fetchData();
      this.action.spinner = false;
    },
    async pageChange(val) {
      this.table.configs.page = val;
      await this.fetchData();
    },
    async getLocationStatus(locationId) {
      const {
        data: { location },
      } = await LocationService.getById(this.company.id, locationId);
      return location.deleted ? "deleted" : location.suspendedInfo?.paused ? "inactive" : "active";
    },
    insightsListModify(insights) {
      // capitalize
      // split
      // join
      return insights
        .map((insight) => {
          switch (insight) {
            case "tasks":
              return this.$t("tasks");
            case "conversations":
              return this.$t("conversations");
            case "users":
              return this.$t("users");
            case "contacts":
              return this.$t("contacts");
            case "outreach":
              return this.$t("outreach");
            case "submissions":
              return this.$t("submissions");
            case "sms":
              return "SMS";
            case "appointments":
              return this.$t("appointments");
            case "opportunities":
              return this.$t("dealOverview");
            case "email_engagement":
              return `${this.$t("emailEngagement")}`;
            case "email_reactions":
              return `${this.$t("emailReaction")}`;
            case "email_delivery":
              return `${this.$t("emailDeliverySummary")}`;
            default:
              return (insight.charAt(0).toUpperCase() + insight.slice(1)).replace("_", " ");
          }
        })
        .join(", ");
    },
    isAdmin() {
      const appStore = useAppStore();
      if (appStore && appStore.user?.role === "admin" && appStore.user?.type === "agency") {
        return true;
      }
      return false;
    },
    async fetchInitialData() {
      const appStore = useAppStore();
      if (!appStore.companyId) {
        return setTimeout(this.fetchInitialData, 1000);
      }
      this.loadCompany();
      await this.fetchData();
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.container-height {
  min-height: calc(100vh - 90px);
}
</style>