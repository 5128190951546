import { GetUsers, User } from "@/types/User";

import config from "@/config";
import { requests } from ".";

export const UserService = {
  getUsers: () => requests(config.REST_API_URLS).get<{ users: User[] }>("/todos"),
  // Override URLS
  getUserById: (params: GetUsers) =>
    requests("https://jsonplaceholder.typicode.com").get<{ user: User }>(`/todos/${params.id}`),

  getUsersList: (params: {
    companyId: string,
    locationId: string,
    type: string,
    role: string
  }) => requests().get('/users/search', { params })
};
