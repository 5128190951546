<template>
  <UICard class="rollup__charts-card h-full" :class="{ 'select-card': selection && selectMode }">
    <template #header>
      <div class="flex flex-row items-center justify-between">
        <div
          class="chart-draggble-handler flex items-center"
          :class="{ 'cursor-pointer': isDraggble }"
        >
          <Menu05Icon v-if="isDraggble" class="mr-2 h-4 w-4" />
          <UITextLgMedium>{{ chartTitle }}</UITextLgMedium>
        </div>
        <div>
          <UICheckbox
            v-show="selectMode"
            id="checkbox"
            v-model:checked="selection"
            @update:checked="insightSelect"
          ></UICheckbox>
        </div>
      </div>
    </template>

    <div class="flex">
      <div v-for="label in labels" :key="label.label" class="mr-20">
        <UITextSmMedium class="text-gray-500">{{ label.label }}</UITextSmMedium>
        <UITooltip :id="'loc-' + chartTitle + '-' + label.label + '__stat_title'">
          <template #trigger>
            <UITextLgMedium class="label-value">{{
              $filters.compactNumbers(label.count || 0)
            }}</UITextLgMedium>
          </template>
          {{ $filters.reportNumbers(label.count || 0) }}
        </UITooltip>
      </div>
    </div>

    <div v-show="isLoading" class="flex h-full w-full items-center justify-center">
      <UISpin :show="true"></UISpin>
    </div>
    <div v-show="!isLoading">
      <v-chart class="chart" :option="option" autoresize />
    </div>
  </UICard>
</template>

<script lang="ts">
import {
  UICard,
  UITextLgMedium,
  UITextSmMedium,
  UISpin,
  UITooltip,
  UICheckbox,
} from "@gohighlevel/ghl-ui";
import { Menu05Icon } from "@gohighlevel/ghl-icons/24/outline";
import VChart, { THEME_KEY } from "vue-echarts";
import { graphic } from "echarts/core";
import { ReportingService } from "@/services/ReportingService";
import { useAppStore } from "@/store/app";
import moment from "moment";
import { sumBy } from "lodash";

export default {
  components: {
    VChart,
    UISpin,
    UICard,
    UITextLgMedium,
    UITextSmMedium,
    UITooltip,
    Menu05Icon,
    UICheckbox,
  },
  provide: {
    [THEME_KEY]: "shine",
  },
  props: {
    isDraggble: {
      type: Boolean,
    },
    type: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: true,
    },
    chartTitle: {
      type: String,
      required: true,
    },
    selectMode: {
      type: Boolean,
      required: true,
    },
    checkedInsights: {
      type: Array,
      required: true,
    },
  },
  emits: ["insightSelectEvent"],
  data() {
    return {
      isLoading: false,
      selection: false,
      labels: [] as { label: string; count: number; color?: string }[],
      option: {
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [],
            type: "line",
            name: this.$t("total"),
            itemStyle: {
              color: "#2970FF",
            },
            areaStyle: {
              color: new graphic.LinearGradient(0, 0, 9, 1, [
                {
                  offset: 0,
                  color: "#fff",
                },
                {
                  offset: 1,
                  color: "#2970FF",
                },
              ]),
            },
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        // legend: {
        //   data: ["Inbound", "Outbound"],
        // },
      },
    };
  },
  watch: {
    $route() {
      this.loadData();
    },
    checkedInsights() {
      this.fillCheckBox();
    },
  },
  mounted() {
    this.loadData();
    this.fillCheckBox();
  },
  methods: {
    async loadData() {
      const start = new Date(this.$route.query.start).getTime();
      const end = new Date(this.$route.query.end).getTime();
      if (!this.type || !start || !end) {
        return;
      }

      this.isLoading = true;
      this.option.xAxis.data = [];
      this.option.series[0].data = [];
      this.option.series.splice(1);
      this.labels = [];

      const appStore = useAppStore();

      const { data } = await ReportingService.getGraphData({
        locationId: appStore.locationId || (this.$route.params.locationId as string),
        companyId: appStore.companyId,
        type: this.type,
        start: this.$route.query.start as string,
        end: this.$route.query.end as string,
      });

      let totalCount = sumBy(data, "total");

      if (!data) {
        this.option.xAxis.data = [];
        this.option.series[0].data = [];
        totalCount = 0;
      } else {
        this.option.series[0].data = data.map((x) => x.total);
        this.option.xAxis.data = data.map((x) => x.date);
      }

      this.labels.push({
        label: this.title,
        count: totalCount,
      });

      this.isLoading = false;
    },
    insightSelect(val: boolean) {
      this.$emit("insightSelectEvent", { chart: this.type, value: val });
    },
    fillCheckBox() {
      if (this.type === "contact") {
        this.selection = this.checkedInsights.includes("contacts", 0);
      }
    },
  },
};
</script>
<style scoped>
.label-value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 38px;
}

.select-card {
  background-color: #eff4ff;
  border: 2px solid #155eef;
}
</style>
