interface SetupPendo {
  companyId: string;
  locationId: string;
  userId: string;
  user: {
    id: string;
    type: string;
    role: string;
  }
}

declare global {
  interface Window {
    pendo: any;
  }
}

export function setupPendo(params: SetupPendo) {
  if (params.companyId) {
    const initOptions = {
      visitor: {
        id: params.user?.id,
      },
      parentAccount: {
        id: `${params.companyId}`
      },
    } as any;

    if (params.locationId) {
      initOptions.account = {
        id: `${params.companyId}::${params.locationId}`
      };
    }

    window.pendo && window.pendo.initialize(initOptions);
  }
}

export const emitPendoEvent = (eventName: string, eventProperties: { [key: string]: string }) => {
  if (process.env.NODE_ENV === "production") {
    if (window.pendo) {
      window.pendo.track(eventName, eventProperties);
    }
  } else {
    console.table({
      "PENDO Object": "[only in dev mode]",
      eventName,
      ...eventProperties,
    });
  }
};
