export default {
  pt_BR: {
    common: {
      thisWeek: "Esta semana",
      lastWeek: "Semana passada",
      lastNumberOfDays: "Últimos {numberOfDays} dias",
      thisMonth: "Este mês",
    },
    subAccounts: "Subcontas",
    submitFeedback: "Enviar comentários",
    createSubAccount: "Criar Subconta",
    viewScheduleReports: "Ver Relatórios Agendados",
    searchSubAccount: "Pesquisar por subconta",
    columns: "Colunas",
    sortBy: "Ordenar por",
    selectColumns: "Selecionar colunas",
    maxColumns: "Máximo de 8 colunas por vez",
    applyColumns: "Aplicar Colunas",
    activeUsers: "Usuários ativos",
    appointments: "Consultas",
    contacts: "Contatos",
    emails: "Emails",
    textMessages: "Mensagens de texto",
    calls: "Chamadas",
    submissions: "Envios",
    reviews: "Avaliações",
    sitesCreated: "Sites Criados",
    name: "Nome",
    formSubmissions: "Envio de formulários",
    surveySubmissions: "Envio de Pesquisas",
    facebookFormSubmissions: "Envios de formulários do Facebook",
    funnels: "Funis",
    websites: "Websites",
    forms: "Formulários",
    surveys: "Pesquisas",
    highestFirst: "Mais alto primeiro",
    lowestFirst: "Mais baixa primeiro",
    orderBy: "Ordenar por",
    noMoreLocations: "Nenhum local encontrado",
    preparingReport: "Estamos preparando o seu relatório",
    preparingReportDesc:
      "Não feche ou recarregue esta janela enquanto a exportação estiver em andamento",
    outOf: "fora de",
    subAccountsExported: "subcontas exportadas",
    close: "fechar",
    scheduledForDeletion: "Programado para exclusão",
    readyToSell: "Pronto para vender",
    closedWon: "Fechado (Ganho)",
    wpSaleSuccessful: "A venda do WordPress foi bem-sucedida",
    wpNotSold: "WordPress ainda não foi vendido",
    yxtSaleSuccessful: "A venda do Yext foi bem-sucedida",
    yxtNotSold: "Ainda não vendido pela Yext",
    saasSubInactive: "A assinatura do SaaS está inativa",
    saasSubTrialing: "A assinatura SaaS está em fase de teste",
    saasSubActive: "A assinatura de SaaS está ativa",
    emailRebillingActive: "O reembolso por e-mail está ativo",
    emailRebillingInactive: "Faturamento por email está inativo",
    twilioRebillingActive: "A cobrança recorrente do Twilio está ativa",
    twilioRebillingInactive: "A cobrança recorrente do Twilio está inativa",
    wpRebillingActive: "A fatura do WhatsApp está ativa",
    wpRebillingInactive: "A cobrança recorrente do WhatsApp está inativa",
    saasModeActivated: "O modo SaaS está ativado",
    paymentPending: "Pagamento pendente",
    saasModeNotActivated: "O modo SaaS não está ativado",
    managedByLCPhone: "Gerenciado por LC Phone",
    migrationToLCPhone: "Sob migração para LC Phone",
    notManagedByLCPhone: "Não gerenciado pela LC Phone",
    managedByLCEmail: "Gerenciado por LC Email",
    notMangedByLCEmail: "Não gerenciado pelo Email LC",
    remainingWalletBalance: "Saldo restante da carteira deste local SaaS",
    reports: "Relatórios",
    switchToSubAccount: "Mudar para Subconta",
    manageClient: "Gerenciar cliente",
    subAccountDeleted: "Esta subconta será excluída em",
    subAccountDeletedInMinute: "Esta subconta será excluída em um minuto",
    changedMind: "se você mudou de ideia",
    clickHere: "Clique aqui",
    subAccountPaused:
      "Esta subconta está pausada. Seus clientes não podem fazer login, mas a agência pode. Para retomar esta conta",
    subAccountRestricted: "Esta subconta está pausada devido a uma aprovação pendente",
    subAccountVerificationPending:
      "Esta subconta está pausada devido à verificação pendente de e-mail e telefone pelo administrador da subconta",
    subAccountDeclined:
      "Esta subconta está pausada devido à solicitação de aprovação recusada. Você ainda pode retomar esta subconta a partir de",
    here: "aqui",
    reviewAndResume: "revisar e retomar esta subconta",
    incomingCalls: "Chamadas recebidas",
    outgoingCalls: "Chamadas realizadas",
    totalTalktime: "Tempo total de conversação",
    incomingEmails: "E-mails recebidos",
    outgoingEmails: "Emails enviados",
    incomingSms: "Mensagens de texto recebidas",
    outgoingSms: "Mensagens de texto enviadas",
    contactsCreated: "Número de contatos criados",
    appointmentsBooked: "Número de compromissos agendados",
    totalFormSubmissions: "Total de envios de formulários",
    totalSurveySubmissions: "Total de envios de pesquisa",
    totalFacebookFormSubmissions: "Total de envios de formulários do Facebook",
    incomingGMBMessages: "Mensagens GBP Recebidas",
    outgoingGMBMessages: "Mensagens de saída em GBP",
    gmbMessages: "GBP Mensagens",
    fbMessages: "Mensagens do Facebook",
    incomingFbMessages: "Mensagens do Facebook recebidas",
    outgoingFbMessages: "Mensagens enviadas no Facebook",
    igMessages: "Mensagens do Instagram",
    incomingIgMessages: "Mensagens do Instagram recebidas",
    outgoingIgMessages: "Mensagens enviadas do Instagram",
    totalReviews: "Avaliações totais",
    positiveReviews: "Avaliações positivas",
    negativeReviews: "Avaliações negativas",
    back: "De volta",
    selectDashboards: "Selecionar painéis de controle",
    next: "Próximo",
    backToSubAccounts: "Voltar para Subcontas",
    pauseSchedule: "Pausar agenda",
    resumeSchedule: "Retomar cronograma",
    editSchedule: "Editar Agenda",
    addSchedule: "Adicionar horário",
    selectReportToAdd: "Selecionar relatórios para adicionar ao email",
    selectAll: "Selecionar tudo",
    pauseScheduleConfirm: "Você tem certeza de que quer pausar a programação do relatório?",
    resumeScheduleConfirm: "Você tem certeza de que deseja retomar o cronograma de relatórios?",
    tasks: "Tarefas",
    conversations: "Conversas",
    users: "Usuários",
    dealOverview: "Visão geral do acordo",
    confirmAction: "Confirmar Ação",
    yes: "Sim",
    no: "Não",
    reportDetails: "Detalhes do relatório",
    reportCreatedOn: "Relatório criado em",
    searchByEmail: "Procurar por email",
    lastActivity: "Última atividade",
    noRecords: "Nenhum registro encontrado",
    scheduleReport: "Agendar relatório",
    schedule: "Agenda",
    save: "salvar",
    emailSubject: "Assunto do email",
    frequency: "Frequência",
    daily: "Diário",
    weekly: "Semanal",
    monthly: "Mensal",
    nextScheduleDate: "Próxima data da agenda",
    startingOn: "Começando em",
    timeOfDay: "Hora do dia",
    reportsToTimezone: "O relatório será enviado no fuso horário da subconta",
    selectRecipients: "Selecionar destinatários",
    invalidEmail: "Você digitou um endereço de email inválido",
    testEmail: "Email de teste",
    selectTestRecipients: "Selecione os destinatários do teste",
    setupSubject: "Configure o assunto acima para testar o relatório",
    sendTest: "Enviar teste",
    scheduleSimilarSchedules: "Agende um relatório semelhante para outras subcontas",
    accountSummary: "Resumo da conta",
    scheduleList: "Lista de horários",
    subAccountName: "Nome da subconta",
    nextRunDate: "Próxima Data de Execução",
    reportSectionsAdded: "Seções de relatório adicionadas",
    status: "Estado",
    scheduleAction: "Ação Agendada",
    cancel: "Cancelar",
    pleaseWait: "Por favor, aguarde",
    confirm: "Confirmar",
    booked: "Reservado",
    confirmed: "Confirmado",
    showed: "Mostrou",
    noShow: "Não compareceu",
    cancelled: "Cancelado",
    unread: "Não lido",
    engagement: "Compromisso ",
    reaction: "Reação",
    deliverySummary: "Resumo de entrega",
    successfulDelivery: "Entrega bem-sucedida",
    opened: "Aberto",
    clicked: "Clicado",
    replied: "Respondeu",
    unsubscribed: "Desinscrito",
    complained: "reclamou",
    failed: "Fracassado",
    open: "Aberto",
    won: "Ganhou",
    abandoned: "Abandonado",
    lost: "Perdido",
    sent: "Sent: Enviado",
    delivered: "Entregue",
    faceBookLeadForm: "Formulário de Geração de Leads do Facebook",
    overdue: "Atrasado",
    reviewsReceived: "Análises recebidas",
    formBuilders: "Construtores de Formulários",
    switchToSaaS: "Mudar para SaaS",
    numberOfContacts: "Número de Contatos",
    numberOfAppointments: "Número de Agendamentos",
    outreach: "Engajamento",
    notSubAccountUser: "Não é um usuário de subconta",
    searchSubAccName: "Pesquisar pelo nome da subconta",
    recipients: "Beneficiários",
    scheduleCreated: "Programa de envio de relatório de e-mail criado para",
    scheduleUpdated: "Agendamento de relatório por e-mail atualizado para",
    scheduleNotCreated: "Não foi possível criar o agendamento de relatório por e-mail para",
    scheduleNotUpdated:
      "O agendamento de envio de relatório por e-mail não pôde ser atualizado para",
    total: "Total",
    inbound: "Entrada",
    outbound: "Outbound - Saída",
    totalEmails: "Total de e-mails",
    inboundEmails: "Emails entrantes",
    outboundEmails: "E-mails de saída",
    totalTextMessages: "Total de mensagens de texto",
    inboundTextMessages: "Mensagens de texto de entrada",
    outboundTextMessages: "Mensagens de texto de saída",
    totalCalls: "Total de chamadas",
    inboundCalls: "Chamadas recebidas",
    outboundCalls: "Chamadas de saída",
    faceBookLeadFormSubmissions: "Envios de Formulário de Preenchimento de Leads do Facebook",
    inboundGMBMessages: "Mensagens de entrada em GBP",
    outboundGMBMessages: "Mensagens de Saída GBP",
    totalIgMessages: "Total mensagens no Instagram",
    inboundIgMessages: "Mensagens do Instagram de Entrada",
    outboundIgMessages: "Mensagens do Instagram enviadas",
    totalfbMessages: "Total de mensagens do Facebook",
    inboundfbMessages: "Mensagens recebidas do Facebook",
    outboundfbMessages: "Mensagens de saída do Facebook",
    sitesCreatedFunnels: "Sites Criados - Funis",
    sitesCreatedWebsites: "Sites Criados - Websites",
    sitesCreatedForms: "Sites Criados - Formulários",
    sitesCreatedSurveys: "Sites Criados - Pesquisas",
    googleReviews: "Avaliações do Google",
    facebookReviews: "Avaliações do Facebook",
    inboundCallDuration: "Duração da chamada de entrada (horas)",
    outboundCallDuration: "Duração da Chamada de Saída (horas)",
    pleaseSelect: "Por favor, selecione",
    subAccountIsDeleted: "Esta subconta foi excluída",
    subAccountIsPaused: "Esta subconta foi pausada",
    success: "Sucesso",
    error: "Erro",
    testEmailSent: "Email de teste enviado",
    testEmailNotSent: "Email de teste não pôde ser enviado",
    typeToSearch: "Digite para pesquisar",
    reviewRequest: "Pedidos de revisão",
    pendingTransferDescription: "Outras agências solicitaram transferir subcontas para você",
    pendingTransferHeading: "Você tem solicitações de transferência de subconta pendentes",
    subAccountTransfer: "Transferências de subcontas",
    manageSubAccTransfer: "Gerencie suas solicitações de transferência da subconta",
    subAccRequest: "Solicitações da Subconta {tabName}",
    receive: "Receber",
    send: "Enviar",
    release: "Lançamento",
    requests: "{count} solicitações",
    subAccLimit: "Limite de uso de subcontas atingido!",
    subAccPlanLimit: "Você usou 3/3 subcontas permitidas no seu plano atual",
    upgradeTime: "É hora de um upgrade!",
    upgradeHigher:
      "Por favor, faça upgrade para um plano superior para aceitar a transferência da subconta",
    browse: "Navegar pelos planos",
    pendingTransferTitle: "Uma solicitação de transferência para esta subconta já está pendente",
    unableTransfer: "Não é possível transferir a subconta para esta agência",
    hippaErrorDescription:
      "A subconta que você está tentando transferir está habilitada para HIPAA e não pode ser transferida para uma agência não-HIPAA",
    pendingTransferErrorDescription:
      "Por favor, cancele a solicitação de transferência existente para esta subconta antes de prosseguir com uma nova",
    receivingAgency: "Agência Recebedora",
    releasingAgency: "Órgão de liberação",
    noOfContacts: "Número de Contatos",
    requestedDate: "Data solicitada",
    action: "Ação",
    cancelTransfer: "Cancelar transferência",
    decline: "Declínio",
    cancelTransferRequest: "Cancelar solicitação de transferência de subconta?",
    cancelRequestDescription:
      "A agência receptora não verá mais esse pedido de transferência, e a subconta {name} não será transferida",
    declineRequest: "Recusar pedido de transferência de subconta?",
    declineRequestDescription:
      "O pedido será recusado e a subconta {name} não será transferida para a sua agência",
    approveTransfer: "Aprovar a transferência da subconta para a sua agência?",
    approveTransferDescription: "A subconta de {name} será transferida para a sua agência",
    transferNow: "Transferir Agora",
    cancelNow: "Cancelar agora",
    transferErrorDetails: "A subconta que você está tentando transferir não pode ser transferida",
    cancelRequest: "Cancelar solicitação",
    received: "Recebido",
    approve: "Aprovar",
    goBack: "Voltar",
    done: "Feito",
    subAccountTransferred: "A subconta foi transferida com sucesso!",
    subAccountTransferDescription:
      "Por favor, observe que, mesmo que a subconta tenha sido transferida, alguns ativos como usuários, números de telefone, contas Mailgun, listagens do Yext, etc., podem levar de 10 a 15 minutos para serem refletidos no destino",
    activeSubscriptionsAlert:
      "O local possui assinaturas ativas. Por favor, cancele todas as assinaturas antes de transferir o local. Lista de assinaturas ativas: {subscriptions}",
    emailEngagement: "Engajamento por e-mail",
    emailReaction: "Reação por e-mail",
    emailDeliverySummary: "Resumo de Entrega de Email",
    facebook: "Facebook",
    sms: "SMS",
    instagram: "Instagram",
    accountSummarySubAccount: "Account Summary - {subAccountName}",
    test: "Test",
    scheduleExists: "Schedule Exists",
    aToZ: "A - Z",
    zToA: "Z - A",
    onlyAdminUsersCanAddNewSubAccounts: "Only Admin users can add new sub-account's.",
    locationName: "Location Name",
    subAccountDeletedIfChangedMind:
      "This Sub-Account will be deleted on {time}, if you've changed your mind",
    eject: "Ejetar",
    unableEjectCancel: "Não é possível cancelar o pedido de expulsão da subconta",
    EjectErrorDetails:
      "A solicitação de cancelamento de emissão de subconta que você está tentando cancelar não pode ser cancelada",
    cancelEjectRequest: "Cancelar pedido de ejeção da subconta?",
    cancelEjectRequestDescription:
      "O usuário selecionado não poderá mais criar uma nova agência fora da subconta {name}",
      transferCompleted:
        "Pedido de transferência para este local já foi processado. Por favor, atualize a página",
      unauthorizedTransfer:
        "Você não está autorizado a realizar esta ação. Apenas os proprietários da agência podem realizar esta ação",
  },
};
